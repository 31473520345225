import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { officerGetStatusDatesAction } from '../../../actions/OfficerActions';
import StatusTemplate from './statusTemplate';

const PackageHistory = () => {
	const dispatch = useDispatch();
	const { id: packageId } = useParams();

	const { fetchingStatusDates, statusDates } = useSelector(state => ({
		fetchingStatusDates: state.officer.fetchingStatusDates,
		statusDates: state.officer.statusDates,
	}));

	useEffect(
		() => {
			dispatch(officerGetStatusDatesAction(packageId));
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<div style={{ paddingTop: '16vh' }}>
			<StatusTemplate statusDates={statusDates} />
		</div>
	);
};

export default PackageHistory;
