import { Paper, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { adminEditUserAction, adminGetMetricsAction, adminGetOfficerAction } from '../../../actions/AdminActions';
import { Routes } from '../../../routes';
import { EditOfficerForm } from '../../../utils/officersUtil';
import '../../dashboard/Dashboard.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
import '../Admin.scss';
import { useStyles } from '../drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminEditOfficer = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { admin, officer } = useSelector(state => ({
		admin: state.admin,
		officer: state.officer,
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		firstName: '',
		lastName: '',
		email: '',
		officer: {},
		formErrors: '',
		startDate: moment().add(-30, 'days'),
		endDate: moment(),
		dateError: null,
	});

	const { id } = useParams();

	useEffect(
		() => {
			dispatch(adminGetOfficerAction(id));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { officerAdded } = admin;
			if (officer && Object.keys(officer).length) {
				const { firstName, lastName, email } = officer;

				setState({ ...state, officer, firstName, lastName, email });
			}
			if (officerAdded) {
				navigate(Routes.AdminOfficers);
			}
		},
		// eslint-disable-next-line
		[officer, admin]
	);

	const onFormInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};
	const onFormSelectChange = event => {
		const { name, value } = event.target;
		setState({ ...state, [name]: value });
	};
	const onEditOfficer = event => {
		event.preventDefault();
		setState({ ...state, formErrors: '' });
		const { firstName, lastName } = state;
		if (!firstName && !lastName) {
			setState({ ...state, formErrors: '* All fields are required' });
			return;
		} else {
			dispatch(adminEditUserAction(id, firstName, lastName));
		}
	};

	const onChangeStartDate = date => {
		setState({ ...state, dateError: null });
		const { endDate } = state;
		if (moment(date).isBefore(endDate)) {
			setState({ ...state, startDate: moment(date) }, () => {
				dispatch(adminGetMetricsAction(date, endDate));
			});
		} else {
			setState({ ...state, dateError: 'Start date should be any day before the end date' });
		}
	};

	const onChangeEndDate = date => {
		setState({ ...state, dateError: null });
		const { startDate } = state;
		if (moment(date).isBefore(moment())) {
			if (moment(date).isAfter(startDate)) {
				setState({ ...state, endDate: moment(date) }, () => {
					dispatch(adminGetMetricsAction(startDate, date));
				});
			} else {
				setState({ ...state, dateError: 'The end date cannot be after the start date' });
			}
		} else {
			setState({ ...state, dateError: 'Oops! we cannot tell the future' });
		}
	};

	const classes = makeStyles(useStyles)();
	const { adminHttpSuccess, adminHttpErrors } = admin;

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '16vh',
				}}
			>
				<EditOfficerForm
					state={state}
					props={props}
					id={id}
					onFormInputChange={onFormInputChange}
					onFormSelectChange={onFormSelectChange}
					onEditOfficer={onEditOfficer}
					currentUser='admin'
				/>
			</Paper>
			{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
			{adminHttpSuccess ? <HttpSuccess errorMessage={adminHttpSuccess} /> : null}
		</div>
	);
};

export default AdminEditOfficer;
