import { Button, Card, CardContent, CardHeader, Input, LinearProgress, Paper, makeStyles } from '@material-ui/core';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	officerGetDataAction,
	officerGetOfficerInfoAction,
	officerReplyIssueAction,
} from '../../../actions/OfficerActions';
import '../../admin/Admin.scss';
import '../../dashboard/Dashboard.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
import { LocalOfficerDrawer, useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const OfficerMessages = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const { auth, officer } = useSelector(state => ({
		auth: state.auth,
		officer: state.officer,
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		officerInfo: {},
		messages: [],
		filterLimit: 100,
		filterSkip: 0,
	});

	useEffect(
		() => {
			const { filterLimit, filterSkip } = state;
			dispatch(officerGetOfficerInfoAction());
			dispatch(officerGetDataAction(filterLimit, filterSkip));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { officerInfo, messages, messagesUpdated } = officer;
			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			if (messagesUpdated) {
				const { filterLimit, filterSkip } = state;
				dispatch(officerGetDataAction(filterLimit, filterSkip));
			}
			setState({ ...state, officerInfo, messages });
		},
		// eslint-disable-next-line
		[auth, officer]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	const onReplyToIssue = (event, id) => {
		event.preventDefault();
		dispatch(officerReplyIssueAction(id, event.target[0].value));
	};

	const { officerInfoFetched } = officer;
	if (!officerInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		const { errorMsg, successMsg } = officer;
		return (
			<div>
				<LocalOfficerDrawer
					currentUser='officer'
					classes={classes}
					state={state}
					props={props}
					activeLink='iss'
					toggleDrawer={toggleDrawer}
					openProfile={openProfileMenu}
					closeProfile={closeProfileMenu}
					profileAnchorEl={state.profileAnchorEl}
					logout={onLogout}
					// searchFieldChange={searchFieldChange}
					// onSearchClient={onSearchClient}
				/>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					{state.messages.reverse().map(message => {
						return (
							<Card key={message.id} style={{ margin: '1% 4%', paddingBottom: '2%' }}>
								<CardHeader
									subheader={`From: ${message.client.client_number || ''}, 
                ${message.client.first_name || ''} ${message.client.last_name || ''}`}
								/>
								<CardHeader
									title={`${message.issue}`}
									avatar={<span></span>}
									subheader={`${Moment(message.createdAt).format('LLL')}`}
									style={{ color: 'blue' }}
								/>
								{message._responses.map(response => {
									if (response.userType === 'client') {
										return (
											<CardContent
												style={{
													padding: '0.6%',
													margin: '0% 7%',
													background: 'rgba(0,0,255,0.05)',
													color: 'blue',
												}}
												key={response.id}
											>
												<b>Client:&nbsp;</b>
												{response.message}
											</CardContent>
										);
									}
									return (
										<CardContent
											style={{
												padding: '0.6%',
												margin: '0% 7%',
												background: 'whitesmoke',
												color: 'darkslategray',
											}}
											key={response.id}
										>
											<b>Officer:&nbsp;</b>
											{response.message}
										</CardContent>
									);
								})}
								<CardContent style={{ padding: '0.4% 7%', margin: '0', marginTop: '2%' }}>
									<form onSubmit={event => onReplyToIssue(event, message.id)}>
										<Input
											required
											placeholder='Reply to this issue'
											style={{ width: '60%', marginRight: '2%' }}
										/>
										<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
											Reply
										</Button>
									</form>
								</CardContent>
							</Card>
						);
					})}
				</Paper>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
		);
	}
};

export default OfficerMessages;
