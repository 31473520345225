const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
const customDateFormatter = date => {
	const t = date.split(/[- :]/);

	// Apply each element to the Date function
	const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
	const workingDate = new Date(d);

	const dateVal = `${workingDate.getDate()}`;
	const monthVal = `${months[workingDate.getMonth()]}`;
	const yearVal = `${workingDate.getFullYear()}`;

	return `${dateVal} ${monthVal}, ${yearVal}`;
};

const costFormatter = date => {
	const t = date.split(/[- :]/);

	// Apply each element to the Date function
	const d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
	const workingDate = new Date(d);

	const dateVal = `${workingDate.getDate()}`;
	const monthVal = `${workingDate.getMonth() + 1}`;
	const yearVal = `${workingDate.getFullYear()}`;

	return `${dateVal}/${monthVal}/${yearVal}`;
};

export default customDateFormatter;
export { costFormatter };
