import { Button, Card, CardContent, Input, LinearProgress, Paper, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { HttpError, HttpSuccess } from '../snackbars';
import { AdminDrawer, useStyles } from './drawer';

import { CheckOutlined, DeleteOutline } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { adminAddMerchatAction, adminEditMerchatAction, adminGetMerchatsAction } from '../../actions/AdminActions';
import '../dashboard/Dashboard.scss';
import './Admin.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminMerchants = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles());

	const { admin, auth } = useSelector(state => ({
		admin: state.admin,
		auth: state.auth,
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		merchants: [],
		merchant: '',
	});

	useEffect(
		() => {
			dispatch(adminGetMerchatsAction());
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { merchants, merchantAdded } = admin;
			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			if (merchantAdded) {
				dispatch(adminGetMerchatsAction());
			}
			setState({ ...state, merchants });
		},
		// eslint-disable-next-line
		[admin, auth]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = event => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	const onInputChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onAddMerchant = () => {
		const { merchant } = state;
		if (merchant) {
			dispatch(adminAddMerchatAction(merchant));
		}
		setState({ ...state, merchant: '' });
	};
	const { adminHttpErrors, adminHttpSuccess, fetchingData } = admin;

	const AllMerchants = state.merchants.map(merchant => {
		return (
			<Grid item xs={mql.matches ? 12 : 4} key={merchant.name}>
				<Card style={{ margin: '2%', padding: '2%' }}>
					<Grid container spacing={0}>
						<Grid item xs={10}>
							<CardContent style={{ padding: '2%' }}>{merchant.name}</CardContent>
						</Grid>
						<Grid item xs={2}>
							{merchant.verified ? (
								<Button onClick={() => dispatch(adminEditMerchatAction(merchant.id, 'delete'))}>
									<DeleteOutline htmlColor='orangered' />
								</Button>
							) : (
								<div>
									<Button onClick={() => dispatch(adminEditMerchatAction(merchant.id, 'verify'))}>
										<CheckOutlined htmlColor='green' />
									</Button>
									<Button onClick={() => dispatch(adminEditMerchatAction(merchant.id, 'delete'))}>
										<DeleteOutline htmlColor='orangered' />
									</Button>
								</div>
							)}
						</Grid>
					</Grid>
				</Card>
			</Grid>
		);
	});

	return (
		<>
			{!fetchingData ? (
				<div className='linear_progress'>
					<LinearProgress />
				</div>
			) : (
				<div>
					<AdminDrawer
						classes={classes}
						state={state}
						props={props}
						activeLink='merch'
						toggleDrawer={toggleDrawer}
						openProfile={openProfileMenu}
						closeProfile={closeProfileMenu}
						profileAnchorEl={state.profileAnchorEl}
						logout={onLogout}
					/>
					<Paper
						square={true}
						style={{
							background: 'rgba(0,0,0,0)',
							boxShadow: 'none',
							paddingLeft: mql.matches ? '12px' : '260px',
							paddingTop: '16vh',
						}}
					>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Paper className='clean_paper' style={{ padding: '1%', margin: '0.5%' }}>
									<Input
										name='merchant'
										onChange={onInputChange}
										value={state.merchant}
										placeholder='Add merchant'
										className={classes.largeInput}
									/>
									<Button onClick={onAddMerchant}>Add</Button>
								</Paper>
							</Grid>
							{AllMerchants}
						</Grid>
					</Paper>
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)}
		</>
	);
};

export default AdminMerchants;
