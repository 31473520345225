import { Button, Grid, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { LockOutlined, MailOutlineSharp } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clientLoginAction } from '../../../actions/AccountsActions';
import { regEx } from '../../../data/data';
import { Routes } from '../../../routes';
import './Signin.scss';

const SignIn = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [state, setState] = useState({
		email: '',
		password: '',
		formValidationErrors: '',
		optionsAnchorEl: null,
	});

	const handleFormInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const signInSubmit = event => {
		event.preventDefault();

		setState({ ...state, formValidationErrors: '' });
		const { email, password } = state;
		const isValid = regEx.email.test(String(email).toLowerCase());
		if (email === '' || password === '') {
			setState({ ...state, formValidationErrors: '* Username and Password fields are required' });
			return;
		} else if (isValid !== true) {
			setState({
				...state,
				formValidationErrors: '* Enter a valid email address',
			});
			return;
		} else {
			dispatch(clientLoginAction(email, password, navigate));
		}
	};
	// const openOptions = (event) => {
	// 	setState({ ...state, optionsAnchorEl: event.currentTarget })
	// }
	// const closeOptions = () => {
	// 	setState({ ...state, optionsAnchorEl: null })
	// }
	// const onOptions = (path) => {
	// 	navigate(path)
	// }
	return (
		<div className='signin_container_main'>
			<div className='signin_image_main'></div>
			<div className='signin_form_main'>
				<div className='signin_form_logo'></div>
				<form onSubmit={signInSubmit} className='signin_form'>
					<div className='signin_form_title'>Login to proceed</div>
					<InputLabel className='signin_label' htmlFor='input-with-icon-adornment'>
						Email or Username
					</InputLabel>
					<Input
						name='email'
						onChange={handleFormInputChange}
						value={state.email}
						className='signin_input'
						startAdornment={
							<InputAdornment position='start'>
								<MailOutlineSharp />
							</InputAdornment>
						}
					/>
					<InputLabel className='signin_label' htmlFor='input-with-icon-adornment'>
						Password
					</InputLabel>
					<Input
						name='password'
						type='password'
						onChange={handleFormInputChange}
						value={state.password}
						className='signin_input'
						startAdornment={
							<InputAdornment position='start'>
								<LockOutlined />
							</InputAdornment>
						}
					/>
					<div className='signin_form_errors'>{state.formValidationErrors}</div>
					<div className='signin_options'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
									Submit
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Link to={Routes.Signup}>Create an account</Link>
							</Grid>
							<Grid item xs={6}>
								<Link to={Routes.Forgotpassword}>Forgot password?</Link>
							</Grid>
						</Grid>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SignIn;
