import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	Input,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from '../../../actions/ErrorHandler';
import { addComma } from '../../../utils/stringConverter';
import '../../admin/Admin.scss';
import { useStyles } from '../drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminVariables = props => {
	const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
	const classes = makeStyles(useStyles)();
	const dispatch = useDispatch();
	const [openCalc, setOpenCalc] = useState(false);
	const [values, setValues] = useState({
		ukLargeWeight: 0,
		usaLargeWeight: 0,
		usaLessWeight: 0,
		ukExchangeRate: 0,
		chinaLargeWeight: 0,
		chinaLessWeight: 0,
		turkeyLargeWeight: 0,
		turkeyLessWeight: 0,
		dubaiLargeWeight: 0,
		dubaiLessWeight: 0,
		chinaSeaFreight: 0,
		chinaSeaFreightGarment: 0,
		turkeySeaFreight: 0,
		dubaiSeaFreight: 0,
	});
	const [variables, setVariables] = useState({
		ukLargeWeight: '',
		usaLargeWeight: '',
		usaLessWeight: '',
		ukExchangeRate: '',
		chinaLargeWeight: '',
		chinaLessWeight: '',
		turkeyLargeWeight: '',
		turkeyLessWeight: '',
		dubaiLargeWeight: '',
		dubaiLessWeight: '',
		chinaSeaFreight: '',
		chinaSeaFreightGarment: '',
		turkeySeaFreight: '',
		dubaiSeaFreight: '',
	});

	useEffect(
		() => {
			Axios.get(`${baseUrl}/calculation-variables`)
				.then(snaps => {
					setValues({
						ukLargeWeight: snaps.data.ukLargeWeight,
						usaLargeWeight: snaps.data.usaLargeWeight,
						usaLessWeight: snaps.data.usaLessWeight,
						ukExchangeRate: snaps.data.ukExchangeRate,
						chinaLargeWeight: snaps.data.chinaLargeWeight,
						chinaLessWeight: snaps.data.chinaLessWeight,
						turkeyLargeWeight: snaps.data.turkeyLargeWeight,
						turkeyLessWeight: snaps.data.turkeyLessWeight,
						dubaiLargeWeight: snaps.data.dubaiLargeWeight,
						dubaiLessWeight: snaps.data.dubaiLessWeight,
						chinaSeaFreight: snaps.data.chinaSeaFreight,
						chinaSeaFreightGarment: snaps.data.chinaSeaFreightGarment,
						turkeySeaFreight: snaps.data.turkeySeaFreight,
						dubaiSeaFreight: snaps.data.dubaiSeaFreight,
					});
				})
				.catch(() => {
					this.setState({ calcErrors: 'An error occured while getting calculation rates.' });
				});
		},
		// eslint-disable-next-line
		[]
	);

	const onChange = e => {
		setVariables({
			...variables,
			[e.target.name]: e.target.value,
		});
	};
	return (
		<>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<div className='variables-content h-screen'>
					{/* Calculator Variable Dialogue Start*/}
					<Dialog
						open={openCalc}
						fullWidth
						onClose={() => setOpenCalc(false)}
						classes={{ paper: classes.dialogPaper }}
					>
						<div className='h-full'>
							<DialogTitle className='text-blue-800'>Update Calculator Variables</DialogTitle>
							<div className='pl-8 mb-5'>
								<Typography className='text-blue-800'>
									<b>Current Values</b>:
								</Typography>
							</div>
							<div className='border-b pb-10 border-solid flex justify-between px-8'>
								<div>
									<Typography className='pt-5'>
										<b>UK Weight (£) </b> - &nbsp;{values.ukLargeWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>China Sea Freight (Ksh.) </b> - &nbsp;
										{addComma(Number(values.chinaSeaFreight))}
									</Typography>
									<Typography className='pt-5'>
										<b>Turkey Sea Freight ($) </b> - &nbsp;{values.turkeySeaFreight}
									</Typography>
									<Typography className='pt-5'>
										<b>USA Large Weight ($) </b> - &nbsp;{values.usaLargeWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>China Large Weight ($) </b> - &nbsp;{values.chinaLargeWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>Turkey Large Weight ($) </b> - &nbsp;{values.turkeyLargeWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>Dubai Large Weight ($) </b> - &nbsp;{values.dubaiLargeWeight}
									</Typography>
								</div>
								<div>
									<Typography className='pt-5'>
										<b>UK Exchange Rate to Ksh</b> - &nbsp;{values.ukExchangeRate}
									</Typography>
									<Typography className='pt-5'>
										<b>China Garment Sea Freight (Ksh.) </b> - &nbsp;
										{addComma(Number(values.chinaSeaFreightGarment))}
									</Typography>
									<Typography className='pt-5'>
										<b>Dubai Sea Freight (Ksh.) </b> - &nbsp;
										{addComma(Number(values.dubaiSeaFreight))}
									</Typography>
									<Typography className='pt-5'>
										<b>USA Less Weight ($) </b> - &nbsp;{values.usaLessWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>China Less Weight ($) </b> - &nbsp;{values.chinaLessWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>Turkey Less Weight ($) </b> - &nbsp;{values.turkeyLessWeight}
									</Typography>
									<Typography className='pt-5'>
										<b>Dubai Less Weight ($) </b> - &nbsp;{values.dubaiLessWeight}
									</Typography>
								</div>
							</div>
							<Card
								style={{
									marginTop: '6%',
									boxShadow: 'none',
									borderRadius: '0',
									borderLeft: '2px solid blue',
								}}
							>
								<CardContent>
									<form
										onSubmit={e => {
											e.preventDefault();

											const payload = {};

											if (variables.ukLargeWeight !== '')
												payload.ukLargeWeight = variables.ukLargeWeight;
											if (variables.ukExchangeRate !== '')
												payload.ukExchangeRate = variables.ukExchangeRate;
											if (variables.usaLargeWeight !== '')
												payload.usaLargeWeight = variables.usaLargeWeight;
											if (variables.usaLessWeight !== '')
												payload.usaLessWeight = variables.usaLessWeight;
											if (variables.chinaLargeWeight !== '')
												payload.chinaLargeWeight = variables.chinaLargeWeight;
											if (variables.chinaLessWeight !== '')
												payload.chinaLessWeight = variables.chinaLessWeight;
											if (variables.turkeyLargeWeight !== '')
												payload.turkeyLargeWeight = variables.turkeyLargeWeight;
											if (variables.turkeyLessWeight !== '')
												payload.turkeyLessWeight = variables.turkeyLessWeight;
											if (variables.dubaiLargeWeight !== '')
												payload.dubaiLargeWeight = variables.dubaiLargeWeight;
											if (variables.dubaiLessWeight !== '')
												payload.dubaiLessWeight = variables.dubaiLessWeight;
											if (variables.chinaSeaFreight !== '')
												payload.chinaSeaFreight = variables.chinaSeaFreight;
											if (variables.chinaSeaFreightGarment !== '')
												payload.chinaSeaFreightGarment = variables.chinaSeaFreightGarment;
											if (variables.turkeySeaFreight !== '')
												payload.turkeySeaFreight = variables.turkeySeaFreight;
											if (variables.dubaiSeaFreight !== '')
												payload.dubaiSeaFreight = variables.dubaiSeaFreight;

											if (
												payload &&
												Object.keys(payload).length === 0 &&
												Object.getPrototypeOf(payload) === Object.prototype
											)
												return;

											Axios.patch(`${baseUrl}/calculation-variables/${1}`, payload)
												.then(() => {
													// refetch
													Axios.get(`${baseUrl}/calculation-variables`)
														.then(snaps => {
															setValues({
																ukLargeWeight: snaps.data.ukLargeWeight,
																usaLargeWeight: snaps.data.usaLargeWeight,
																usaLessWeight: snaps.data.usaLessWeight,
																ukExchangeRate: snaps.data.ukExchangeRate,
																chinaLargeWeight: snaps.data.chinaLargeWeight,
																chinaLessWeight: snaps.data.chinaLessWeight,
																turkeyLargeWeight: snaps.data.turkeyLargeWeight,
																turkeyLessWeight: snaps.data.turkeyLessWeight,
																dubaiLargeWeight: snaps.data.dubaiLargeWeight,
																dubaiLessWeight: snaps.data.dubaiLessWeight,
																chinaSeaFreight: snaps.data.chinaSeaFreight,
																chinaSeaFreightGarment:
																	snaps.data.chinaSeaFreightGarment,
																turkeySeaFreight: snaps.data.turkeySeaFreight,
																dubaiSeaFreight: snaps.data.dubaiSeaFreight,
															});
														})
														.catch(() => {
															this.setState({
																calcErrors:
																	'An error occured while getting calculation rates.',
															});
														});
													setVariables({
														ukLargeWeight: '',
														usaLargeWeight: '',
														usaLessWeight: '',
														ukExchangeRate: '',
														chinaLargeWeight: '',
														chinaLessWeight: '',
														turkeyLargeWeight: '',
														turkeyLessWeight: '',
														dubaiLargeWeight: '',
														dubaiLessWeight: '',
														chinaSeaFreight: '',
														chinaSeaFreightGarment: '',
														turkeySeaFreight: '',
														dubaiSeaFreight: '',
													});
												})
												.catch(error => dispatch(handleError(error.response.data)));
										}}
									>
										<Input
											type='text'
											placeholder='UK Weight (£)'
											name='ukLargeWeight'
											onChange={onChange}
											value={variables.ukLargeWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='UK Exchange Rate to Ksh'
											name='ukExchangeRate'
											onChange={onChange}
											value={variables.ukExchangeRate}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='China Sea Freight (Ksh.)'
											name='chinaSeaFreight'
											onChange={onChange}
											value={variables.chinaSeaFreight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='China Garment Sea Freight (Ksh.)'
											name='chinaSeaFreightGarment'
											onChange={onChange}
											value={variables.chinaSeaFreightGarment}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='Turkey Sea Freight ($)'
											name='turkeySeaFreight'
											onChange={onChange}
											value={variables.turkeySeaFreight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='Dubai Sea Freight (Ksh.)'
											name='dubaiSeaFreight'
											onChange={onChange}
											value={variables.dubaiSeaFreight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='USA Large Weight ($)'
											name='usaLargeWeight'
											onChange={onChange}
											value={variables.usaLargeWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='USA Less Weight ($)'
											name='usaLessWeight'
											onChange={onChange}
											value={variables.usaLessWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='China Large Weight ($)'
											name='chinaLargeWeight'
											onChange={onChange}
											value={variables.chinaLargeWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='China Less Weight ($)'
											name='chinaLessWeight'
											onChange={onChange}
											value={variables.chinaLessWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='Turkey Large Weight ($)'
											name='turkeyLargeWeight'
											onChange={onChange}
											value={variables.turkeyLargeWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='Turkey Less Weight ($)'
											name='turkeyLessWeight'
											onChange={onChange}
											value={variables.turkeyLessWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='Dubai Large Weight ($)'
											name='dubaiLargeWeight'
											onChange={onChange}
											value={variables.dubaiLargeWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Input
											type='text'
											placeholder='Dubai Less Weight ($)'
											name='dubaiLessWeight'
											onChange={onChange}
											value={variables.dubaiLessWeight}
											style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
										/>
										<Button
											type='submit'
											style={{ background: '#232C39', color: 'white', margin: '2%' }}
										>
											Update Values
										</Button>
									</form>
								</CardContent>
							</Card>
						</div>
					</Dialog>
					{/* Calculator Variable Dialogue End*/}
					<Button
						style={{
							background: '#232C39',
							color: 'white',
							width: '155px',
							borderRadius: '4',
							cursor: 'pointer',
						}}
						onClick={() => setOpenCalc(!openCalc)}
					>
						Edit Calculator
					</Button>
				</div>
			</Paper>
		</>
	);
};

export default AdminVariables;
