import { Button, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { Routes } from '../routes';

const NotFound = () => {
	const navigate = useNavigate();

	const redirect = () => {
		if (!!localStorage.getItem('token') && !!localStorage.getItem('client')) {
			navigate(Routes.Dashboard);
		} else {
			navigate(Routes.Signup);
		}
	};

	return (
		<Grid container spacing={0}>
			<Grid item xs={12}>
				<Paper className='not_found_logo'></Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper className='not_found_text'>Page not found</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper className='not_found_button'>
					<Button style={{ border: '1px solid #232C39' }} onClick={redirect}>
						Get Started Here
					</Button>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default NotFound;
