import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from '.';
import { Sidebar } from '../components/common/Sidebar';
import { useAppSelector } from '../store/store';

const OfficerRoute = ({ component }) => {
	const selectAuthAndRole = createSelector(
		[state => state.auth.authenticated, state => state.auth.isOfficer],
		(isAuthenticated, isOfficer) => {
			return {
				isAuthenticated,
				isOfficer,
			};
		}
	);

	const { isAuthenticated, isOfficer } = useAppSelector(selectAuthAndRole);

	return (
		<>
			{isAuthenticated && isOfficer ? (
				<div>
					<Sidebar />
					{component}
				</div>
			) : (
				<Navigate to={Routes.Signin} />
			)}
		</>
	);
};

export default OfficerRoute;
