import {
	Badge,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Input,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Routes } from '../../../routes';
// import PaypalExpressBtn from 'react-paypal-express-checkout'
import { createSelector } from '@reduxjs/toolkit';
import Axios from 'axios';
import { compareAsc } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	baseUrl,
	dashGetUserInfoAction,
	dashPayProtectionAction,
	dashRecentItemsAction,
} from '../../../actions/DashAction';
import { useAppSelector } from '../../../store/store';
import '../../admin/Admin.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from '../drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const code = 'vitumob-llc-e1470522d902';

const DashEligibleItems = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.dash.recentUploads,
			state => state.dash.recentUploadsSuccess,
			state => state.dash.userInfoFetched,
			state => state.dash.protectionPaid,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
			state => state.dash.userInfo,
		],
		(recentUploads, recentUploadsSuccess, userInfoFetched, protectionPaid, successMsg, errorMsg, userInfo) => {
			return {
				recentUploads,
				recentUploadsSuccess,
				userInfoFetched,
				protectionPaid,
				successMsg,
				errorMsg,
				userInfo,
			};
		}
	);

	const { recentUploads, recentUploadsSuccess, userInfoFetched, protectionPaid, successMsg, errorMsg, userInfo } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		tabValue: 0,
		profileAnchorEl: null,
		eligibleItems: [],
		eligibleItemsTotal: 0,
		protectionTotal: 0,
		paymentNumber: '',
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		paypalError: '',
		paypalSuccess: '',
		notificationsDialogOpen: false,
		paymentDialogOpen: false,
		mobileScreen: mql.matches,
		updateError: '',
		price: [],
		priceDetails: {
			itemId: '',
			price: '',
			currency: '',
		},
		uploadFormsBExpanded: false,
		currency: '',
		itemId: '',
		external: '',
		checkedItems: [],
		amountKes: '',
		amountPaidUsd: '',
		protectionAmountKes: '',
		loading: false,
		errorMessage: '',
		successMessage: '',
		memo: '',
	});

	const protectItems = async body => {
		setState({ ...state, loading: true });
		try {
			const resp = await Axios.patch(`${baseUrl}/packages/packages-protection-id`, body);
			console.log(resp.data);
			setState({ ...state, loading: false });
			return resp.data;
		} catch (error) {
			setState({ ...state, loading: false, errorMessage: 'Try again later' });
			setTimeout(() => setState({ ...state, errorMessage: '' }), 4000);
			return null;
		}
	};

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashRecentItemsAction());
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (protectionPaid) {
				navigate(Routes.RegisteredItems);
			}
			if (recentUploadsSuccess) {
				let eligibleItems = [];
				recentUploads.map(pack => {
					if (
						compareAsc(new Date(pack.packageProtectionEligibiltyExpiry), new Date()) === 1 &&
						!pack.isShipmentProtected
					)
						eligibleItems.push(pack);
					// if (compareAsc(new Date(item.packageProtectionEligibiltyExpiry), new Date())) {
					//   // item.protected = false
					//   eligibleItems.push(item)
					// }
					return pack;
				});
				// eligibleItems = eligibleItems.filter(item => item.isShipmentProtected)
				setState({ ...state, eligibleItems });

				setState({ ...state, price: eligibleItems });
			}
			setState({ ...state, paymentNumber: userInfo.phoneNumber || '' });
		},
		// eslint-disable-next-line
		[recentUploads, recentUploadsSuccess, userInfo, protectionPaid]
	);

	useEffect(
		() => {
			if (recentUploadsSuccess) {
				let eligibleItems = [];
				recentUploads.map(pack => {
					if (
						compareAsc(new Date(pack.packageProtectionEligibiltyExpiry), new Date()) === 1 &&
						!pack.isShipmentProtected
					)
						eligibleItems.push(pack);
					return pack;
				});
				// eligibleItems = eligibleItems.filter(item => !item.isShipmentProtected)
				setState({ ...state, eligibleItems });
			}
		},
		// eslint-disable-next-line
		[recentUploads, recentUploadsSuccess]
	);

	const handleChange = (e, id) => {
		setState({ ...state, price: e.target.value });
	};

	const onProtectItem = (event, id) => {
		const { eligibleItems } = state;
		const index = eligibleItems.findIndex(x => x.id === id);
		let newEligibleItems = [...eligibleItems];
		newEligibleItems[index].protected = event.target.checked;

		setState(
			...state,
			{
				eligibleItems: newEligibleItems,
			},
			() => {
				let checkedItems = [];
				state.eligibleItems.map(item => {
					if (item.protected) {
						checkedItems.push(item);
					}
					return item;
				});
				setState({ ...state, checkedItems });
			}
		);
	};

	const onPayProtection = () => {
		const { checkedItems, paymentNumber, amountKes, memo } = state;
		const trackingNumbers = checkedItems.map(item => item.trackingNumber);
		dispatch(dashPayProtectionAction(trackingNumbers, paymentNumber, amountKes, memo));
		setState({
			...state,
			paymentDialogOpen: false,
			protectionTotal: 0,
			eligibleItemsTotal: 0,
			amountKes: 0,
			checkedItems: [],
		});
	};

	const reset = () => {
		setState({ ...state, price: '', currency: '' });
	};

	// eslint-disable-next-line

	if (!userInfoFetched || state.loading) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		// exchangeRates.data.USD
		// let exchangeRate = Math.round(100)
		// if (typeof exchangeRates.exchangeRate !== 'undefined') {
		//   exchangeRate =
		// }
		// const onPayPalSuccess = payment => {
		//   console.log(payment)
		//   setState({p...state, aypalSuccess: 'Success. Please wait'})
		// }
		// const onPayPalCancel = (data) => {
		//   setState({p...state, aypalError: 'Payment cancelled'})
		// }
		// const onPayPalError = (err) => {
		//   console.log(err)
		//   setState({p...state, aypalError: 'An error occured'})
		// }
		// const client = {
		//   sandbox:    'Ab0ADalLRfrfVV8hWSkwkW44nH2YiSJwK52xPZ2m2PfY6x6o6DiY_oOxopznoyy5RfntyXZFMRMVoUr-',
		//   production: '',
		// }
		return (
			<div>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					<Paper className='clean_paper' style={{ padding: '4%', fontSize: '1.2em' }}>
						<Badge badgeContent={state.eligibleItems.length} color='primary'>
							Items Eligible for protection
						</Badge>
					</Paper>
					<Paper style={{ margin: '4%', marginTop: '0' }}>
						{/*EligibleItems*/}
						{mql.matches ? (
							<Table style={{ width: '100%' }}>
								<TableHead>
									<TableRow selected>
										<TableCell>Items</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{state.eligibleItems.map((item, i) => {
										return (
											<TableRow key={item.id}>
												<TableCell>
													<b>Tracking Number:&nbsp;</b>
													{item.trackingNumber}
													<br />
													<b>Price:&nbsp;</b>USD.&nbsp;{item.price}
													<br />
													{/* <b>Date registered:&nbsp;</b>{item.packageProtectionEligibiltyExpiry}<br /> */}
												</TableCell>
												<TableCell>
													<Checkbox
														color='primary'
														checked={item.protected}
														onChange={event => onProtectItem(event, item.id)}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						) : (
							<Table style={{ width: '100%' }}>
								<TableHead>
									<TableRow selected>
										<TableCell></TableCell>
										<TableCell>Tracking Number</TableCell>
										<TableCell>Package Protection Eligibility Expiry</TableCell>
										{/*<TableCell>Submit</TableCell>*/}
										<TableCell>Protect</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{state.eligibleItems.map((item, index) => {
										return (
											<TableRow key={index} style={{ width: '100%' }}>
												<TableCell>{index + 1}</TableCell>
												<TableCell>{item.trackingNumber}</TableCell>
												<TableCell>{item.packageProtectionEligibiltyExpiry}</TableCell>
												<TableCell>
													<Checkbox
														color='primary'
														checked={item.protected}
														onChange={event => onProtectItem(event, item.id)}
													/>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						)}
						<Paper classes={classes} style={{ padding: '2%', background: 'whitesmoke' }}>
							{/* <Button onClick={event => {
                  event.preventDefault();

                  const { eligibleItems } = state;
                  const payload = eligibleItems.map(item => {return {'id': `${item.id}`, 'currency': `${item.currency}`, 'price': `${item.price}`}})                  
				
                  dispatch(dashUpdatePrice(payload, dashRecentItemsAction));
                }}
                style={{background: '#232C39', color: 'white'}}>Calculate</Button> */}
							{/* <Typography style={{fontSize: '1.2em'}}>
                  <b>Selected items total:&nbsp;</b>
                  USD. {parseFloat(state.eligibleItemsTotal).toFixed(2)}&nbsp;&nbsp;-&nbsp;
                  KES. {(parseFloat(state.eligibleItemsTotal) * exchangeRate).toFixed(2)}
                </Typography>
                <Typography style={{fontSize: '1.2em'}}>
                <b>Protection total:&nbsp;</b>
                USD. {parseFloat(state.protectionTotal).toFixed(2)}&nbsp;&nbsp;-&nbsp;
                KES. {(parseFloat(state.protectionTotal) * exchangeRate).toFixed(2)}<br/>
                {Boolean(state.protectionTotal)?
                  (<Button onClick={() => setState({p...state, aymentDialogOpen: true})}
                  style={{background: '#232C39', color: 'white'}}>Protect items</Button>)
                : (<span></span>)}
                </Typography> */}
							<Grid item xs={8}>
								<div></div>
							</Grid>
							<Grid item xs={4}>
								<Input
									placeholder='Enter amount in KES'
									onChange={e =>
										setState({ ...state, ...state, protectionAmountKes: e.target.value })
									}
									type='number'
								/>
								<Typography>
									<Button
										style={{
											background: '#232C39',
											color: 'white',
											marginLeft: '40%',
											textTransform: 'none',
											marginBottom: '20px',
											width: '100px',
											margin: '5px',
										}}
										onClick={async () => {
											if (state.checkedItems.length === 0) {
												setState({
													...state,
													...state,
													loading: false,
													errorMessage: 'Select Items to protect',
												});
												setTimeout(
													() => setState({ ...state, ...state, errorMessage: '' }),
													4000
												);
												return;
											}
											if (!state.protectionAmountKes.trim()) {
												setState({
													...state,
													...state,
													loading: false,
													errorMessage: 'Enter protection amount',
												});
												setTimeout(
													() => setState({ ...state, ...state, errorMessage: '' }),
													4000
												);
												return;
											}

											let body = {
												trackingNumbers: state.checkedItems.map(el => el.trackingNumber),
												amountKes: state.protectionAmountKes,
											};

											let resp = await protectItems(body);

											if (resp) {
												setState({
													...state,
													...state,
													amountPaidUsd: resp.amountPaidUsd,
													amountKes: resp.amountPaidKes,
													memo: resp.memo,
													paymentDialogOpen: true,
												});
											}
										}}
									>
										{' '}
										Protect
									</Button>
								</Typography>
							</Grid>
						</Paper>
					</Paper>
					<Divider />

					{/* Payment Dialog
					 ******************
					 ******************
					 ****************/}
					<Dialog
						open={state.paymentDialogOpen}
						fullWidth
						onClose={() => setState({ ...state, paymentDialogOpen: false })}
					>
						<DialogTitle>Payment</DialogTitle>
						<DialogContent>
							{/* M-PESA */}
							{/* <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography className="invoice_pay_mpesa_logo">Pay with M-PESA</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>1. Confirm or enter your payment phone number below</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Input value={state.paymentNumber} name="paymentNumber"
                      onChange={event => setState({ ...state, paymentNumber: event.target.value })}
                      placeholder="Phone number" style={{ margin: '3%', width: '90%' }} />
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Input value={state.amountKes} name="amountKes"
                      onChange={event => setState({ ...state, amountKes: event.target.value })}
                      placeholder="Amount" style={{ margin: '3%', width: '90%' }} />
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>3. Make sure your phone is unlocked</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>4. Click the pay button below</Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Typography>5. You will be prompted to enter your M-Pesa pin to pay
                      KES {addComma(state.amountKes)}
                    </Typography>
                  </ExpansionPanelDetails>
                  <ExpansionPanelDetails>
                    <Paper className="clean_paper" style={{ textAlign: 'right' }}>
                      <Button onClick={onPayProtection}
                        style={{ background: 'green', color: 'white' }}>Pay</Button>
                    </Paper>
                  </ExpansionPanelDetails>
                </ExpansionPanel> */}
							{/* Fatt Merchant */}
							<ExpansionPanel>
								<ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
									<Typography className='invoice_pay_fatt_merchant_logo'>
										Pay with Fatt Merchant
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Typography>Please click the link below</Typography>
								</ExpansionPanelDetails>
								<ExpansionPanelDetails>
									<Typography>
										<Button style={{ background: '#EF6B20' }}>
											<a
												style={{ textDecoration: 'none', color: '#00416A' }}
												href={`https://omni.fattmerchant.com/#/pay/${code}?memo=${state.memo}&total=${parseFloat(state.amountPaidUsd).toFixed(2)}&r=${window.location.href}`}
											>
												Pay
											</a>
										</Button>
									</Typography>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</DialogContent>
					</Dialog>
				</Paper>
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{state.errorMessage ? <HttpError errorMessage={state.errorMessage} /> : null}
				{state.paypalError ? <HttpError errorMessage={state.paypalError} /> : null}
				{state.paypalSuccess ? <HttpSuccess successMessage={state.paypalSuccess} /> : null}
			</div>
		);
	}
};

export default DashEligibleItems;
