import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Input,
	InputLabel,
	LinearProgress,
	Paper,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	dashDeleteItemAction,
	dashEditItemAction,
	dashGetUserInfoAction,
	dashRecentItemAction,
	dashRecentItemsAction,
} from '../../../actions/DashAction';
import { Routes } from '../../../routes';
import '../../admin/Admin.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
import { DashDrawer, useStyles } from '../drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const EditRegisteredItem = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const { auth, dashboard } = useSelector(state => ({
		auth: state.auth,
		dashboard: state.dashboard,
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		recentUploads: [],
		recentUpload: {},
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		tracking_number: '',
		formError: '',
	});

	const { id } = useParams();

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashRecentItemsAction());
			dispatch(dashRecentItemAction(id));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { recentUploadsSuccess, recentUploads, recentUpload, recentUploadSuccess, recentItemEdited } =
				dashboard;
			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			if (recentItemEdited) {
				navigate(Routes.RegisteredItems);
			}
			if (recentUploadsSuccess) {
				const notArrivedItems = [];
				const receivedItems = [];
				const shippedItems = [];
				let eligibleItems = [];
				recentUploads.map(item => {
					if (item.status === 'Registered') notArrivedItems.push(item);
					if (item.status === 'CheckedIn') receivedItems.push(item);
					if (item.status === 'Shipped') shippedItems.push(item);
					if (item.eligible_for_protection === true) eligibleItems.push(item);
					return item;
				});
				setState({ ...state, notArrivedItems, receivedItems, shippedItems, eligibleItems, recentUploads });
			}
			if (recentUploadSuccess) {
				const { tracking_number } = recentUpload;
				setState({ ...state, recentUpload, tracking_number });
			}
		},
		// eslint-disable-next-line
		[auth, dashboard]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen });
	};

	const handleFormInputChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const handleMerchantChange = (newValue, actionType) => {
		if (actionType.action === 'select-option') {
			setState({ ...state, merchant: newValue.label || '' });
		}
	};

	const handleMerchantInputChange = (inputValue, actionType) => {
		if (actionType.action === 'input-change') {
			setState({ ...state, merchant: inputValue || '' });
		}
	};

	const onUpdateCheckinItem = event => {
		event.preventDefault();

		setState({ ...state, formError: '' });
		const { recentUpload, tracking_number } = state;

		if (recentUpload.status !== 'registered') {
			return setState({ ...state, formError: 'You can only edit registered items' });
		}

		dispatch(dashEditItemAction(id, tracking_number));
	};
	const { userInfoFetched, errorMsg, successMsg } = dashboard;

	if (!userInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		const { recentUploads, tracking_number, formError } = state;
		return (
			<div>
				<DashDrawer
					classes={classes}
					state={state}
					props={props}
					activeLink='reg'
					toggleDrawer={toggleDrawer}
					openProfile={openProfileMenu}
					closeProfile={closeProfileMenu}
					profileAnchorEl={state.profileAnchorEl}
					logout={onLogout}
					toggleNotifications={toggleNotifications}
				/>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					{recentUploads.some(item => item.id === id) ? (
						<Card style={{ margin: '4% auto', width: mql.matches ? '88%' : '50%' }}>
							<CardHeader title='Edit shipping item' />
							<CardContent>
								<form onSubmit={onUpdateCheckinItem}>
									<CardContent>
										<InputLabel>Item description</InputLabel>
										<Input
											value={tracking_number || ''}
											name='tracking_number'
											required
											placeholder='Tracking Number'
											onChange={handleFormInputChange}
											fullWidth
										/>
									</CardContent>
									<CardContent style={{ color: 'orangered' }}>{formError}</CardContent>
									<Button
										type='submit'
										style={{
											background: '#232C39',
											color: 'white',
											marginLeft: '40%',
										}}
									>
										Submit
									</Button>
									&nbsp;&nbsp;&nbsp;
									<Button onClick={() => dispatch(dashDeleteItemAction(id))} color='secondary'>
										Delete item
									</Button>
								</form>
							</CardContent>
						</Card>
					) : (
						<Typography>Shipping item not found</Typography>
					)}
				</Paper>
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			</div>
		);
	}
};

export default EditRegisteredItem;
