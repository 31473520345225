import { LinearProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { verifyEmailAction } from '../../../actions/AccountsActions';
import { Routes } from '../../../routes';

import '../accounts.scss';

const VerifyEmail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { access_token } = useParams();

	const { verifyEmailSuccess, successMsg, errorTrue } = useSelector(state => ({
		verifyEmailSuccess: state.auth.verifyEmailSuccess,
		successMsg: state.auth.successMsg,
		errorTrue: state.auth.errorTrue,
	}));

	useEffect(
		() => {
			dispatch(verifyEmailAction(access_token));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (verifyEmailSuccess) {
				setTimeout(() => {
					navigate(Routes.Signin);
				}, 3000);
			}
		},
		// eslint-disable-next-line
		[verifyEmailSuccess]
	);

	return (
		<div>
			{!verifyEmailSuccess && !errorTrue && (
				<div className='linear_progress'>
					<LinearProgress />
				</div>
			)}
			{verifyEmailSuccess && (
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					<div
						style={{
							marginTop: '50px',
							textAlign: 'center',
							color: '#427D3E',
							display: 'flex',
							justifyContent: 'space-around',
						}}
					>
						<span>{successMsg}</span>
						<span style={{ paddingLeft: '20px' }}>
							<CheckCircleIcon />
						</span>
					</div>
				</div>
			)}
			{errorTrue && (
				<div style={{ paddingTop: '50px', textAlign: 'center' }}>
					<Link to={Routes.Signup}>Sign up</Link>
				</div>
			)}
		</div>
	);
};

export default VerifyEmail;
