import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from '.';
import { useAppSelector } from '../store/store';

const GuestRoute = ({ component }) => {
	const selectAuthAndRole = createSelector(
		[
			state => state.auth.authenticated,
			state => state.auth.isClient,
			state => state.auth.isOfficer,
			state => state.auth.isAdmin,
		],
		(isAuthenticated, isClient, isOfficer, isAdmin) => {
			return {
				isAuthenticated,
				isClient,
				isOfficer,
				isAdmin,
			};
		}
	);

	const { isAuthenticated, isClient, isOfficer, isAdmin } = useAppSelector(selectAuthAndRole);

	const redirectTo = () => {
		if (isClient) {
			return Routes.Dashboard;
		}
		if (isOfficer) {
			return Routes.OfficerLocalOfficer;
		}
		if (isAdmin) {
			return Routes.AdminDash;
		}
	};
	return <>{!isAuthenticated ? component : <Navigate to={redirectTo()} />}</>;
};

export default GuestRoute;
