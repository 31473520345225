import { Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { TermsAndConditions } from '../static/TermsAndC';
import './Dashboard.scss';
import { useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const TermsAndC = () => {
	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<TermsAndConditions />
			</Paper>
		</div>
	);
};

export default withStyles(useStyles)(TermsAndC);
