import { Button } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { LockOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetNewPasswordAction } from '../../../actions/AccountsActions';
import { Routes } from '../../../routes';

import '../signin/Signin.scss';

const NewPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { access_token } = useParams();
	const [state, setState] = useState({
		newPassword: '',
		repeatNewPassword: '',
		formFieldError: '',
	});

	const { resetNewSuccess } = useSelector(state => ({
		resetNewSuccess: state.auth.resetNewSuccess,
	}));

	useEffect(
		() => {
			if (resetNewSuccess) {
				navigate(Routes.Signin);
			}
		},
		// eslint-disable-next-line
		[resetNewSuccess]
	);

	const onPasswordInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const onSetNewPassword = event => {
		event.preventDefault();

		setState({ ...state, formFieldError: '' });
		const { newPassword, repeatNewPassword } = state;
		if (newPassword === '' || repeatNewPassword === '') {
			setState({ ...state, formFieldError: '* Password fields cannot be empty' });
		} else if (newPassword !== repeatNewPassword) {
			setState({ ...state, formFieldError: '* Passwords do not match' });
		} else {
			dispatch(resetNewPasswordAction(state.newPassword, access_token));
		}
	};
	return (
		<div className='signin_container_main'>
			<div className='signin_image_main'></div>
			<div className='signin_form_main'>
				<div className='signin_form_logo'></div>
				<form onSubmit={onSetNewPassword} className='signin_form'>
					<div className='signin_form_title'>Enter your new Password</div>
					<InputLabel className='signin_label' htmlFor='input-with-icon-adornment'>
						New Password
					</InputLabel>
					<Input
						type='password'
						style={{ width: '100%' }}
						onChange={onPasswordInputChange}
						value={state.newPassword}
						name='newPassword'
						startAdornment={
							<InputAdornment position='start'>
								{' '}
								<LockOutlined />{' '}
							</InputAdornment>
						}
					/>
					<InputLabel className='signin_label' htmlFor='input-with-icon-adornment'>
						Confirm New Password
					</InputLabel>
					<Input
						type='password'
						style={{ width: '100%' }}
						onChange={onPasswordInputChange}
						value={state.repeatNewPassword}
						name='repeatNewPassword'
						startAdornment={
							<InputAdornment position='start'>
								{' '}
								<LockOutlined />{' '}
							</InputAdornment>
						}
					/>
					<div className='signin_options' style={{ fontSize: '0.8em', color: 'red' }}>
						{state.formFieldError}
					</div>
					<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
						Submit
					</Button>
				</form>
			</div>
		</div>
	);
};

export default NewPassword;
