import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineSharp from '@material-ui/icons/MailOutlineSharp';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { regEx } from '../../../data/data';
import { Routes } from '../../../routes';

import '../signin/Signin.scss';
import { requestNewPasswordAction } from '../../../actions/AccountsActions';

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		email: '',
		formFieldError: '',
	});

	// const { fetchingData, successMsg } = useSelector(state => ({
	// 	fetchingData: state.auth.fetchingData,
	// 	successMsg: state.auth.successMsg
	// }));

	const handleEmailChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onResetPassword = event => {
		event.preventDefault();

		setState({ ...state, formFieldError: '' });
		const isValid = regEx.email.test(String(state.email).toLowerCase());

		if (state.email === '') {
			setState({ ...state, formFieldError: '* Enter a Username or Email' });
			return;
		} else if (isValid !== true) {
			setState({ ...state, formFieldError: '* Enter a valid email address' });
			return;
		} else {
			dispatch(requestNewPasswordAction(state.email));
		}
	};

	return (
		<div className='signin_container_main'>
			<div className='signin_image_main'></div>
			<div className='signin_form_main'>
				<div className='signin_form_logo'></div>
				<form onSubmit={onResetPassword} className='signin_form'>
					<div className='signin_form_title'>Enter your email to receive a password reset link</div>
					<InputLabel className='signin_label' htmlFor='input-with-icon-adornment'>
						Email
					</InputLabel>
					<Input
						type='email'
						name='email'
						value={state.email}
						onChange={handleEmailChange}
						style={{ width: '100%' }}
						startAdornment={
							<InputAdornment position='start'>
								{' '}
								<MailOutlineSharp />{' '}
							</InputAdornment>
						}
					/>
					<div className='signin_options' style={{ fontSize: '0.8em', color: 'red' }}>
						{state.formFieldError}
					</div>
					<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
						Submit
					</Button>
				</form>
				<div style={{ textAlign: 'center', width: '100%', paddingTop: '8px' }}>
					<Link style={{ color: '#232C39' }} to={Routes.Signin}>
						Sign in
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
