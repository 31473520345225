import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dashGetUserInfoAction, dashRecentItemsAction } from '../../../actions/DashAction';
import { officerGetStatusDatesAction } from '../../../actions/OfficerActions';
import StatusTemplate from '../../officer/localOfficer/statusTemplate';
import { useStyless } from '../drawer';

const PackageHistoryClient = () => {
	const dispatch = useDispatch();
	const classes = useStyless();
	const { id: packageId } = useParams();

	const [state, setState] = useState({
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		arrivedItems: [],
		invoicedItems: [],
		paidItems: [],
		deliveredItems: [],
		notifications: [],
		invoices: [],
		closeTourDialogOpen: false,
		notificationsDialogOpen: false,
	});

	const { recentUploads, recentUploadsSuccess, invoicesSuccess, invoices, statusDates } = useSelector(state => ({
		recentUploads: state.dash.recentUploads,
		recentUploadsSuccess: state.dash.recentUploadsSuccess,
		invoicesSuccess: state.dash.invoicesSuccess,
		invoices: state.dash.invoices,
		statusDates: state.officer.statusDates,
	}));

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashRecentItemsAction());
			dispatch(officerGetStatusDatesAction(packageId));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (recentUploadsSuccess) {
				let arrivedItems = [];
				let receivedItems = [];
				let shippedItems = [];
				let invoicedItems = [];
				let deliveredItems = [];
				let paidItems = [];
				let eligibleItems = [];
				recentUploads &&
					recentUploads.map(item => {
						if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item);
						if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item);
						if (item.packageStatus === 'IN_NAIROBI') arrivedItems.push(item);
						if (item.packageStatus === 'invoiced') invoicedItems.push(item);
						if (item.packageStatus === 'paid') paidItems.push(item);
						if (item.packageStatus === 'delivered') deliveredItems.push(item);
						if (item.isEligibleForProtection === true) eligibleItems.push(item);
						return item;
					});
				setState({
					...state,
					receivedItems,
					shippedItems,
					eligibleItems,
					arrivedItems,
					invoicedItems,
					paidItems,
					deliveredItems,
				});
			}

			if (invoicesSuccess) {
				setState({ ...state, invoices: invoices });
			}
		},
		// eslint-disable-next-line
		[recentUploadsSuccess, recentUploads, invoices, invoicesSuccess]
	);

	return (
		<div>
			<div style={{ paddingTop: '16vh' }}>
				<StatusTemplate statusDates={statusDates} />
			</div>
		</div>
	);
};

export default PackageHistoryClient;
