const initialState = {
	warehouses: [],
	warehousesFetched: false,
	officers: [],
	officersCount: 0,
	officer: {},
	officersFetched: false,
	clients: [],
	clientsJSON: [],
	clientsJSONFetched: false,
	filteredClientsJSON: [],
	filteredClientsJSONFetched: false,
	client: {},
	clientsCount: 0,
	clientsFetched: false,
	invoices: [],
	invoicesCount: 0,
	invoice: {},
	invoicesFetched: false,
	invoiceFetched: false,
	creditAdded: false,
	debitAdded: false,
	redirectToLogin: false,
	adminHttpErrors: null,
	adminHttpSuccess: null,
	fetchingData: false,
	officerAdded: false,
	officerRoleChanged: false,
	merchants: [],
	merchantAdded: false,
	metrics: {},
	metricsFetched: false,
	revenue: {},
	fetchedRevenue: false,
	clientInvoicesFetched: false,
	clientInvoices: [],
	fetchedPayments: false,
	payments: [],
	exchangeRates: {},
	userAddress: [],
	addressFetched: false,
	revenueJSON: [],
	deliveryUpdated: false,
	packageAnalytics: [],
	fetchedPackageAnalytics: false,
	paymentAnalytics: [],
	fetchedPaymentAnalytics: false,
	clientsCounts: null,
	fetchedClientsCount: false,
	fetchedClientsLatest: false,
	clientsLatest: null,
};

export const AdminReducer = (state = initialState, action) => {
	switch (action.type) {
		/* Login */
		case 'ADMIN_SIGNIN':
			return {
				...state,
				adminLoggedIn: false,
				adminHttpErrors: null,
				adminHttpSuccess: null,
				fetchingData: true,
			};
		case 'ADMIN_SIGNIN_SUCCESS':
			return {
				...state,
				adminLoggedIn: true,
				adminHttpSuccess: action.payload,
				fetchingData: false,
			};
		/* Verify Email */
		case 'ADMIN_VERIFY_EMAIL':
			return {
				...state,
				fetchingData: true,
				adminVerifyEmailSuccess: false,
				adminHttpErrors: null,
				adminHttpSuccess: null,
			};
		case 'ADMIN_VERIFY_EMAIL_SUCCESS':
			return {
				...state,
				fetchingData: false,
				adminVerifyEmailSuccess: true,
				adminHttpSuccess: action.payload,
			};
		/* Warehouses */
		case 'ADMIN_GET_WAREHOUSES':
			return {
				...state,
				redirectToLogin: false,
				warehousesFetched: false,
				fetchingData: true,
			};
		case 'ADMIN_GET_WAREHOUSES_SUCCESS':
			return {
				...state,
				warehouses: action.payload,
				warehousesFetched: true,
				fetchingData: false,
			};
		/* Officers */
		case 'ADMIN_ADD_OFFICER':
			return {
				...state,
				fetchingData: true,
				officerAdded: false,
				adminHttpErrors: null,
				adminHttpSuccess: null,
			};
		case 'ADMIN_ADD_OFFICER_SUCCESS':
			return {
				...state,
				fetchingData: false,
				officerAdded: true,
				adminHttpSuccess: action.payload,
			};
		case 'ADMIN_ADD_OFFICER_FAIL':
			return {
				...state,
				fetchingData: false,
				adminHttpErrors: action.payload,
			};
		case 'ADMIN_GET_OFFICERS':
			return {
				...state,
				adminHttpErrors: null,
				adminHttpSuccess: null,
				fetchingData: true,
				officers: [],
				officersFetched: false,
				redirectToLogin: false,
				officerAdded: false,
				officerRoleChanged: false,
			};
		case 'ADMIN_GET_OFFICERS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				officers: action.payload,
				officersFetched: true,
			};
		case 'ADMIN_GET_OFFICER':
			return {
				...state,
				adminHttpErrors: null,
				adminHttpSuccess: null,
				fetchingData: true,
				officer: {},
				redirectToLogin: false,
			};
		case 'ADMIN_GET_OFFICER_SUCCESS':
			return {
				...state,
				fetchingData: false,
				officer: action.payload,
			};
		case 'ADMIN_GET_CLIENTS':
			return {
				...state,
				adminHttpErrors: null,
				adminHttpSuccess: null,
				fetchingData: true,
				clients: [],
				clientsCount: 0,
				clientsFetched: false,
				redirectToLogin: false,
			};
		case 'ADMIN_GET_CLIENTS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				clients: action.payload.clients,
				clientsCount: action.payload.count,
				clientsFetched: true,
			};
		case 'SEARCH_CLIENT_ADMIN':
			return {
				...state,
				clients: [],
				clientsCount: 0,
				fetchingData: true,
			};
		case 'SEARCH_OFFICER_ADMIN':
			return {
				...state,
				clientsCount: 0,
				fetchingData: true,
			};
		case 'SEARCH_CLIENT_ADMIN_SUCCESS':
			return {
				...state,
				clients: action.payload,
				clientsCount: action.payload.length,
				fetchingData: false,
			};
		case 'SEARCH_OFFICER_ADMIN_SUCCESS':
			return {
				...state,
				officers: action.payload,
				officersCount: action.payload.length,
				fetchingData: false,
			};
		/* Get address */
		case 'ADMIN_GET_ADDRESS':
			return {
				...state,
				addressFetched: false,
				userAddress: [],
			};
		case 'ADMIN_GET_ADDRESS_SUCCESS':
			return {
				...state,
				addressFetched: true,
				userAddress: action.payload,
			};
		case 'ADMIN_GET_CLIENTS_COUNT':
			return {
				...state,
				fetchedClientsCount: false,
				clientsCounts: null,
			};
		case 'ADMIN_GET_CLIENTS_COUNT_SUCCESS':
			return {
				...state,
				fetchedClientsCount: true,
				clientsCounts: {
					all: action.payload.all.count,
					active: action.payload.active.count,
					dormant: action.payload.dormant.count,
					inactive: action.payload.inactive.count,
					pending: action.payload.pending.count,
				},
			};
		case 'ADMIN_GET_CLIENT_LATEST':
			return {
				...state,
				fetchedClientsLatest: false,
				clientsLatest: null,
			};
		case 'ADMIN_GET_CLIENT_LATEST_SUCCESS':
			return {
				...state,
				fetchedClientsLatest: true,
				clientsLatest: action.payload,
			};
		case 'ADMIN_GET_PACKAGE_ANALYTICS':
			return {
				...state,
				fetchedPackageAnalytics: false,
				packageAnalytics: [],
			};
		case 'ADMIN_GET_PACKAGE_ANALYTICS_SUCCESS':
			return {
				...state,
				fetchedPackageAnalytics: true,
				packageAnalytics: action.payload,
			};
		case 'ADMIN_GET_PAYMENT_ANALYTICS':
			return {
				...state,
				fetchedPackageAnalytics: false,
				paymentAnalytics: [],
			};
		case 'ADMIN_GET_PAYMENT_ANALYTICS_SUCCESS':
			return {
				...state,
				fetchedPaymentAnalytics: true,
				paymentAnalytics: action.payload,
			};
		case 'ADMIN_GET_CLIENTS_JSON':
			return {
				...state,
				clientsJSON: [],
				clientsJSONFetched: false,
			};
		case 'ADMIN_GET_CLIENTS_JSON_SUCCESS':
			return {
				...state,
				clientsJSON: action.payload,
				clientsJSONFetched: true,
			};
		case 'ADMIN_GET_FILTERED_CLIENTS_JSON':
			return {
				...state,
				filteredClientsJSON: [],
				filteredClientsJSONFetched: false,
			};
		case 'ADMIN_GET_FILTERED_CLIENTS_JSON_SUCCESS':
			return {
				...state,
				filteredClientsJSON: action.payload,
				filteredClientsJSONFetched: true,
			};
		case 'ADMIN_GET_CLIENT':
			return {
				...state,
				adminHttpErrors: null,
				adminHttpSuccess: null,
				fetchingData: true,
				client: {},
				clientsFetched: false,
				redirectToLogin: false,
			};
		case 'ADMIN_GET_CLIENT_SUCCESS':
			return {
				...state,
				fetchingData: false,
				client: action.payload,
				clientsFetched: true,
			};
		case 'ADMIN_EDIT_DELIVERY_DATE':
			return {
				...state,
				fetchingDate: true,
				deliveryUpdated: true,
			};
		case 'ADMIN_EDIT_DELIVERY_DATE_SUCCESS':
			return {
				...state,
				fetchingDate: false,
				deliveryUpdated: false,
			};
		case 'ADMIN_CHANGE_OFFICER_ROLE':
			return {
				...state,
				fetchingData: true,
				officerRoleChanged: false,
				adminHttpErrors: null,
				adminHttpSuccess: null,
			};
		case 'ADMIN_CHANGE_OFFICER_ROLE_SUCCESS':
			return {
				...state,
				officerRoleChanged: true,
				adminHttpSuccess: action.payload,
				fetchingData: false,
			};
		/* Merchants */
		case 'ADMIN_ADD_MERCHANT':
			return {
				...state,
				fetchingData: true,
				merchantAdded: false,
			};
		case 'ADMIN_ADD_MERCHANT_SUCCESS': {
			return {
				...state,
				fetchingData: false,
				merchantAdded: true,
			};
		}
		case 'ADMIN_GET_MERCHANT':
			return {
				...state,
				fetchingData: true,
				merchants: [],
				merchantAdded: false,
			};
		case 'ADMIN_GET_MERCHANT_SUCCESS':
			return {
				...state,
				fetchingData: false,
				merchants: action.payload,
			};
		case 'ADMIN_GET_INVOICES':
			return {
				...state,
				fetchingData: false,
				invoices: [],
				invoicesCount: 0,
				invoicesFetched: false,
				invoiceFetched: false,
			};
		case 'ADMIN_GET_INVOICES_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoicesFetched: true,
				invoices: action.payload.invoices,
				invoicesCount: action.payload.count.count,
			};
		case 'ADMIN_GET_CLIENT_INVOICES':
			return {
				...state,
				fetchingData: false,
				invoices: [],
				invoicesFetched: false,
				invoiceFetched: false,
			};
		case 'ADMIN_GET_CLIENT_INVOICES_SUCCESS':
			return {
				...state,
				fetchingData: false,
				clientInvoicesFetched: true,
				clientInvoices: action.payload,
			};
		case 'ADMIN_GET_INVOICE':
			return {
				...state,
				fetchingData: true,
				invoice: {},
				exchangeRates: {},
				invoiceFetched: false,
				invoiceEdited: false,
			};
		case 'ADMIN_GET_INVOICE_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoice: action.payload.invoice,
				// exchangeRates: action.payload.exchangeRates,
				invoiceFetched: true,
			};
		case 'ADMIN_ADD_CREDIT':
			return {
				...state,
				fetchingData: true,
				creditAdded: false,
				adminHttpSuccess: null,
			};
		case 'ADMIN_ADD_CREDIT_SUCCESS':
			return {
				...state,
				fetchingData: false,
				creditAdded: true,
				adminHttpSuccess: action.payload,
			};
		case 'ADMIN_ADD_DEBIT':
			return {
				...state,
				fetchingData: true,
				debitAdded: false,
				adminHttpSuccess: null,
			};
		case 'ADMIN_ADD_DEBIT_SUCCESS':
			return {
				...state,
				fetchingData: false,
				debitAdded: true,
				adminHttpSuccess: action.payload,
			};
		case 'ADMIN_PAY_INVOICE':
			return {
				...state,
				fetchingData: true,
				adminHttpSuccess: null,
			};
		case 'ADMIN_PAY_INVOICE_SUCCESS':
			return {
				...state,
				fetchingData: false,
				adminHttpSuccess: action.payload,
			};
		/* Metrics */
		case 'ADMIN_METRICS':
			return {
				...state,
				fetchingData: true,
				metrics: {},
				metricsFetched: false,
				adminHttpErrors: null,
				adminHttpSuccess: null,
			};
		case 'ADMIN_METRICS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				metrics: action.payload,
				metricsFetched: true,
			};
		case 'ADMIN_HTTP_ERROR':
			return {
				...state,
				adminHttpErrors: action.payload,
				fetchingData: false,
			};
		/* Auth Errors */
		case 'ADMIN_REDIRECT_TO_LOGIN':
			return {
				...state,
				redirectToLogin: true,
				fetchingData: false,
			};
		case 'GET_REVENUE':
			return {
				...state,
				fetchedRevenue: false,
			};
		case 'GET_REVENUE_SUCCESS':
			return {
				...state,
				fetchedRevenue: true,
				revenue: action.payload.revenue,
				revenueJSON: action.payload.revenueJSON,
			};
		case 'GET_PAYMENT':
			return {
				...state,
				fetchedPayments: false,
			};
		case 'GET_PAYMENT_SUCCESS':
			return {
				...state,
				fetchedPayments: true,
				payments: action.payload,
			};
		case 'ADMIN_INVOICE_LOADING':
			return {
				...state,
				fetchingData: false,
			};
		default:
			return state;
	}
};
