import { Button, Grid, Input, InputAdornment, InputLabel, Paper } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dashChangePasswordAction } from '../../actions/DashAction';
import { Routes } from '../../routes';
import { useStyless } from './drawer';

import '../admin/Admin.scss';
import '../admin/accounts/AdminSignin.scss';
import '../dashboard/Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const OfficerNewPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = useStyless();

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		currentPassword: '',
		newPassword: '',
		newPasswordConfirm: '',
		formErrors: '',
	});

	const { officerPasswordChangeSuccess } = useSelector(state => ({
		officerPasswordChangeSuccess: state.auth.officerPasswordChangeSuccess,
	}));

	useEffect(
		() => {
			if (officerPasswordChangeSuccess) {
				navigate(Routes.OfficerDash);
			}
		},
		// eslint-disable-next-line
		[officerPasswordChangeSuccess]
	);

	const handleFormInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const onSetNewPassword = event => {
		event.preventDefault();

		setState({ ...state, formErrors: '' });
		const { currentPassword, newPassword, newPasswordConfirm } = state;
		if (newPassword !== newPasswordConfirm) {
			setState({ ...state, formErrors: '* Passwords do not match' });
			return;
		} else {
			dispatch(dashChangePasswordAction(currentPassword, newPassword));
		}
	};
	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<form onSubmit={onSetNewPassword} className='admin_signin_form' style={{ marginTop: '20%' }}>
						<InputLabel>Old Password</InputLabel>
						<Input
							required
							name='currentPassword'
							type='password'
							onChange={handleFormInputChange}
							value={state.currentPassword}
							style={{ width: '100%' }}
							startAdornment={
								<InputAdornment position='start'>
									<LockOutlined />{' '}
								</InputAdornment>
							}
						/>
						<InputLabel>New Password</InputLabel>
						<Input
							required
							name='newPassword'
							type='password'
							onChange={handleFormInputChange}
							value={state.newPassword}
							style={{ width: '100%' }}
							startAdornment={
								<InputAdornment position='start'>
									<LockOutlined />{' '}
								</InputAdornment>
							}
						/>
						<InputLabel>Confirm New Password</InputLabel>
						<Input
							required
							name='newPasswordConfirm'
							type='password'
							onChange={handleFormInputChange}
							value={state.newPasswordConfirm}
							style={{ width: '100%' }}
							startAdornment={
								<InputAdornment position='start'>
									<LockOutlined />{' '}
								</InputAdornment>
							}
						/>
						<div style={{ color: 'orangered' }}>{state.formErrors}</div>
						<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
							Submit
						</Button>
					</form>
				</Grid>
			</Paper>
		</div>
	);
};

export default OfficerNewPassword;
