import { Avatar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import stringConverter, { addComma } from '../../../utils/stringConverter';

const mql = window.matchMedia(`(max-width: 1024px)`);

const ReceiptsTable = ({ receipts }) => {
	const navigate = useNavigate();

	const headCells = [
		{ id: 'rctsclnno', numeric: true, disablePadding: true, label: '', align: 'center' },
		{ id: 'rctsdt', numeric: true, disablePadding: false, label: 'Date', align: 'left' },
		{ id: 'rctsrfno', numeric: true, disablePadding: false, label: 'Reference No.', align: 'left' },
		{ id: 'rctsamnt', numeric: true, disablePadding: false, label: 'Amount', align: 'left' },
		{ id: 'rctspymtyp', numeric: true, disablePadding: false, label: 'Payment Type', align: 'left' },
		{ id: 'rctsvw', numeric: true, disablePadding: false, label: 'View', align: 'left' },
	];

	return (
		<div className='tables'>
			<Table
				aria-labelledby='tableTitle'
				aria-label='enhanced table'
				className='table'
				style={{ display: mql.matches ? 'none' : 'blocsk' }}
			>
				<TableHead className='table-header'>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell
								className='cell'
								key={headCell.id}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className='table-body'>
					{receipts &&
						receipts.map((receipt, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index} className={`table-row`}>
									<TableCell align='center'>{index + 1}</TableCell>
									<TableCell className='cell' align='left'>
										{receipt.paidOn && format(receipt.paidOn, 'MMM dd, yyyy')}
										{receipt.paymentDate && format(receipt.paymentDate, 'MMM dd, yyyy')}
									</TableCell>
									<TableCell className='cell' align='left'>
										{receipt.referenceNumber}
									</TableCell>
									<TableCell className='cell' align='left'>
										USD. {receipt.amountPaidUsd}&nbsp;&nbsp;-&nbsp; KES.{' '}
										{addComma(receipt.amountPaidKes)}
									</TableCell>
									<TableCell className='cell' align='left'>
										{stringConverter(receipt.paymentType || '')}
									</TableCell>
									<TableCell className='clickable cell' align='left'>
										<button
											onClick={() =>
												navigate(`/officers/officer/receipts/${receipt.id}?payment=invoice`)
											}
										>
											View
										</button>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			{/* Mobile screens */}
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				<TableBody className='table-body-mobi'>
					{receipts &&
						receipts.map((receipt, index) => {
							return (
								<TableRow key={index} className='table-row-mobi'>
									<TableCell className='cell'>
										<Typography className='pt-5'>
											<Avatar>
												<small>{index + 1}</small>
											</Avatar>
										</Typography>
										<Typography className='pt-7'>
											<b>Date:&nbsp;</b>
											{receipt.paidOn && format(receipt.paidOn, 'MMM dd, yyyy')}
											{receipt.paymentDate && format(receipt.paymentDate, 'MMM dd, yyyy')}
										</Typography>
										<Typography className='pt-2'>
											<b>Reference No:&nbsp;</b>
											{receipt.referenceNumber}
										</Typography>
										<Typography className='pt-2'>
											<b>Amount:&nbsp;</b>
											USD. {receipt.amountPaidUsd}&nbsp;&nbsp;-&nbsp; KES.{' '}
											{addComma(receipt.amountPaidKes)}
										</Typography>
										<Typography className='pt-2'>
											<b>Payment Type:&nbsp;</b>
											{stringConverter(receipt.paymentType || '')}
										</Typography>
										<Typography className='pt-3 clickable'>
											<button
												onClick={() =>
													navigate(`/officers/officer/receipts/${receipt.id}?payment=invoice`)
												}
											>
												View
											</button>
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</div>
	);
};

export default ReceiptsTable;
