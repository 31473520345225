import { Grid, Paper, Typography } from '@material-ui/core';
import { CloseOutlined, DoneOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { HttpError, HttpSuccess } from '../../snackbars';
// import _ from 'lodash'
import TablePagination from '@material-ui/core/TablePagination';

import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { createSelector } from '@reduxjs/toolkit';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../actions/DashAction';
import { officerGetDataAction } from '../../../actions/OfficerActions';
import { useAppSelector } from '../../../store/store';
import '../../admin/Admin.scss';
import InvoicesTable from '../../common/tables/invoices';
import '../../dashboard/Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

// function filterInvoiceItems(collection, substring) {

// 	const invoices = _.filter(collection, _.flow(
// 		[_.identity, _.values, _.join, _.toLower, _.partialRight(
// 			_.includes,
// 			substring.toLowerCase()
// 		)]
// 	))
// 	return invoices
// }

const OfficerInvoices = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectProps = createSelector(
		[
			state => state.officer.errorMsg,
			state => state.officer.successMsg,
			state => state.officer.invoicesCount,
			state => state.officer.invoices,
			state => state.officer.dataFetched,
			state => state.officer.officerInfo,
		],
		(errorMsg, successMsg, invoicesCount, invoices, dataFetched, officerInfo) => {
			return {
				errorMsg,
				successMsg,
				invoicesCount,
				invoices,
				dataFetched,
				officerInfo,
			};
		}
	);

	const { errorMsg, successMsg, invoicesCount, invoices, dataFetched, officerInfo } = useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		invoices: [],
		filteredInvoices: [],
		filterString: '',
		officerInfo: {},
		filterLimit: 10,
		filterSkip: 0,
		invoicesCount: 0,
		page: 0,
		rowsPerPage: 10,
		deliverOpen: false,
		newError: '',
	});

	const handlesearchInvoices = async pattern => {
		try {
			// let filteredInvoices = (await Axios.get(`${baseUrl}/invoices?filter[search]=${pattern}`)).data
			let filteredInvoices = (
				await Axios.get(
					`${baseUrl}/invoices?filter[where][or][0][id][ilike]=%${pattern}%&filter[where][or][1][clientNumber][ilike]=%${pattern}%&filter[where][or][2][paymentStatus][ilike]=%${pattern}%&filter[order]=paymentStatus DESC`
				)
			).data;
			// let { count: invoicesCount } = (await Axios.get(`${baseUrl}/invoices/count?filter[search]=${pattern}`)).data
			let { count: invoicesCount } = (
				await Axios.get(
					`${baseUrl}/invoices/count?filter[where][or][0][id][ilike]=%${pattern}%&filter[where][or][1][clientNumber][ilike]=%${pattern}%&filter[where][or][2][paymentStatus][ilike]=%${pattern}%&filter[order]=paymentStatus DESC`
				)
			).data;
			return {
				filteredInvoices,
				invoicesCount,
			};
		} catch (error) {
			return {
				filteredInvoices: [],
				invoicesCount: 0,
			};
		}
	};

	useEffect(
		() => {
			const { filterLimit, filterSkip } = state;
			let viewing = 'IN_DELAWARE';
			dispatch(officerGetDataAction(filterLimit, filterSkip, viewing));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			setState({ ...state, officerInfo, invoices, filteredInvoices: invoices, invoicesCount });
		},
		// eslint-disable-next-line
		[officerInfo, invoices, invoicesCount]
	);

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = state;
			let viewing = 'IN_DELAWARE';
			dispatch(officerGetDataAction(filterLimit, filterSkip, viewing));
		});
	};

	const onFilterInvoices = substring => {
		setState({ ...state, filterString: substring });
		handlesearchInvoices(substring).then(data => {
			setState({ ...state, ...state, ...data });
		});
	};

	const onCheckInvoice = (event, id) => {
		const { filteredInvoices } = state;
		const index = filteredInvoices.findIndex(x => x.id === id);
		const newInvoices = [...filteredInvoices];

		if (newInvoices[index].paymentStatus !== 'FULLY_PAID') {
			return setState({ ...state, newError: 'The invoice must be fully paid' });
		}

		setState({ ...state, newError: '' });
		newInvoices[index].checked = event.target.checked;
		setState(
			...state,
			{
				filteredInvoices: newInvoices,
			},
			() => {
				const checkedItems = [];
				state.filteredInvoices.map(item => {
					if (item.checked) checkedItems.push(item);
					return item;
				});
				if (checkedItems.length >= 1) {
					setState({ ...state, deliverOpen: true });
				} else {
					setState({ ...state, deliverOpen: false });
				}
			}
		);
	};
	let invoicess = state.filterString.length > 0 ? state.filteredInvoices : state.invoices;

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<Paper className='clean_paper table-wrap'>
							<Typography className='title'>Invoices</Typography>
							<Typography className='align_icons' style={{ color: 'blue' }}>
								<DoneOutlined />
								&nbsp;Paid
							</Typography>
							<br />
							<Typography className='align_icons' style={{ color: 'rgb(255,122,0)' }}>
								<CloseOutlined />
								&nbsp;Unpaid
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className='table-wrap'>
							<div className='flex py-5 px-10'>
								<div className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg cursor-pointer'>
									<FunnelIcon className='h-6 w-6 m-3 text-indigo-400 ' />
									<Typography className=''>Filter</Typography>
								</div>
								<div className='ml-10 flex bg-violet-50 sm:w-3/12 border border-solid border-violet-50 rounded-lg'>
									<MagnifyingGlassIcon className='h-6 w-6 m-3 text-indigo-400 ' />
									<input
										className='pl-3 w-full outline-violet-50'
										placeholder='Search Invoices'
										value={state.filterString}
										onChange={event => {
											onFilterInvoices(event.target.value);
										}}
									/>
								</div>
							</div>
							<InvoicesTable invoices={invoicess} invoicesFetched={dataFetched} />
							<TablePagination
								component='div'
								count={typeof state.invoicesCount === 'number' ? state.invoicesCount : 0}
								page={typeof state.page === 'number' ? state.page : 0}
								onPageChange={handleChangePage}
								rowsPerPage={typeof state.rowsPerPage === 'number' ? state.rowsPerPage : 0}
								rowsPerPageOptions={[10, 25, 50, 100]}
								// onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
						{/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
										style={{ background: 'green', color: 'white' }}
										onClick={() => {
											const { filteredInvoices, invoices } = state;

											let checkedItems = filteredInvoices.filter(invoice => invoice.checked).map(invoice => invoice.id);
											const payload = {};
											payload.invoiceNumbers = checkedItems

											if (payload.invoiceNumbers.length < 1) {
												return setState({ ...state, newError: 'You have not selected any item' })
											}

											setState({ ...state, newError: '' })

											dispatch(officerMarksDeliveredAction(payload, 1));
											setState({ ...state, filteredInvoices: invoices })
										}}
									>
										Deliver
										</Button> 
						</div> */}
					</Grid>
				</Grid>
			</Paper>
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{state.newError.length !== 0 ? <HttpError errorMessage={state.newError} /> : null}
		</div>
	);
};

export default OfficerInvoices;
