import { Card, CardContent, Paper, makeStyles } from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { dashGetIssuesAction } from '../../actions/DashAction';
import { FAQs } from '../../data/FAQs';
import { Routes } from '../../routes';
import { useAppSelector } from '../../store/store';
import { HttpError, HttpSuccess } from '../snackbars';
import './Dashboard.scss';
import { useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const DashFAQs = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.dash.recentUploads,
			state => state.dash.recentUploadsSuccess,
			state => state.dash.invoices,
			state => state.dash.messageActionsSucces,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
			state => state.dash.messages,
		],
		(recentUploads, recentUploadsSuccess, invoices, messageActionsSucces, successMsg, errorMsg, messages) => {
			return {
				recentUploads,
				recentUploadsSuccess,
				invoices,
				messageActionsSucces,
				successMsg,
				errorMsg,
				messages,
			};
		}
	);

	const { recentUploads, recentUploadsSuccess, invoices, messageActionsSucces, successMsg, errorMsg, messages } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		messages: [],
		notArrivedItems: [],
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
	});

	useEffect(
		() => {
			if (messageActionsSucces) {
				dispatch(dashGetIssuesAction());
			}
			if (recentUploadsSuccess) {
				const notArrivedItems = [];
				const receivedItems = [];
				const shippedItems = [];
				let eligibleItems = [];
				recentUploads.map(item => {
					if (item.status === 'registered') notArrivedItems.push(item);
					if (item.status === 'checkedIn') receivedItems.push(item);
					if (item.status === 'shipped') shippedItems.push(item);
					if (item.eligible_for_protection === true) eligibleItems.push(item);
					return item;
				});
				setState({ ...state, notArrivedItems, receivedItems, shippedItems, eligibleItems });
			}
			setState({ ...state, messages, invoices });
		},
		// eslint-disable-next-line
		[messages, recentUploads, recentUploadsSuccess, invoices, messageActionsSucces]
	);

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Card style={{ margin: '4%' }}>
					<Accordion>
						{FAQs.map(FAQ => {
							return (
								<AccordionItem key={Math.random()}>
									<AccordionItemHeading>
										<AccordionItemButton>{FAQ.question}</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<div dangerouslySetInnerHTML={{ __html: FAQ.response }} />
									</AccordionItemPanel>
								</AccordionItem>
							);
						})}
					</Accordion>
					<CardContent>
						For any other questions, kindly send us a&nbsp;
						<Link to={Routes.Messages}>Direct mesage here</Link>&nbsp; or email us on{' '}
						<b>support@vitumob.com</b>
					</CardContent>
				</Card>
			</Paper>
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
		</div>
	);
};

export default DashFAQs;
