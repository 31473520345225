import {
	Avatar,
	Button,
	Divider,
	Grid,
	Input,
	InputLabel,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
const mql = window.matchMedia(`(max-width: 1024px)`);

export const OfficersListing = ({ officers, currentUser }) => {
	const headCells = [
		{ id: 'admofcrclnno', numeric: true, disablePadding: true, label: '', align: 'left' },
		{ id: 'admofcrinvoicno', numeric: true, disablePadding: false, label: 'Name', align: 'left' },
		{ id: 'admofcrdate', numeric: true, disablePadding: false, label: 'Action', align: 'left' },
	];

	return (
		<div className='tables'>
			<Table
				aria-labelledby='tableTitle'
				aria-label='enhanced table'
				className='table'
				style={{ display: mql.matches ? 'none' : 'blocsk' }}
			>
				<TableHead className='table-header table-header-bd'>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell
								className='cell'
								key={headCell.id}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className='table-body'>
					{officers &&
						officers.map((officer, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<TableCell align='left'>{index + 1}</TableCell>
									<TableCell className='cell' align='left'>
										{officer.firstName} {officer.lastName}
									</TableCell>
									<TableCell className='cell' align='left'>
										<Link className='clickable' to={`/${currentUser}/officers/${officer.id}`}>
											View
										</Link>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			{/* For Mobile Screens */}
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				<TableBody className='table-body-mobi'>
					{officers &&
						officers.map((officer, index) => {
							return (
								<TableRow key={index} className='table-row-mobi'>
									<TableCell className='cell'>
										<Typography className='pt-5'>
											<Avatar>
												<small>{index + 1}</small>
											</Avatar>
										</Typography>
										<Typography className='pt-7'>
											<b>Name:&nbsp;</b>
											{officer.firstName} {officer.lastName}
										</Typography>
										<Typography className='pt-3'>
											<Link className='clickable' to={`/admin/officers/${officer.id}`}>
												View
											</Link>
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</div>
	);
};

export const EditOfficerForm = ({ state, props, id, onFormInputChange, onEditOfficer }) => {
	// const Warehouses = state.warehouses.map(warehouse => {
	//   if (currentUser === 'admin') {
	//     return (
	//       <MenuItem value={warehouse.id} key={warehouse.id} >{warehouse.name}</MenuItem>
	//     )
	//   } else {
	//     return (<div></div>)
	//   }
	// })
	return (
		<Grid container spacing={0}>
			<Grid item xs={12}>
				<Paper className='clean_paper' style={{ fontSize: '1.2em', textAlign: 'center' }}>
					EDIT OFFICER FORM
				</Paper>
				<Paper
					style={{
						fontSize: '1.2em',
						margin: '3.2% auto',
						padding: '2%',
						width: mql.matches ? '80%' : '50%',
					}}
				>
					<form onSubmit={onEditOfficer}>
						<InputLabel>First name</InputLabel>
						<Input
							name='firstName'
							onChange={onFormInputChange}
							required
							value={state.firstName || ''}
							style={{ width: '100%', marginBottom: '5%' }}
						/>
						<InputLabel>Last name</InputLabel>
						<Input
							name='lastName'
							onChange={onFormInputChange}
							required
							value={state.lastName || ''}
							style={{ width: '100%', marginBottom: '5%' }}
						/>
						{/* <InputLabel>Warehouse</InputLabel>
          <Select name="warehouse" onChange={onFormSelectChange} required
            disabled={currentUser === 'admin'? false : true}
            style={{width: '100%', marginBottom: '2%'}}
            value={state.warehouse || ''}>
            {Warehouses}
          </Select> */}
						<div style={{ color: 'orangered' }}>{state.formErrors}</div>
						<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
							Submit
						</Button>
					</form>
					<Paper className='clean_paper' style={{ marginTop: '4%', textAlign: 'center' }}>
						<Divider />
						{/* <Button onClick={() => {
            props.changeOfficerRole(
              state.officer.id, 'foreman'
            )
          }} color="primary" style={{margin: '2%'}}>Promote</Button>
          <Button onClick={() => {
             props.changeOfficerRole(
              state.officer.id, 'officer'
            )
          }} color="default" style={{margin: '2%'}}>Demote</Button>
          <Button onClick={() => {
          props.suspendOfficer(this.state.officer.id)
          }}
            color="secondary" style={{margin: '2%'}}>Suspend</Button> */}
						{/* <Button onClick={() => {
            const status = ''
            props.activate(status, id)
          }} color="primary" style={{ margin: '2%' }}>Activate</Button> */}
					</Paper>
				</Paper>
			</Grid>
		</Grid>
	);
};
