import { jwtDecode } from 'jwt-decode';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { logout } from './actions/AccountsActions';
import './index.css';
import setAuthToken from './services/setAuthToken';
import store from './store/store';
// import 'bootstrap/dist/css/bootstrap.css';
// import "bootstrap";
import App from './App';
import './App.css';
import * as serviceWorker from './serviceWorker';

if (localStorage.token) {
	const token = localStorage.token;
	const decoded = jwtDecode(token);
	// check token expiration
	const thisTime = Date.now() / 1000;
	if (decoded.exp < thisTime) {
		store.dispatch(logout('', 2));
		window.location.href = '/';
		window.location.reload(true);
	}

	// set login
	if (decoded.role === 'client') {
		store.dispatch({ type: 'ACCOUNTS_CLIENT_LOGIN_SUCCESS', payload: '' });
	} else if (decoded.role === 'officer') {
		store.dispatch({ type: 'OFFICER_SIGNIN_SUCCESS', payload: '' });
	} else if (decoded.role === 'admin') {
		store.dispatch({ type: 'ADMIN_SIGNIN_SUCCESS', payload: '' });
	}

	// auth headers
	setAuthToken(token);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

serviceWorker.unregister();

RegExp.escape = function (str) {
	return str.replace(/[[\]{}()*+?.\\|^$\-,&#\s]/g, '\\$&');
};
