import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { CloseOutlined, DoneOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpSuccess } from '../snackbars';
import { useStyles } from './drawer';
// import _ from 'lodash'
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { TablePagination } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { adminGetInvoicesAction } from '../../actions/AdminActions';
import { baseUrl } from '../../actions/DashAction';
import { useAppSelector } from '../../store/store';
import InvoicesTable from '../common/tables/invoices';
import '../dashboard/Dashboard.scss';
import './Admin.scss';

// function filterInvoiceItems(collection, substring) {
// 	const invoices = _.filter(collection, _.flow(
// 		_.identity, _.values, _.join, _.toLower, _.partialRight(
// 			_.includes,
// 			substring.toLowerCase()
// 		)
// 	))
// 	return invoices
// }

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminInvoices = props => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.admin.adminHttpErrors,
			state => state.admin.adminHttpSuccess,
			state => state.admin.invoicesCount,
			state => state.admin.invoices,
			state => state.admin.invoicesFetched,
		],
		(adminHttpErrors, adminHttpSuccess, invoicesCount, invoices, invoicesFetched) => {
			return {
				adminHttpErrors,
				adminHttpSuccess,
				invoicesCount,
				invoices,
				invoicesFetched,
			};
		}
	);

	const { adminHttpErrors, adminHttpSuccess, invoicesCount, invoices, invoicesFetched } = useAppSelector(selectProps);

	const [state, setState] = useState({
		invoices: [],
		filteredInvoices: [],
		drawerOpen: false,
		profileAnchorEl: null,
		filterString: '',
		page: 0,
		rowsPerPage: 10,
		filterLimit: 10,
		filterSkip: 0,
		invoicesCount: 0,
	});

	const handlesearchInvoices = async pattern => {
		try {
			let filteredInvoices = (await Axios.get(`${baseUrl}/invoices?filter[search]=${pattern}`)).data;
			let { count: invoicesCount } = (await Axios.get(`${baseUrl}/invoices/count?filter[search]=${pattern}`))
				.data;
			return {
				filteredInvoices,
				invoicesCount,
			};
		} catch (error) {
			return {
				filteredInvoices: [],
				invoicesCount: 0,
			};
		}
	};

	useEffect(
		() => {
			const { filterLimit, filterSkip } = state;
			dispatch(adminGetInvoicesAction(filterLimit, filterSkip));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (invoicesFetched) {
				setState({ ...state, invoices });
			}
			setState({ ...state, invoices, filteredInvoices: invoices, invoicesCount });
		},
		// eslint-disable-next-line
		[invoices, invoicesFetched, invoicesCount]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = event => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = state;
			dispatch(adminGetInvoicesAction(filterLimit, filterSkip));
		});
	};

	const handleChangeRowsPerPage = event => {
		setState(
			{
				...state,
				rowsPerPage: parseInt(event.target.value, 10),
				page: 0,
				filterLimit: parseInt(event.target.value, 10),
				filterSkip: 0,
			},
			() => {
				const { filterLimit, filterSkip } = state;
				dispatch(adminGetInvoicesAction(filterLimit, filterSkip));
			}
		);
	};

	const onFilterInvoices = substring => {
		setState({ ...state, filterString: substring });
		handlesearchInvoices(substring).then(data => {
			setState({ ...state, ...state, ...data });
		});
	};

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<Paper className='clean_paper table-wrap'>
							<Typography className='title'>Invoices</Typography>
							<Typography className='align_icons' style={{ color: 'blue' }}>
								<DoneOutlined />
								&nbsp;Paid
							</Typography>
							<br />
							<Typography className='align_icons' style={{ color: '#F2682A' }}>
								<CloseOutlined />
								&nbsp;Unpaid
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className='table-wrap'>
							<div className='flex py-5 px-10'>
								<div className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg cursor-pointer'>
									<FunnelIcon className='h-6 w-6 m-3 text-indigo-400 ' />
									<Typography className=''>Filter</Typography>
								</div>
								<div className='ml-10 flex bg-violet-50 sm:w-3/12 border border-solid border-violet-50 rounded-lg'>
									<MagnifyingGlassIcon className='h-6 w-6 m-3 text-indigo-400 ' />
									<input
										className='pl-3 w-full outline-violet-50'
										placeholder='Search Invoices'
										value={state.filterString}
										onChange={event => {
											onFilterInvoices(event.target.value);
										}}
									/>
								</div>
							</div>
							<InvoicesTable invoices={state.filteredInvoices} invoicesFetched={invoicesFetched} />
							<TablePagination
								component='div'
								count={typeof state.invoicesCount === 'number' ? state.invoicesCount : 0}
								page={typeof state.page === 'number' ? state.page : 0}
								onPageChange={handleChangePage}
								rowsPerPage={typeof state.rowsPerPage === 'number' ? state.rowsPerPage : 0}
								rowsPerPageOptions={[10, 25, 50, 100]}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
					</Grid>
					{/* <Grid item xs={12}>
							<Paper style={{ margin: '3%', padding: '3%' }}>
								<Input
									value={state.filterString}
									placeholder='Search invoices'
									onChange={event => {
										onFilterInvoices(event.target.value);
									}}
									className={classes.searchField}
								/>
								<Table style={{ display: mql.matches ? 'none' : 'block' }}>
									<TableHead>
										<TableRow>
											<TableCell>Client number</TableCell>
											<TableCell>Invoice number</TableCell>
											<TableCell>Date</TableCell>
											<TableCell>Balance Due</TableCell>
											<TableCell>Status</TableCell>
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableHead></TableHead>
									<TableBody>{UnpaidInvoices}</TableBody>
								</Table>
								<TablePagination
									component='div'
									count={typeof state.invoicesCount === 'number' ? state.invoicesCount : 0}
									page={typeof state.page === 'number' ? state.page : 0}
									onChangePage={handleChangePage}
									rowsPerPage={typeof state.rowsPerPage === 'number' ? state.rowsPerPage : 0}
									rowsPerPageOptions={[10]}
									style={{ margin: '0 auto', width: '50%' }}
								/>
								<Table style={{ display: mql.matches ? 'block' : 'none' }}>
									<TableBody>{UnpaidInvoicesSmall}</TableBody>
								</Table>
							</Paper>
						</Grid> */}
				</Grid>
			</Paper>
			{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
			{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
		</div>
	);
};

export default AdminInvoices;
