import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useEffect, useState } from 'react';
import { HttpError, HttpSuccess } from '../../snackbars';
import { LocalOfficerDrawer, useStyles } from './drawer';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getArchivedAction, officerGetOfficerInfoAction } from '../../../actions/OfficerActions';
import '../../admin/Admin.scss';
import '../../dashboard/Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const OfficerArchived = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const { auth, officer } = useSelector(state => ({
		auth: state.auth,
		officer: state.officer,
	}));

	const [state, setState] = useState({
		page: 0,
		rowsPerPage: 10,
		drawerOpen: false,
		profileAnchorEl: null,
		officerInfo: {},
		archivedItems: [],
		formErrors: '',
		filterString: '',
		filterLimit: 10,
		filterSkip: 0,
		countArchived: 0,
	});

	useEffect(
		() => {
			const { filterLimit, filterSkip } = state;
			dispatch(officerGetOfficerInfoAction());
			dispatch(getArchivedAction(filterLimit, filterSkip));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { officerInfo, archivedItems, countArchived } = officer;

			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			setState({ ...state, officerInfo, archivedItems, countArchived });
		},
		// eslint-disable-next-line
		[auth, officer]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	// return item
	// const lwh = (parseFloat(item.length) * parseFloat(item.width) * parseFloat(item.height))
	// const weight = parseFloat(item.weight)
	// if (Boolean(lwh)) {
	//   const metricWeight = (lwh / 6000)
	//   if (Boolean(weight)) {
	//     // Push the larger number
	//     payload.push({id: item.id, weight: Math.max(metricWeight, weight)})
	//   } else {
	//     // Proceed with metric weight only
	//     payload.push({id: item.id, weight: metricWeight})
	//   }
	// } else {
	//   if (Boolean(weight)) {
	//     // Proceed with weight only
	//     payload.push({id: item.id, weight})
	//   }
	// }
	// return item

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = state;
			dispatch(getArchivedAction(filterLimit, filterSkip));
		});
	};

	// handleChangeRowsPerPage = (event) => {
	//   setState({ ...state, rowsPerPage: parseInt(event.target.value, 10), page: 0 })
	// };

	const { officerInfoFetched } = officer;
	const { archivedItems } = state;

	if (!officerInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		const { errorMsg, successMsg } = officer;

		const ShippingItemsTable = archivedItems.map((item, index) => {
			return (
				<TableRow key={item.id}>
					<TableCell>{index + 1}</TableCell>
					<TableCell>{item.client_number}</TableCell>
					<TableCell>{item.tracking_number}</TableCell>
					<TableCell>{item.shipment_number}</TableCell>
				</TableRow>
			);
		});
		const ShippingItemsCard = archivedItems.map((item, index) => {
			return (
				<Card key={item.id} style={{ display: mql.matches ? 'block' : 'none' }}>
					<CardHeader
						avatar={
							<Avatar>
								<small>{index + 1}</small>
							</Avatar>
						}
					/>
					<CardContent style={{ color: 'darkslategray' }}>
						<Typography className='admin_officers_card_p'>
							<b>Client number:&nbsp;</b>
							{item.client_number}
						</Typography>
						<Typography className='admin_officers_card_p'>
							<b>Tracking Number:&nbsp;</b>
							{item.tracking_number}
						</Typography>
						<Typography className='admin_officers_card_p'>
							<b>Shipment Number:&nbsp;</b>
							{item.shipment_number}
						</Typography>
					</CardContent>
				</Card>
			);
		});
		return (
			<div>
				<LocalOfficerDrawer
					currentUser='officer'
					classes={classes}
					state={state}
					props={props}
					activeLink='arc'
					toggleDrawer={toggleDrawer}
					openProfile={openProfileMenu}
					closeProfile={closeProfileMenu}
					profileAnchorEl={state.profileAnchorEl}
					logout={onLogout}
				/>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					<Table
						style={{
							display: mql.matches ? 'none' : 'block',
							padding: '3%',
							margin: '4%',
							marginBottom: '0',
							width: '86%',
							boxShadow: '0 0 4px 0 silver',
							fontSize: '1.2em',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-around',
							}}
						></div>
						<TableHead>
							<TableRow selected>
								<TableCell></TableCell>
								<TableCell>Client Number</TableCell>
								<TableCell>Tracking Number</TableCell>
								<TableCell>Shipping Number</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{ShippingItemsTable}</TableBody>
					</Table>
					<TablePagination
						component='div'
						count={state.countArchived}
						page={state.page}
						onChangePage={handleChangePage}
						rowsPerPage={state.rowsPerPage}
						rowsPerPageOptions={[10]}
						style={{ margin: '0 auto', width: '50%' }}
					/>
					{/* Mobile Screens */}
					{ShippingItemsCard}
					<Button
						onClick={() => setState({ ...state, consolidateDialogOpen: true })}
						style={{
							background: 'darkgreen',
							color: 'white',
							position: 'fixed',
							bottom: '0',
							left: mql.matches ? '0' : '240px',
							borderRadius: '0',
							display: state.consolidateBtnOpen ? 'block' : 'none',
						}}
					>
						Consolidate items
					</Button>
					<Button
						// onClick={onCheckinItems}
						style={{
							background: '#232C39',
							color: 'white',
							position: 'fixed',
							bottom: '0',
							right: '0',
							borderRadius: '0',
						}}
					>
						Checkin items
					</Button>
				</Paper>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
		);
	}
};

export default OfficerArchived;
