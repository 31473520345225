import {
	Button,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TablePagination from '@material-ui/core/TablePagination';
import Tabs from '@material-ui/core/Tabs';
import { createSelector } from '@reduxjs/toolkit';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { adminGetInvoiceReceiptsAction, adminGetPackagesReceiptsAction } from '../../../actions/AdminActions';
import { useAppSelector } from '../../../store/store';
import stringConverter, { addComma } from '../../../utils/stringConverter';
import ReceiptsTable from '../../common/tables/receipts';
import '../../dashboard/Dashboard.scss';
import '../../dashboard/invoices/Invoices.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from '../drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

function a11yProps(index) {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && (
				//   <Box p={3}>
				<Typography>{children}</Typography>
				//   </Box>
			)}
		</div>
	);
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         borderColor: "green",
//     },
// }));

const Receipts = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.dash.userInfoFetched,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
			state => state.dash.receipts,
			state => state.dash.receiptsCount,
			state => state.dash.packageReceipts,
			state => state.dash.packageReceiptsCount,
		],
		(userInfoFetched, successMsg, errorMsg, receipts, receiptsCount, packageReceipts, packageReceiptsCount) => {
			return {
				userInfoFetched,
				successMsg,
				errorMsg,
				receipts,
				receiptsCount,
				packageReceipts,
				packageReceiptsCount,
			};
		}
	);

	const { userInfoFetched, successMsg, errorMsg, receipts, receiptsCount, packageReceipts, packageReceiptsCount } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		receipts: [],
		receipt: {},
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		page: 0,
		rowsPerPage: 10,
		rowsPerPage1: 10,
		filterLimit: 10,
		filterSkip: 0,
		receiptsCount: 0,
		paymentLimit: 0,
		paymentSkip: 0,
		packageReceipts: [],
		packageReceiptsCount: 0,
	});

	useEffect(
		() => {
			const { filterLimit, filterSkip, paymentLimit, paymentSkip } = state;
			// dispatch(dashGetUserInfoAction());
			dispatch(adminGetInvoiceReceiptsAction(filterLimit, filterSkip));
			dispatch(adminGetPackagesReceiptsAction(paymentLimit, paymentSkip));
		},
		// eslint-disable-next-line
		[state.filterSkip, state.filterLimit]
	);

	// const receipts = useSelector(state => state.dash.receipts)

	useEffect(
		() => {
			setState({ ...state, receipts, receiptsCount, packageReceipts, packageReceiptsCount });
		},
		// eslint-disable-next-line
		[receipts, receiptsCount, packageReceipts, packageReceiptsCount]
	);

	useEffect(() => {
		console.log(state.packageReceipts);
	});

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = state;
			dispatch(adminGetInvoiceReceiptsAction(filterLimit, filterSkip));
		});
	};

	const handleChangeRowsPerPage = event => {
		setState(
			{
				...state,
				rowsPerPage: parseInt(event.target.value, 10),
				page: 0,
				filterLimit: parseInt(event.target.value, 10),
				filterSkip: 0,
			},
			() => {
				const { filterLimit, filterSkip } = state;
				dispatch(adminGetInvoiceReceiptsAction(filterLimit, filterSkip));
			}
		);
	};

	const handleChangeRowsPerPage1 = event => {
		setState(
			{
				...state,
				rowsPerPage1: parseInt(event.target.value, 10),
				page: 0,
				paymentLimit: parseInt(event.target.value, 10),
				paymentSkip: 0,
			},
			() => {
				const { filterLimit, filterSkip } = state;
				dispatch(adminGetPackagesReceiptsAction(filterLimit, filterSkip));
			}
		);
	};

	const [value, setValue] = React.useState('Invoice');

	// if (!userInfoFetched) {
	//     return (
	//         <div className='linear_progress'>
	//             <LinearProgress />
	//         </div>
	//     );
	// }

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<Paper className='clean_paper table-wrap'>
							<Typography style={{ fontSize: '1.6em' }}>Receipts</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className='table-wrap'>
							<Tabs
								TabIndicatorProps={{
									style: {
										backgroundColor: '#F57E29',
									},
								}}
								value={value}
								onChange={handleChange}
								aria-label='wrapped labe'
								className='header-bg text-neutral-800'
							>
								<Tab value='Invoice' label='The Shipping Receipts' {...a11yProps('one')} />
								<Tab
									value='Shipments'
									className='text-red-500'
									label='Package Protection Receipts'
									{...a11yProps('shipments')}
								/>
							</Tabs>
							<TabPanel value={value} index='Invoice'>
								<ReceiptsTable receipts={state.receipts} />
								<TablePagination
									component='div'
									count={state.receiptsCount || 0}
									page={state.page}
									onPageChange={handleChangePage}
									rowsPerPage={state.rowsPerPage}
									rowsPerPageOptions={[10, 25, 50, 100]}
								/>
							</TabPanel>
							<TabPanel value={value} index='Shipments'>
								<ReceiptsTable receipts={state.packageReceipts} />
								<TablePagination
									component='div'
									count={state.packageReceiptsCount || 0}
									page={state.page}
									onPageChange={handleChangePage}
									rowsPerPage={state.rowsPerPage}
									rowsPerPageOptions={[10, 25, 50, 100]}
								/>
							</TabPanel>
						</Paper>
					</Grid>
				</Grid>
			</Paper>
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
		</div>
	);
};

export default Receipts;

const AllInvoices = ({
	receipts,
	receiptsCount,
	page,
	handleChangePage,
	onChangeRowsPerPage,
	rowsPerPage,
	navigate,
}) => {
	if (mql.matches) {
		return (
			<div>
				<TablePagination
					component='div'
					count={receiptsCount || 0}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPage={rowsPerPage}
					style={{ margin: '0 auto', width: '50%' }}
				/>
				<Table style={{ marginTop: '2%' }}>
					<TableHead>
						<TableRow selected>
							<TableCell>Receipt </TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{receipts > 0 &&
							receipts.length > 0 &&
							receipts.map(receipt => {
								return (
									<TableRow
										key={receipt.id}
										// className={receipt.paid ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
									>
										<TableCell>
											<b>Date:&nbsp;</b>
											{Moment(receipt.paidOn).format('LLL')}
											<br />
											<b>Cost:&nbsp;</b>
											USD {addComma(receipt.amountPaidKes)}&nbsp;&nbsp;-&nbsp; KES:{' '}
											{receipt.amountPaidUsd}
											<br />
										</TableCell>
										<TableCell>
											<Button
												onClick={() =>
													navigate(`/admin/receipts/${receipt.id}?payment=invoice`)
												}
												style={{ color: 'blue' }}
											>
												View
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</div>
		);
	}

	return (
		<div>
			<Table style={{ marginTop: '2%' }}>
				<TableHead>
					<TableRow selected>
						<TableCell></TableCell>
						<TableCell>Date</TableCell>
						<TableCell>Reference Number</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Payment Type</TableCell>
						<TableCell>View</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{receipts.length > 0 &&
						receipts.map((receipt, index) => {
							return (
								<TableRow
									key={receipt.id}
									// className={receipt.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
								>
									<TableCell>{index + 1}</TableCell>
									<TableCell>{Moment(receipt.paidOn).format('LLL')}</TableCell>
									<TableCell>{receipt.referenceNumber}</TableCell>
									<TableCell>
										USD. {receipt.amountPaidUsd}&nbsp;&nbsp;-&nbsp; KES.{' '}
										{addComma(receipt.amountPaidKes)}
									</TableCell>
									<TableCell>{stringConverter(receipt.paymentType || '')}</TableCell>
									<TableCell>
										<Button
											onClick={() => navigate(`/admin/receipts/${receipt.id}?payment=invoice`)}
											style={{ color: 'blue' }}
										>
											View
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			<TablePagination
				component='div'
				count={receiptsCount || 0}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPage={rowsPerPage}
				style={{ margin: '0 auto', width: '50%' }}
			/>
		</div>
	);
};

const AllShipments = ({
	receipts,
	receiptsCount,
	page,
	handleChangePage,
	onChangeRowsPerPage,
	rowsPerPage,
	navigate,
}) => {
	if (mql.matches) {
		return (
			<div>
				<TablePagination
					component='div'
					count={receiptsCount || 0}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={onChangeRowsPerPage}
					rowsPerPage={rowsPerPage}
					style={{ margin: '0 auto', width: '50%' }}
				/>
				<Table style={{ marginTop: '2%' }}>
					<TableHead>
						<TableRow selected>
							<TableCell>Receipt </TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{receipts > 0 &&
							receipts.length > 0 &&
							receipts.map(receipt => {
								return (
									<TableRow
										key={receipt.id}
										// className={receipt.paid ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
									>
										<TableCell>
											<b>Date:&nbsp;</b>
											{Moment(receipt.paymentDate || Date.now()).format('LLL')}
											<br />
											<b>Cost:&nbsp;</b>
											USD {addComma(receipt.amountPaidKes)}&nbsp;&nbsp;-&nbsp; KES:{' '}
											{receipt.amountPaidUsd}
											<br />
										</TableCell>
										<TableCell>
											<Button
												onClick={() =>
													navigate(`/admin/receipts/${receipt.id}?payment=packages`)
												}
												style={{ color: 'blue' }}
											>
												View
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</div>
		);
	}

	return (
		<div>
			<Table style={{ marginTop: '2%' }}>
				<TableHead>
					<TableRow selected>
						<TableCell></TableCell>
						<TableCell>Date</TableCell>
						<TableCell>Reference Number</TableCell>
						<TableCell>Amount</TableCell>
						<TableCell>Payment Type</TableCell>
						<TableCell>View</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{receipts &&
						receipts.length > 0 &&
						receipts.map((receipt, index) => {
							return (
								<TableRow
									key={receipt.id}
									// className={receipt.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}
								>
									<TableCell>{index + 1}</TableCell>
									<TableCell>{Moment(receipt.paymentDate || Date.now()).format('LLL')}</TableCell>
									<TableCell>{receipt.referenceNumber}</TableCell>
									<TableCell>
										USD. {receipt.amountPaidUsd}&nbsp;&nbsp;-&nbsp; KES.{' '}
										{addComma(receipt.amountPaidKes)}
									</TableCell>
									<TableCell>{stringConverter(receipt.paymentType || '')}</TableCell>
									<TableCell>
										<Button
											onClick={() => navigate(`/admin/receipts/${receipt.id}?payment=packages`)}
											style={{ color: 'blue' }}
										>
											View
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			<TablePagination
				component='div'
				count={receiptsCount || 0}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				rowsPerPage={rowsPerPage}
				style={{ margin: '0 auto', width: '50%' }}
			/>
		</div>
	);
};
