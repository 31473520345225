import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { CloseOutlined, DoneOutlined } from '@material-ui/icons';
import { Avatar } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dashGetInvoicesAction, dashGetUserInfoAction } from '../../../actions/DashAction';
import { useAppSelector } from '../../../store/store';
import customDateFormatter from '../../../utils/customDateFormatter';
import stringConverter, { addComma } from '../../../utils/stringConverter';
import '../../admin/Admin.scss';
import Loader from '../../common/loader/Loader';
import '../Dashboard.scss';
import { useStyles } from '../drawer';
// import './Invoices.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const Invoices = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.dash.invoicesSuccess,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
			state => state.dash.invoices,
			state => state.dash.invoicesCount,
		],
		(invoicesSuccess, successMsg, errorMsg, invoices, invoicesCount) => {
			return {
				invoicesSuccess,
				successMsg,
				errorMsg,
				invoices,
				invoicesCount,
			};
		}
	);

	const { invoicesSuccess, successMsg, errorMsg, invoices, invoicesCount } = useAppSelector(selectProps);

	const [state, setState] = useState({
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		page: 0,
		rowsPerPage: 10,
		filterLimit: 10,
		filterSkip: 0,
		invoicesCount: 0,
	});
	const [invoicesState, setInvoicesState] = useState([]);

	useEffect(
		() => {
			const { filterLimit, filterSkip } = state;
			dispatch(dashGetUserInfoAction());
			dispatch(dashGetInvoicesAction(filterLimit, filterSkip));
		},
		// esint-disable-next-line
		[]
	);

	useEffect(
		() => {
			setState({ ...state, invoicesCount });
			setInvoicesState(invoices);
		},
		// esint-disable-next-line
		[invoices, invoicesCount]
	);

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = state;
			dispatch(dashGetInvoicesAction(filterLimit, filterSkip));
		});
	};

	const headCells = [
		{ id: 'invsdshindx', numeric: true, disablePadding: true, label: '', align: 'left' },
		{ id: 'invsdshdate', numeric: true, disablePadding: false, label: 'Date', align: 'left' },
		{ id: 'invsdshinvoicno', numeric: true, disablePadding: false, label: 'Invoice No.', align: 'left' },
		{ id: 'invsdshsts', numeric: true, disablePadding: false, label: 'Status', align: 'left' },
		{ id: 'invsdshbld', numeric: true, disablePadding: false, label: 'Balance Due', align: 'left' },
		{ id: 'invsdshact', numeric: true, disablePadding: false, label: 'View', align: 'left' },
	];
	const AllInvoices = () => {
		if (mql.matches) {
			return (
				<div className='tables'>
					<Table className='table-mobi'>
						{invoicesSuccess ? (
							<TableBody className='table-body-mobi'>
								{invoicesState.length > 0 &&
									invoicesState.map((invoice, index) => {
										return (
											<TableRow
												key={index}
												className={`table-row-mobi ${invoice.paymentStatus === 'FULLY_PAID' ? classes.paidInvoiceRow : classes.unpaidInvoiceRow}`}
											>
												<TableCell>
													<Typography className=''>
														<Avatar>
															<small>{index + 1}</small>
														</Avatar>
													</Typography>
													<Typography className='pt-7'>
														<b>Date:&nbsp;</b>
														{invoice.invoiceUpdatedOn &&
															customDateFormatter(invoice.invoiceUpdatedOn)}
													</Typography>
													<Typography className='pt-2'>
														<b>Invoice No:&nbsp;</b>
														{invoice.id.toUpperCase()}
													</Typography>
													<Typography className='pt-2'>
														<b>Status:&nbsp;</b>
														{stringConverter(invoice.paymentStatus)}
													</Typography>
													<Typography className='pt-2'>
														<b>Balance Due:&nbsp;</b>
														USD. {invoice.totalCostUsd}&nbsp;&nbsp;-&nbsp; KES.{' '}
														{addComma(invoice.totalCostKes)}
													</Typography>
													<Typography className='pt-3'>
														<button
															onClick={() =>
																navigate(`/dashboard/invoices/${invoice.id}`)
															}
															className='clickable'
														>
															View
														</button>
													</Typography>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						) : (
							<div className='flex w-full justify-around'>
								<Loader />
							</div>
						)}
					</Table>
				</div>
			);
		}
		return (
			<div className='tables'>
				<Table
					aria-labelledby='tableTitle'
					aria-label='enhanced table'
					className='table'
					style={{ display: mql.matches ? 'none' : 'blocsk' }}
				>
					<TableHead className='table-header table-header-bd'>
						<TableRow>
							{headCells.map(headCell => (
								<TableCell
									className='cell'
									key={headCell.id}
									align={headCell.align}
									padding={headCell.disablePadding ? 'none' : 'normal'}
								>
									{headCell.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{invoicesSuccess ? (
						<TableBody className='table-body'>
							{invoicesState.length > 0 &&
								invoicesState.map((invoice, index) => {
									return (
										<TableRow
											key={invoice.id}
											className={
												invoice.paymentStatus === 'FULLY_PAID'
													? classes.paidInvoiceRow
													: classes.unpaidInvoiceRow
											}
										>
											<TableCell>{index + 1}</TableCell>
											<TableCell>{customDateFormatter(invoice.invoiceUpdatedOn)}</TableCell>
											<TableCell>{invoice.id.toUpperCase()}</TableCell>
											<TableCell>{stringConverter(invoice.paymentStatus)}</TableCell>
											<TableCell>
												USD. {invoice.totalCostUsd}&nbsp;&nbsp;-&nbsp; KES.{' '}
												{addComma(invoice.totalCostKes)}
											</TableCell>
											<TableCell>
												<button
													onClick={() => navigate(`/dashboard/invoices/${invoice.id}`)}
													className='clickable'
												>
													View
												</button>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					) : (
						<div className='absolute top-1/2 left-1/2'>
							<Loader />
						</div>
					)}
				</Table>
			</div>
		);
	};
	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<Paper className='clean_paper table-wrap'>
							<Typography className='title'>Invoices</Typography>
							<Typography className='align_icons' style={{ color: 'blue' }}>
								<DoneOutlined />
								&nbsp;Paid
							</Typography>
							<br />
							<Typography className='align_icons' style={{ color: 'rgb(255,122,0)' }}>
								<CloseOutlined />
								&nbsp;Unpaid
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Grid className='table-wrap'>
							<div className='flex py-5 px-10'>
								{/* <div className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg cursor-pointer'>
										<FunnelIcon className='h-6 w-6 m-3 text-indigo-400 ' />
										<Typography className=''>Filter</Typography>
									</div>
									<div className='ml-10 flex bg-violet-50 sm:w-3/12 border border-solid border-violet-50 rounded-lg'>
										<MagnifyingGlassIcon className='h-6 w-6 m-3 text-indigo-400 ' />
										<input
											className='pl-3 w-full outline-violet-50'
											placeholder='Search Invoices'
											value={state.filterString}
											onChange={event => {
												// onFilterInvoices(event.target.value);
											}}
										/>
									</div> */}
							</div>

							<AllInvoices />
							<TablePagination
								component='div'
								count={state.invoicesCount}
								page={state.page}
								onPageChange={handleChangePage}
								rowsPerPage={state.rowsPerPage}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
		</div>
	);
};

export default Invoices;
