import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Input,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	makeStyles,
} from '@material-ui/core';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	dashCloseIssueAction,
	dashCreateIssueAction,
	dashGetIssuesAction,
	dashGetUserInfoAction,
	dashReplyIssueAction,
} from '../../actions/DashAction';
import { HttpError, HttpSuccess } from '../snackbars';
import './Dashboard.scss';
import { DashDrawer, useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const DashMessages = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const { auth, dashboard } = useSelector(state => ({
		auth: state.auth,
		dashboard: state.dashboard,
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		messages: [],
		notArrivedItems: [],
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
		newIssue: '',
		newIssueSelect: '',
		issueResponse: '',
		newIssueDialogOpen: false,
	});

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashGetIssuesAction());
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { messages, recentUploads, recentUploadsSuccess, invoices, messageActionsSucces } = dashboard;
			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			if (messageActionsSucces) {
				dispatch(dashGetIssuesAction());
			}
			if (recentUploadsSuccess) {
				const notArrivedItems = [];
				const receivedItems = [];
				const shippedItems = [];
				let eligibleItems = [];
				recentUploads.map(item => {
					if (item.status === 'registered') notArrivedItems.push(item);
					if (item.status === 'checkedIn') receivedItems.push(item);
					if (item.status === 'shipped') shippedItems.push(item);
					if (item.eligible_for_protection === true) eligibleItems.push(item);
					return item;
				});
				setState({ ...state, notArrivedItems, receivedItems, shippedItems, eligibleItems });
			}
			setState({ ...state, messages, invoices });
		},
		// eslint-disable-next-line
		[auth, dashboard]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleNotifications = () => {
		setState({ ...state, notificationsDialogOpen: !state.notificationsDialogOpen });
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	const onCreateIssue = () => {
		const { newIssue, newIssueSelect } = state;
		if (newIssue === '') return;
		dispatch(dashCreateIssueAction(newIssue, newIssueSelect));
		setState({ ...state, newIssueDialogOpen: false, newIssue: '' });
	};

	const onReplyToIssue = (event, id) => {
		event.preventDefault();
		dispatch(dashReplyIssueAction(id, event.target[0].value));
	};

	const onCloseIssue = id => {
		dispatch(dashCloseIssueAction(id));
	};

	const { successMsg, errorMsg, userInfo, userInfoFetched } = dashboard;

	if (!userInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		return (
			<div>
				<DashDrawer
					classes={classes}
					state={state}
					props={props}
					activeLink='dash'
					toggleDrawer={toggleDrawer}
					openProfile={openProfileMenu}
					closeProfile={closeProfileMenu}
					profileAnchorEl={state.profileAnchorEl}
					logout={onLogout}
					toggleNotifications={toggleNotifications}
				/>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					<Button
						onClick={() => setState({ ...state, newIssueDialogOpen: true })}
						style={{ margin: '1% 4%', background: '#232C39', color: 'white' }}
					>
						Add new message
					</Button>
					{state.messages.map(message => {
						return (
							<Card key={message.id} style={{ margin: '1% 4%', paddingBottom: '2%' }}>
								<CardHeader
									avatar={<Avatar>{userInfo.first_name.substring(0, 2)}</Avatar>}
									title={`${message.issue}`}
									style={{ color: 'blue' }}
									subheader={`${Moment(message.createdAt).format('LLL')}`}
								/>
								{message._responses.map(response => {
									if (response.userType === 'client') {
										return (
											<CardContent
												style={{
													padding: '0.6%',
													margin: '0% 7%',
													background: 'rgba(0,0,255,0.05)',
													color: 'blue',
												}}
												key={response.id}
											>
												<b>{userInfo.first_name}:&nbsp;</b>
												{response.message}
											</CardContent>
										);
									}
									return (
										<CardContent
											style={{
												padding: '0.6%',
												margin: '0% 7%',
												background: 'whitesmoke',
												color: 'darkslategray',
											}}
											key={response.id}
										>
											<b>Officer:&nbsp;</b>
											{response.message}
										</CardContent>
									);
								})}
								<CardContent style={{ padding: '0.4% 7%', margin: '0', marginTop: '2%' }}>
									<form onSubmit={event => onReplyToIssue(event, message.id)}>
										<Input
											required
											placeholder='Reply to this issue'
											style={{ width: '60%', marginRight: '2%' }}
										/>
										<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
											Reply
										</Button>
									</form>
								</CardContent>
								<CardActions>
									<Button
										onClick={() => onCloseIssue(message.id)}
										style={{ margin: '0 6%' }}
										color='secondary'
									>
										Close message
									</Button>
								</CardActions>
							</Card>
						);
					})}
				</Paper>
				{/* New issue Dialog */}
				<Dialog
					open={state.newIssueDialogOpen}
					fullWidth
					onClose={() => setState({ ...state, newIssueDialogOpen: false })}
				>
					<DialogTitle style={{ color: 'blue' }}>Message</DialogTitle>
					<DialogContent style={{ color: 'darkslategray' }}>
						Type your message or issue bellow. We will respond as soon as possible
					</DialogContent>
					<DialogContent>
						<FormControl style={{ marginBottom: '2%' }} fullWidth>
							<InputLabel id='issues_category_select'>Category (optional)</InputLabel>
							<Select
								labelId='issues_category_select'
								value={state.newIssueSelect}
								onChange={event => setState({ ...state, newIssueSelect: event.target.value })}
							>
								<MenuItem value='Lost items'>Lost items</MenuItem>
								<MenuItem value='Queries on cost'>Queries on cost</MenuItem>
								<MenuItem value='Delivery timelines'>Delivery timelines</MenuItem>
								<MenuItem value='Amendment of orders'>Amendment of orders</MenuItem>
								<MenuItem value='Cancelation of orders'>Cancelation of orders</MenuItem>
								<MenuItem value='General'>General</MenuItem>
							</Select>
						</FormControl>
						<Input
							name='newIssue'
							value={state.newIssue}
							onChange={event => setState({ ...state, newIssue: event.target.value })}
							placeholder='Text message'
							style={{ marginBottom: '2%' }}
							fullWidth
						/>
					</DialogContent>
					<DialogActions style={{ paddingRight: '6%' }}>
						<Button onClick={onCreateIssue} color='primary'>
							Send
						</Button>
					</DialogActions>
				</Dialog>
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			</div>
		);
	}
};

export default DashMessages;
