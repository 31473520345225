import Axios from 'axios';
import { handleError } from './ErrorHandler';

const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
/* Prefrerred payment method */
export const getPaymentMethodsAction = (startDate, endDate) => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/admins/preferredMethodOfPayment/${startDate}/${endDate}`;
	return dispatch => {
		dispatch({ type: 'GET_PAYMENT' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'GET_PAYMENT_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => console.log(error));
	};
};

/* Revenue */
export const getRevenueAction = (selectedStartDate, selectedEndDate, limit, skip) => {
	const endpoint = Axios.get(`${baseUrl}/payments/invoice?filter[skip]=${skip}&filter[limit]=${limit}`);
	const endpointJSON = Axios.get(`${baseUrl}/payments/invoice?filter[skip]=${skip}&filter[limit]=${limit}`);
	return dispatch => {
		dispatch({ type: 'GET_REVENUE' });
		Axios.all([endpoint, endpointJSON])
			.then(
				Axios.spread((...revenue) => {
					dispatch({
						type: 'GET_REVENUE_SUCCESS',
						payload: {
							revenue: revenue[0].data,
							revenueJSON: revenue[1].data,
						},
					});
				})
			)
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

/* Search invoice */
export const searchInvoiceAction = pattern => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/invoices/search?pattern=${pattern}`;
	return dispatch => {
		dispatch({ type: 'SEARCH_CLIENT_ADMIN' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'SEARCH_CLIENT_ADMIN_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

/* Search client */
export const searchClientAction = pattern => {
	Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
	/// const endpoint = `${baseUrl}/clients?filter[search]=${pattern}`
	const endpoint = `${baseUrl}/clients?filter[where][or][0][id][ilike]=%${pattern}%&filter[where][or][1][firstName][ilike]=%${pattern}%&filter[where][or][2][lastName][ilike]=%${pattern}%&filter[where][or][3][phoneNumber][ilike]=%${pattern}&filter[include][0]=user`;
	return dispatch => {
		dispatch({ type: 'SEARCH_CLIENT_ADMIN' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'SEARCH_CLIENT_ADMIN_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

/* Search officer */
export const searchOfficerAction = pattern => {
	Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
	const endpoint = `${baseUrl}/officers?filter[where][or][1][firstName][ilike]=%${pattern}%&[where][or][2][lastName][ilike]=%${pattern}%`;
	return dispatch => {
		dispatch({ type: 'SEARCH_OFFICER_ADMIN' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'SEARCH_OFFICER_ADMIN_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

/* Warehouses */
export const adminGetWarehousesAction = () => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/warehouses`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_WAREHOUSES' });
		Axios.get(endpoint)
			.then(snapshots => dispatch({ type: 'ADMIN_GET_WAREHOUSES_SUCCESS', payload: snapshots.data }))
			.catch(error => dispatch(handleError(error)));
	};
};

/* Users */
export const adminAddUserAction = (email, firstName, lastName) => {
	const endpoint = `${baseUrl}/officers`;
	return dispatch => {
		dispatch({ type: 'ADMIN_ADD_OFFICER' });
		Axios.post(endpoint, { email, firstName, lastName })
			.then(() => {
				dispatch({ type: 'ADMIN_ADD_OFFICER_SUCCESS', payload: 'Officer Added' });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminEditUserAction = (id, firstName, lastName) => {
	const endpoint = `${baseUrl}/officers/${id}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_ADD_OFFICER' });
		Axios.patch(endpoint, { firstName, lastName })
			.then(() => {
				dispatch({ type: 'ADMIN_ADD_OFFICER_SUCCESS', payload: 'Officer information saved' });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetOfficersAction = (limit, skip) => {
	const endpoint = `${baseUrl}/officers?filter[limit]=${limit}&filter[skip]=${skip}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_OFFICERS' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_OFFICERS_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetOfficerAction = id => {
	const endpoint = `${baseUrl}/officers/${id}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_OFFICER' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_OFFICER_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetClientsAction = (limit, skip, viewing) => {
	const endpoint = Axios.get(
		`${baseUrl}/clients?filter[limit]=${limit}&filter[skip]=${skip}&filter[where][status]=${viewing ? viewing : ''}&filter[order]=firstName%20ASC&filter[include][0]=user`
	);
	const countEndpoint = Axios.get(`${baseUrl}/clients/count?where[status]=${viewing ? viewing : ''}`);
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_CLIENTS' });
		Axios.all([endpoint, countEndpoint])
			.then(
				Axios.spread((snapshots, count) => {
					dispatch({
						type: 'ADMIN_GET_CLIENTS_SUCCESS',
						payload: {
							clients: snapshots.data,
							count: count.data,
						},
					});
				})
			)
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetClientsCountAction = () => {
	const endpoint0 = Axios.get(`${baseUrl}/clients/count`);
	const endpoint1 = Axios.get(`${baseUrl}/clients/count?where[status]=active`);
	const endpoint2 = Axios.get(`${baseUrl}/clients/count?where[status]=dormant`);
	const endpoint3 = Axios.get(`${baseUrl}/clients/count?where[status]=inactive`);
	const endpoint4 = Axios.get(`${baseUrl}/clients/count?where[status]=pending-registration`);
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_CLIENTS_COUNT' });
		Axios.all([endpoint0, endpoint1, endpoint2, endpoint3, endpoint4])
			.then(
				Axios.spread((...snapshots) => {
					dispatch({
						type: 'ADMIN_GET_CLIENTS_COUNT_SUCCESS',
						payload: {
							all: snapshots[0].data,
							active: snapshots[1].data,
							dormant: snapshots[2].data,
							inactive: snapshots[3].data,
							pending: snapshots[4].data,
						},
					});
				})
			)
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetClientsLatestAction = () => {
	const endpoint = `${baseUrl}/clients/latest`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_CLIENT_LATEST' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_CLIENT_LATEST_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetClientsJSONAction = () => {
	const endpoint = `${baseUrl}/clients?filter[include][0]=user`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_CLIENTS_JSON' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_CLIENTS_JSON_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetFilteredClientsJSONAction = viewing => {
	const endpoint = `${baseUrl}/clients?filter[where][status]=${viewing ? viewing : ''}&filter[include][0]=user`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_FILTERED_CLIENTS_JSON' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_FILTERED_CLIENTS_JSON_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetClientAction = id => {
	const endpoint = `${baseUrl}/users/clients/${id}?filter={"include":[{"relation": "packages", "scope": {"order": "arrivalDate DESC"}}]}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_CLIENT' });
		dispatch({ type: 'ADMIN_GET_ADDRESS' });

		// Get address
		Axios.get(`${baseUrl}/addresses?filter[where][clientNumber]=${id}`)
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_GET_ADDRESS_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(err => console.log(err));

		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_GET_CLIENT_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetPackageAnalyticsAction = () => {
	const endpoint = `${baseUrl}/package/analytics`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_PACKAGE_ANALYTICS' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_PACKAGE_ANALYTICS_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetPaymentAnalyticsAction = () => {
	const endpoint = `${baseUrl}/payment/analytics`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_PAYMENT_ANALYTICS' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({ type: 'ADMIN_GET_PAYMENT_ANALYTICS_SUCCESS', payload: snapshots.data });
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminGetClientInvoicesAction = clientId => {
	const endpoint = `${baseUrl}/invoices/client/${clientId}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_CLIENT_INVOICES' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_GET_CLIENT_INVOICES_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => console.log(error));
	};
};

export const adminChangeOfficerRoleAction = (id, role) => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/officers/${id}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_CHANGE_OFFICER_ROLE' });
		Axios.patch(endpoint, { role })
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_CHANGE_OFFICER_ROLE_SUCCESS',
					payload: 'Officer role changed successfully',
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

export const activateOfficerAction = (status, id) => {
	const endpoint = `${baseUrl}/users/clients`;
	return dispatch => {
		dispatch({ type: 'ADMIN_ACTIVATE_OFFICER' });
		Axios.get(endpoint, { isActive: !status })
			.then(snapshots => {
				dispatch({ type: 'ADMIN_ACTIVATE_OFFICER_SUCCESS', payload: snapshots.data });
				dispatch(adminGetOfficerAction(id));
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};

export const adminSuspendOfficerAction = id => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/officers/${id}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_CHANGE_OFFICER_ROLE' });
		Axios.patch(endpoint, { status: 'deactivated' })
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_CHANGE_OFFICER_ROLE_SUCCESS',
					payload: 'Officer Suspended',
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

/* Merchants */
export const adminAddMerchatAction = name => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/merchants`;
	return dispatch => {
		dispatch({ type: 'ADMIN_ADD_MERCHANT' });
		Axios.post(endpoint, { name, verified: true })
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_ADD_MERCHANT_SUCCESS',
					payload: 'Merchant added',
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

export const adminGetMerchatsAction = () => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/merchants`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_MERCHANT' });
		Axios.get(endpoint)
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_GET_MERCHANT_SUCCESS',
					payload: snapshots.data,
				});
			})
			.catch(error => dispatch(handleError(error)));
	};
};

export const adminEditMerchatAction = (id, action) => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const endpoint = `${baseUrl}/merchants/${id}`;
	switch (action) {
		case 'verify':
			return dispatch => {
				dispatch({ type: 'ADMIN_ADD_MERCHANT' });
				Axios.patch(endpoint, { verified: true })
					.then(snapshots => {
						dispatch({
							type: 'ADMIN_ADD_MERCHANT_SUCCESS',
							payload: 'Merchant edited',
						});
					})
					.catch(error => dispatch(handleError(error)));
			};
		case 'delete':
			return dispatch => {
				dispatch({ type: 'ADMIN_ADD_MERCHANT' });
				Axios.delete(endpoint)
					.then(snapshots => {
						dispatch({
							type: 'ADMIN_ADD_MERCHANT_SUCCESS',
							payload: 'Merchant deleted',
						});
					})
					.catch(error => dispatch(handleError(error)));
			};
		default:
			return;
	}
};

export const adminGetInvoicesAction = (limit, skip) => {
	const endpoint = `${baseUrl}/invoices?filter[limit]=${limit}&filter[skip]=${skip}&filter[include][0]=consolidatedItems&filter[order]=invoiceGenerationDate DESC`;
	const countEndpoint = `${baseUrl}/invoices/count`;
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_INVOICES' });
		Axios.all([Axios.get(endpoint), Axios.get(countEndpoint)])
			.then(
				Axios.spread((snapshots, count) => {
					dispatch({
						type: 'ADMIN_GET_INVOICES_SUCCESS',
						payload: { invoices: snapshots.data, count: count.data },
					});
				})
			)
			.catch(error => console.log(error));
	};
};

export const adminGetInvoiceAction = id => {
	const endpoint = Axios.get(
		`${baseUrl}/invoices/${id}?filter={"include": [{"relation": "consolidatedItems", "scope": {"include":[{"relation": "packages"}]}}, {"relation": "debits"}, {"relation": "payments"}, {"relation": "credits"}, {"relation": "storageCosts"}]}`
	);
	return dispatch => {
		dispatch({ type: 'ADMIN_GET_INVOICE' });
		dispatch({ type: 'ADMIN_GET_CLIENT' });
		dispatch({ type: 'ADMIN_GET_ADDRESS' });

		Axios.all([endpoint])
			.then(
				Axios.spread((...snapshots) => {
					// client
					Axios.get(`${baseUrl}/users/clients/${snapshots[0].data.clientNumber}`)
						.then(snapshots => {
							dispatch({
								type: 'ADMIN_GET_CLIENT_SUCCESS',
								payload: snapshots.data,
							});
						})
						.catch(err => console.log(err));
					// Get address
					Axios.get(`${baseUrl}/addresses?filter[where][clientNumber]=${snapshots[0].data.clientNumber}`)
						.then(snapshots => {
							dispatch({
								type: 'ADMIN_GET_ADDRESS_SUCCESS',
								payload: snapshots.data,
							});
						})
						.catch(err => console.log(err));

					dispatch({
						type: 'ADMIN_GET_INVOICE_SUCCESS',
						payload: {
							invoice: snapshots[0].data,
						},
					});
				})
			)
			.catch(error => console.log(error));
	};
};

export const adminPayInvoiceAction = (
	referenceNumber,
	mobile,
	amountPaidKes,
	invoiceNumber,
	paymentType,
	getInvoice
) => {
	const endpoint = `${baseUrl}/payments`;
	let phoneNumber = `254${mobile.substr(mobile.length - 9)}`;
	return dispatch => {
		dispatch({ type: 'ADMIN_PAY_INVOICE' });
		Axios.post(endpoint, { referenceNumber, phoneNumber, amountPaidKes, invoiceNumber, paymentType, getInvoice })
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_PAY_INVOICE_SUCCESS',
					payload: 'Invoice paid.',
				});
				dispatch(getInvoice(invoiceNumber));
			})
			.catch(error => {
				dispatch({
					type: 'ADMIN_INVOICE_LOADING',
				});
				dispatch(handleError(error));
			});
	};
};

export const adminAddCreditAction = (description, amountKes, invoiceNumber, getInvoice) => {
	const endpoint = `${baseUrl}/credits`;
	return dispatch => {
		dispatch({ type: 'ADMIN_ADD_CREDIT' });
		Axios.post(endpoint, { description, amountKes, invoiceNumber })
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_ADD_CREDIT_SUCCESS',
					payload: 'Credit added',
				});
				dispatch(getInvoice(invoiceNumber));
			})
			.catch(error => console.log(error));
	};
};

export const adminAddDebitAction = (description, amountKes, invoiceNumber, getInvoice) => {
	const endpoint = `${baseUrl}/debits`;
	return dispatch => {
		dispatch({ type: 'ADMIN_ADD_DEBIT' });
		Axios.post(endpoint, { description, amountKes, invoiceNumber })
			.then(snapshots => {
				dispatch({
					type: 'ADMIN_ADD_DEBIT_SUCCESS',
					payload: 'Debit added',
				});
				dispatch(getInvoice(invoiceNumber));
			})
			.catch(error => console.log(error));
	};
};

/* Receipts */
export const adminGetInvoiceReceiptsAction = (limit, skip) => {
	// const clientNumber = localStorage.getItem('profileId')
	const endpoint = `${baseUrl}/payments/invoice?filter[skip]=${skip}&filter[limit]=${limit}&filter[order]=paidOn%20DESC`;
	const countEndpoint = `${baseUrl}/payments/invoice/count`;
	return dispatch => {
		dispatch({ type: 'DASH_RECEIPTS' });
		Axios.all([Axios.get(endpoint), Axios.get(countEndpoint)])
			.then(
				Axios.spread((snapshots, count) => {
					dispatch({
						type: 'DASH_RECEIPTS_SUCCESS',
						payload: {
							receipts: snapshots.data,
							count: count.data,
						},
					});
				})
			)
			.catch(error => dispatch(handleError(error)));
	};
};

export const adminGetPackagesReceiptsAction = (limit, skip) => {
	// const clientNumber = localStorage.getItem('profileId')
	const endpoint = `${baseUrl}/payments/packages?limit=${limit || '0'}&skip=${skip || '0'}`;
	const countEndpoint = `${baseUrl}/payments/packages/count`;
	return dispatch => {
		dispatch({ type: 'DASH_PACKAGE_RECEIPTS' });
		Axios.all([Axios.get(endpoint), Axios.get(countEndpoint)])
			.then(
				Axios.spread((snapshots, count) => {
					dispatch({
						type: 'DASH_PACKAGE_RECEIPTS_SUCCESS',
						payload: {
							packageReceipts: snapshots.data,
							count: count.data,
						},
					});
				})
			)
			.catch(error => dispatch(handleError(error)));
	};
};

/* Metrics */
export const adminGetMetricsAction = (startDate, endDate) => {
	Axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
	const merchantsRequest = Axios.get(`${baseUrl}/admins/merchantMetrics`);
	const clientsRequest = Axios.get(`${baseUrl}/admins/clientMetrics`);
	const checkedItemsCountRequest = Axios.get(`${baseUrl}/shipping_items/count?where[status]=checkedIn`);
	const shipItemsSCountRequest = Axios.get(`${baseUrl}/shipping_items/count?where[status]=shipping`);
	const arrItemsSCountRequest = Axios.get(`${baseUrl}/shipping_items/count?where[status]=arrived`);
	const paidItemsSCountRequest = Axios.get(`${baseUrl}/shipping_items/count?where[status]=paid`);
	const invoiceRequest = Axios.get(`${baseUrl}/admins/invoiceMetrics`);
	const messageRequest = Axios.get(`${baseUrl}/admins/messageMetric`);
	const amountRequest = Axios.get(`${baseUrl}/admins/amountMetric/${startDate}/${endDate}`);
	const deliveryRequest = Axios.get(`${baseUrl}/admins/deliveryTotal/${startDate}/${endDate}`);
	const deliveredItemsCountRequest = Axios.get(`${baseUrl}/shipping_items/count?where[status]=delivered`);
	return dispatch => {
		dispatch({ type: 'ADMIN_METRICS' });
		Axios.all([
			merchantsRequest,
			clientsRequest,
			checkedItemsCountRequest,
			shipItemsSCountRequest,
			arrItemsSCountRequest,
			paidItemsSCountRequest,
			invoiceRequest,
			messageRequest,
			amountRequest,
			deliveryRequest,
			deliveredItemsCountRequest,
		])
			.then(
				Axios.spread((...snapshots) => {
					dispatch({
						type: 'ADMIN_METRICS_SUCCESS',
						payload: {
							merchants: snapshots[0].data.metrics,
							clients: snapshots[1].data.metrics,
							itemsCount: {
								checkedIn: snapshots[2].data,
								shipping: snapshots[3].data,
								arrived: snapshots[4].data,
								paid: snapshots[5].data,
								delivered: snapshots[10].data,
							},
							invoice: snapshots[6].data.metrics,
							messages: snapshots[7].data.metrics,
							amount: snapshots[8].data.metrics,
							delivery: snapshots[9].data,
						},
					});
				})
			)
			.catch(error => console.log(error));
	};
};

export const adminEditDeliveryDateAction = (payload, getClient, id, setPackages, setOpenDates) => {
	const endpoint = `${baseUrl}/packages/update_delivery_date`;
	return dispatch => {
		dispatch({ type: 'ADMIN_EDIT_DELIVERY_DATE' });
		Axios.patch(endpoint, payload)
			.then(() => {
				dispatch({ type: 'ADMIN_EDIT_DELIVERY_DATE_SUCCESS', payload: 'Package info updated' });
				dispatch(getClient(id));
				setPackages([]);
				setOpenDates(false);
			})
			.catch(error => dispatch(handleError(error.response.data)));
	};
};
