import { Button, Fab, Fade, Modal, Paper } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Grid, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { compareAsc } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	dashDeleteItemAction,
	dashGetUserInfoAction,
	dashRecentItemsAction,
	dashSanitizedAction,
} from '../../../actions/DashAction';
import { Routes } from '../../../routes';
import { useAppSelector } from '../../../store/store';
import '../../admin/Admin.scss';
import Loader from '../../common/loader/Loader';
import { HttpError, HttpSuccess } from '../../snackbars';
import { SanitizedItems } from './sanitizedItems';

const mql = window.matchMedia(`(max-width: 1024px)`);

const ScrollableTabsButtonAuto = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectProps = createSelector(
		[
			state => state.dash.recentUploads,
			state => state.dash.recentUploadsSuccess,
			state => state.dash.userInfoFetched,
			state => state.dash.recentItemEdited,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
		],
		(recentUploadss, recentUploadsSuccess, userInfoFetched, recentItemEdited, successMsg, errorMsg) => {
			return {
				recentUploadss,
				recentUploadsSuccess,
				userInfoFetched,
				recentItemEdited,
				successMsg,
				errorMsg,
			};
		}
	);

	const { recentUploadss, recentUploadsSuccess, userInfoFetched, recentItemEdited, successMsg, errorMsg } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		eligibleItems: [],
		receivedItems: [],
		invoicedItems: [],
		arrivedItems: [],
		paidItems: [],
		shippedItems: [],
		recentUploads: [],
		invoices: [],
		mobileScreen: mql.matches,
		showModal: false,
		sorted: false,
	});

	const handleClose = () => {
		setState({ ...state, showModal: false });
	};

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashRecentItemsAction());

			// SHow modal when visiting for the first time
			// let notice = localStorage.__notice;

			// if (!notice) {
			// 	setTimeout(() => {
			// 		setState({ ...state, showModal: true });
			// 		localStorage.setItem('__notice', Date.now());
			// 	}, 2000);
			// }
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (recentItemEdited) {
				dispatch(dashRecentItemsAction());
			}
			if (recentUploadsSuccess) {
				const invoicedItems = [];
				const receivedItems = [];
				const shippedItems = [];
				let eligibleItems = [];
				const arrivedItems = [];
				const paidItems = [];
				recentUploadss &&
					recentUploadss.map(pack => {
						if (pack.packageStatus === 'IN_DELAWARE') receivedItems.push(pack);
						if (pack.packageStatus === 'invoiced') invoicedItems.push(pack);
						if (pack.packageStatus === 'shipping') shippedItems.push(pack);
						if (pack.packageStatus === 'arrived') arrivedItems.push(pack);
						if (pack.packageStatus === 'paid') paidItems.push(pack);
						if (
							compareAsc(new Date(pack.packageProtectionEligibiltyExpiry), new Date()) === 1 &&
							!pack.isShipmentProtected
						)
							eligibleItems.push(pack);
						return pack;
					});
				// eligibleItems = eligibleItems.filter(item => !item.isShipmentProtected)

				setState({
					...state,
					invoicedItems,
					receivedItems,
					shippedItems,
					eligibleItems,
					arrivedItems,
					paidItems,
					recentUploads: recentUploadss,
				});
			}
		},
		// eslint-disable-next-line
		[recentUploadsSuccess, recentUploadss, recentItemEdited]
	);

	const onDeleteItem = id => {
		dispatch(dashDeleteItemAction(id));
	};

	const markDelivered = id => {
		dispatch(dashSanitizedAction(id, dashRecentItemsAction));
	};

	const { recentUploads } = state;
	return (
		<div>
			<Modal
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					border: 'none',
				}}
				onClose={handleClose}
				open={state.showModal}
			>
				<Fade in={state.showModal}>
					<div
						style={{
							padding: 24,
							minHeight: 150,
							border: 'none',
							borderRadius: 8,
							background: '#fff',
							minWidth: 300,
							maxWidth: 500,
							textAlign: 'center',
							outline: 'none',
						}}
					>
						<div>
							<Info fontSize={'large'} color={'crimson'} />
							<h4>Welcome to the new Vitumob Platform!</h4>
							<p>
								We are excited to inform you that we have updated our site to give you a better
								experience! While we are currently transitioning all our operations to this platform,
								any older invoices or shipments can be found at{' '}
								<a target='_blank' href='https://old-ship.vitumob.com' rel='noopener noreferrer'>
									{' '}
									old site
								</a>
								. (Current logins are still active).
							</p>
							<p>
								We apologise for any inconvenience caused and look forward to servicing you better on
								this site.
							</p>
							<Button
								style={{
									background: '#f3742a',
								}}
								onClick={handleClose}
							>
								Ok
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '260px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<Paper className='clean_paper filter-wrap'>
							<Typography className='title'>Items</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className='table-wrap'>
							{state.eligibleItems.length ? (
								<Typography
									className='rounded-tl-md rounded-tr-md py-4 px-7'
									style={{ background: 'rgba(0,0,255,0.2)' }}
								>
									<Fab onClick={() => navigate(Routes.EligibleItems)} color='primary' size='small'>
										{state.eligibleItems.length}
									</Fab>
									&nbsp; &nbsp;Item(s) are eligible for protection.&nbsp;
									<Button onClick={() => navigate(Routes.EligibleItems)} color='primary'>
										Protect items
									</Button>
								</Typography>
							) : (
								<span></span>
							)}
							{/* <div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								marginRight: '15px',
							}}
						>
							<Link
								className='sort_packages'
								onClick={() => {
									setState({
										...state,
										sorted: !state.sorted,
									});
								}}
							>
								Sort
								{state.sorted ? <ArrowDropUp htmlColor='white' /> : <ArrowDropDown htmlColor='white' />}
							</Link>
						</div> */}
							{recentUploadsSuccess ? (
								<SanitizedItems
									sorted={state.sorted}
									registeredItems={recentUploads}
									deleteItem={onDeleteItem}
									markDelivered={markDelivered}
								/>
							) : (
								<div className='absolute top-1/2 left-1/2'>
									<Loader />
								</div>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Paper>
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
		</div>
	);
};

export default ScrollableTabsButtonAuto;
