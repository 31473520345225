import { Button, Card, CardContent, CardHeader, Divider, FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import palette from './theme/palette';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	animation: false,
	legend: { display: false },
	cornerRadius: 20,
	color: '#374151',
	tooltips: {
		enabled: true,
		mode: 'index',
		intersect: false,
		borderWidth: 1,
		borderColor: palette.divider,
		backgroundColor: palette.white,
		titleFontColor: palette.text.primary,
		bodyFontColor: palette.text.secondary,
		footerFontColor: palette.text.secondary,
	},
	layout: { padding: 5 },
	scales: {
		xAxes: [
			{
				barThickness: 10,
				maxBarThickness: 1,
				barPercentage: 3.5,
				categoryPercentage: 3.5,
				ticks: {
					fontColor: palette.text.secondary,
				},
				gridLines: {
					display: false,
					drawBorder: false,
				},
			},
		],
		yAxes: [
			{
				ticks: {
					fontColor: palette.text.secondary,
					beginAtZero: true,
					min: 0,
				},
				gridLines: {
					borderDash: [2],
					borderDashOffset: [2],
					color: palette.divider,
					drawBorder: false,
					zeroLineBorderDash: [2],
					zeroLineBorderDashOffset: [2],
					zeroLineColor: palette.divider,
				},
			},
		],
	},
};

const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
	},
	chartContainer: {
		height: 400,
		position: 'relative',
	},
	actions: {
		justifyContent: 'flex-end',
	},
}));

const Stats = props => {
	const { className, packageAnalytics, activeYear, setActiveYear, ...rest } = props;
	const classes = useStyles();

	const labels = () => {
		let label = [];
		if (packageAnalytics && packageAnalytics.length > 0) {
			label = packageAnalytics
				.filter(pkg => pkg.year === `${activeYear}`)
				.sort((a, b) => new Date(a.firstDateMonth) - new Date(b.firstDateMonth))
				.map(pkg => pkg.month);
		}
		return label;
	};
	const packageData = () => {
		let data = [];
		if (packageAnalytics && packageAnalytics.length > 0) {
			data = packageAnalytics
				.filter(pkg => pkg.year === `${activeYear}`)
				.sort((a, b) => new Date(a.firstDateMonth) - new Date(b.firstDateMonth))
				.map(pkg => pkg.packageCount);
		}
		return data;
	};

	const yearLabel = () => {
		let label = 'This Year';
		if (activeYear === new Date().getFullYear() - 1) {
			label = 'Last Year';
		} else if (activeYear < new Date().getFullYear()) {
			label = `${activeYear}`;
		}
		return label;
	};

	const years = () => {
		let years = [];
		if (packageAnalytics && packageAnalytics.length > 0) {
			years = packageAnalytics.map(pkg => pkg.year);
		}

		const unique = years.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});
		return unique;
	};

	const data = {
		labels: labels(),
		datasets: [
			{
				label: yearLabel(),
				backgroundColor: 'blue',
				data: packageData(),
			},
		],
	};

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader
				action={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Button size='small' variant='text'>
							Year
						</Button>
						<FormControl>
							<Select
								placeholder='Year'
								labelId='year_select_label'
								value={activeYear}
								variant='outlined'
								onChange={event => {
									setActiveYear(event.target.value);
								}}
							>
								{years()
									.sort((a, b) => a - b)
									.map((year, i) => (
										<MenuItem key={i} value={year}>
											{year}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</div>
				}
				title={<span style={{ fontSize: '16px' }}>Packages Amount</span>}
			/>
			<Divider />
			<CardContent>
				<div className={classes.chartContainer}>
					{packageAnalytics.length > 0 && <Bar data={data} options={options} />}
				</div>
			</CardContent>
			<Divider />
		</Card>
	);
};

Stats.propTypes = {
	className: PropTypes.string,
};

const Stats1 = props => {
	const { className, paymentAnalytics, activeYear, setActiveYear, ...rest } = props;
	const classes = useStyles();

	const labels = () => {
		let label = [];
		if (paymentAnalytics && paymentAnalytics.length > 0) {
			label = paymentAnalytics
				.filter(pkg => pkg.year === `${activeYear}`)
				.sort((a, b) => new Date(a.firstDateMonth) - new Date(b.firstDateMonth))
				.map(pkg => pkg.month);
		}
		return label;
	};
	const paymentData = () => {
		let data = [];
		if (paymentAnalytics && paymentAnalytics.length > 0) {
			data = paymentAnalytics
				.filter(pkg => pkg.year === `${activeYear}`)
				.sort((a, b) => new Date(a.firstDateMonth) - new Date(b.firstDateMonth))
				.map(pkg => pkg.amountInKsh);
		}
		return data;
	};

	const yearLabel = () => {
		let label = 'This Year';
		if (activeYear === new Date().getFullYear() - 1) {
			label = 'Last Year';
		} else if (activeYear < new Date().getFullYear()) {
			label = `${activeYear}`;
		}
		return label;
	};

	const years = () => {
		let years = [];
		if (paymentAnalytics && paymentAnalytics.length > 0) {
			years = paymentAnalytics.map(pkg => pkg.year);
		}

		const unique = years.filter((value, index, self) => {
			return self.indexOf(value) === index;
		});
		return unique;
	};

	const data = {
		labels: labels(),
		datasets: [
			{
				label: yearLabel(),
				backgroundColor: '#D27D2D',
				data: paymentData(),
			},
		],
	};

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader
				action={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Button size='small' variant='text'>
							Year
						</Button>
						<FormControl>
							<Select
								placeholder='Year'
								labelId='year_select_label'
								value={activeYear}
								variant='outlined'
								onChange={event => {
									setActiveYear(event.target.value);
								}}
							>
								{years()
									.sort((a, b) => a - b)
									.map((year, i) => (
										<MenuItem key={i} value={year}>
											{year}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					</div>
				}
				title={<span style={{ fontSize: '16px' }}>Income Amount</span>}
			/>
			<Divider />
			<CardContent>
				<CardContent>
					<div className={classes.chartContainer}>
						{paymentAnalytics.length > 0 && <Bar data={data} options={options} />}
					</div>
				</CardContent>
			</CardContent>
			<Divider />
		</Card>
	);
};

Stats1.propTypes = {
	className: PropTypes.string,
};

export default Stats;
export { Stats1 };
