import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
	warning: WarningIcon,
	success: CheckCircleIcon,
};

const useStyles = makeStyles(theme => ({
	warning: { backgroundColor: amber[700] },
	success: { backgroundColor: green[600] },
	icon: { fontSize: 20 },
	iconVariant: { opacity: 0.9, marginRight: theme.spacing(1) },
	message: { display: 'flex', alignItems: 'center' },
}));

export const HttpError = ({ errorMessage }) => {
	const classes = useStyles();
	const Icon = variantIcon.warning;
	const [open, setOpen] = React.useState(true);
	function handleClose() {
		setOpen(false);
	}
	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={open}
			autoHideDuration={24000}
			onClose={handleClose}
		>
			<SnackbarContent
				className={clsx(classes.warning)}
				aria-describedby='client-snackbar'
				message={
					<span id='client-snackbar' className={classes.message}>
						<Icon className={clsx(classes.icon, classes.iconVariant)} />
						{errorMessage}
					</span>
				}
				action={[
					<IconButton key='close' aria-label='Close' color='inherit' onClick={handleClose}>
						<CloseIcon className={classes.icon} />
					</IconButton>,
				]}
			/>
		</Snackbar>
	);
};

export const HttpSuccess = ({ successMessage }) => {
	const classes = useStyles();
	const Icon = variantIcon.success;
	const [open, setOpen] = React.useState(true);
	function handleClose() {
		setOpen(false);
	}
	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={open}
			autoHideDuration={24000}
			onClose={handleClose}
		>
			<SnackbarContent
				className={clsx(classes.success)}
				aria-describedby='client-snackbar'
				message={
					<span id='client-snackbar' className={classes.message}>
						<Icon className={clsx(classes.icon, classes.iconVariant)} />
						{successMessage}
					</span>
				}
				action={[
					<IconButton key='close' aria-label='Close' color='inherit' onClick={handleClose}>
						<CloseIcon className={classes.icon} />
					</IconButton>,
				]}
			/>
		</Snackbar>
	);
};
