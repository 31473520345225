import { Paper, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import { ArrowBackIosOutlined, ArrowForwardIosRounded } from '@material-ui/icons'
import TablePagination from '@material-ui/core/TablePagination';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from '../drawer';

import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplyIcon from '@mui/icons-material/Reply';
import { Grid, Menu, MenuItem } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	adminGetClientsAction,
	adminGetClientsJSONAction,
	adminGetFilteredClientsJSONAction,
	searchClientAction,
} from '../../../actions/AdminActions';
import { baseUrl } from '../../../actions/DashAction';
import { useAppSelector } from '../../../store/store';
import Loader from '../../common/loader/Loader';
import ClientsTable from '../../common/tables/clients';
import '../../dashboard/Dashboard.scss';
import '../Admin.scss';
const mql = window.matchMedia(`(max-width: 1024px)`);

const getClientsCount = async viewing => {
	try {
		let data = (await Axios.get(`${baseUrl}/clients/count?where[status]=${viewing ? viewing : ''}`)).data;
		if (data.count) return data.count;
		return 0;
	} catch (err) {
		return 0;
	}
};

function filterClients(collection, substring) {
	const clients = _.filter(
		collection,
		_.flow(
			_.identity,
			_.values,
			_.join,
			_.toLower,
			_.partialRight(_.includes, substring.replace(/\s/g, '').toLowerCase())
		)
	);
	return clients;
}

const AdminClients = props => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.admin.clientsFetched,
			state => state.admin.clients,
			state => state.admin.adminHttpErrors,
			state => state.admin.adminHttpSuccess,
			state => state.admin.clientsJSON,
			state => state.admin.clientsJSONFetched,
			state => state.admin.filteredClientsJSON,
			state => state.admin.filteredClientsJSONFetched,
		],
		(
			clientsFetched,
			clients,
			adminHttpErrors,
			adminHttpSuccess,
			clientsJSON,
			clientsJSONFetched,
			filteredClientsJSON,
			filteredClientsJSONFetched
		) => {
			return {
				clientsFetched,
				clients,
				adminHttpErrors,
				adminHttpSuccess,
				clientsJSON,
				clientsJSONFetched,
				filteredClientsJSON,
				filteredClientsJSONFetched,
			};
		}
	);

	const {
		clientsFetched,
		clients,
		adminHttpErrors,
		adminHttpSuccess,
		clientsJSON,
		clientsJSONFetched,
		filteredClientsJSON,
		filteredClientsJSONFetched,
	} = useAppSelector(selectProps);

	const [state, setState] = useState({
		clients: [],
		clientsJSON: [],
		filteredClientsJSON: [], // for filtered JSON
		clientsCSV: '',
		filteredClientsCSV: '',
		clientsCount: 0,
		filteredClients: [],
		filterString: '',
		filterLimit: 10,
		filterSkip: 0,
		page: 0,
		rowsPerPage: 10,
		viewing: 'active',
	});

	const [filterAnchorEl, setFilterAnchorEl] = useState(null);

	const { filterLimit, filterSkip, viewing } = state;

	useEffect(
		() => {
			const { filterLimit, filterSkip, viewing } = state;
			getClientsCount(viewing).then(v => {
				setState({ ...state, clientsCount: v });
			});
			dispatch(adminGetClientsAction(filterLimit, filterSkip, viewing));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (clientsFetched) {
				setState({ ...state, clients, filteredClients: clients, filteredClientsJSON: [] });
			}
			if (clientsJSONFetched) {
				setState({ ...state, clientsJSON });
				let headers = {
					client_number: 'Client Number',
					name: 'Name',
					mobile: 'Phone Number',
					// "location": "Location",
					// "date_joined": "Date Joined",
					status: 'Status',
				};

				let clients = clientsJSON.map(client => {
					return {
						client_number: `${client.id}`,
						name: `${client.firstName} ${client.lastName}`,
						mobile: `${client.phoneNumber}`,
						// 'location': `${'null*'}`,
						// 'date_joined': `${'null*'}`,
						status: `${client.status}`,
					};
				});
				clients.unshift(headers);

				setState({
					...state,
					clientsCSV: ConvertToCSV(clients),
				});
			}

			if (filteredClientsJSONFetched) {
				setState({ ...state, filteredClientsJSON });
				let headers = {
					client_number: 'Client Number',
					name: 'Name',
					mobile: 'Phone Number',
					// "location": "Location",
					// "date_joined": "Date Joined",
					status: 'Status',
				};

				let clients = filteredClientsJSON.map(client => {
					return {
						client_number: `${client.id}`,
						name: `${client.firstName} ${client.lastName}`,
						mobile: `${client.phoneNumber}`,
						// 'location': `${'null*'}`,
						// 'date_joined': `${'null*'}`,
						status: `${client.status}`,
					};
				});
				clients.unshift(headers);

				setState({
					...state,
					filteredClientsCSV: ConvertToCSV(clients),
				});
			}
		},
		// eslint-disable-next-line
		[clients, clientsFetched, clientsJSON, clientsJSONFetched, filteredClientsJSON, filteredClientsJSONFetched]
	);

	useEffect(
		() => {
			dispatch(adminGetClientsAction(filterLimit, filterSkip, viewing));
		},
		// eslint-disable-next-line
		[filterLimit, filterSkip, viewing]
	);

	const clientStatus = status => {
		let returnValue = '';
		switch (status) {
			case 'active':
				returnValue = <span style={{ color: 'green' }}>Active</span>;
				break;
			case 'inactive':
				returnValue = <span style={{ color: 'red' }}>Inactive</span>;
				break;
			case 'dormant':
				returnValue = <span style={{ color: 'grey' }}>Dormant</span>;
				break;
			default:
				returnValue = <span style={{ color: 'orange' }}>Pending</span>;
				break;
		}
		return returnValue;
	};

	const onChangeRadio = e => {
		switch (e.target.id) {
			case 'active':
				return setState({
					...state,
					viewing: 'active',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
					filteredClientsCSV: '',
				});
			case 'inactive':
				return setState({
					...state,
					viewing: 'inactive',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
					filteredClientsCSV: '',
				});
			case 'dormant':
				return setState({
					...state,
					viewing: 'dormant',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
					filteredClientsCSV: '',
				});
			case 'pending-registration':
				return setState({
					...state,
					viewing: 'pending-registration',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
					filteredClientsCSV: '',
				});
			default:
				return '';
		}
	};

	const onFilterClients = substring => {
		setState({
			...state,
			filterString: substring,
			filteredClients: filterClients(state.clients, substring),
		});
	};

	const onPaginationNext = () => {
		const { filterLimit, filterSkip, clients } = state;
		if (clients.length === 0) return;
		setState({ ...state, filterSkip: filterSkip + filterLimit }, () => {
			const { filterLimit, filterSkip, viewing } = state;
			dispatch(adminGetClientsAction(filterLimit, filterSkip, viewing));
			filterLimit, filterSkip, viewing;
		});
	};

	const onPaginationPrevious = () => {
		const { filterLimit, filterSkip } = state;
		if (filterSkip === 0) return;
		setState({ ...state, filterSkip: filterSkip - filterLimit }, () => {
			const { filterLimit, filterSkip, viewing } = state;
			dispatch(adminGetClientsAction(filterLimit, filterSkip, viewing));
			filterLimit, filterSkip, viewing;
		});
	};

	const patternRegex = key => {
		// eslint-disable-next-line
		return `[A-Za-z.\s]*${key}[A-Za-z.\s]*`;
	};

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip, viewing } = state;
			dispatch(adminGetClientsAction(filterLimit, filterSkip, viewing));
			filterLimit, filterSkip, viewing;
		});
	};

	// Convert JSON to CSV Function
	const ConvertToCSV = objArray => {
		let array = objArray;
		let str = '';

		for (let i = 0; i < array.length; i++) {
			let line = '';
			for (let index in array[i]) {
				if (line !== '') line += ',';

				line += array[i][index];
			}

			str += line + '\r\n';
		}

		return str;
	};

	const openFilter = event => {
		setFilterAnchorEl(event.currentTarget);
	};

	const closeFilter = event => {
		setFilterAnchorEl(null);
	};

	return (
		<>
			<div>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '10vh',
					}}
				>
					{!clientsFetched && (
						<div className='absolute top-1/2 left-1/2'>
							<Loader />
						</div>
					)}
					<Grid container spacing={0}>
						<Grid item xs={6}>
							<Paper className='clean_paper filter-wrap'>
								<Typography className='title'>Clients</Typography>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper className='table-wrap'>
								<div className='flex pt-5 px-10'>
									<button
										className='flex items-center pr-5 border border-solid border-orange-600 bg-orange-600 rounded-lg'
										onClick={() => dispatch(adminGetClientsJSONAction())}
									>
										<ReplyIcon className='h-6 w-6 m-3 text-white' />
										<span className='text-white'>Export CSV - All</span>
									</button>
									{state.clientsCSV !== '' && (
										<div className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg'>
											<CSVLink className='float-right' data={state.clientsCSV}>
												<FileDownloadIcon className='h-6 w-6 m-3 text-orange-600' />
												<span className='text-orange-600'>Download File</span>
											</CSVLink>
										</div>
									)}
								</div>
								<div className='flex justify-between pt-5 px-10'>
									<button
										className='flex items-center pr-5 border border-solid border-orange-600 bg-orange-600 rounded-lg'
										onClick={() => dispatch(adminGetFilteredClientsJSONAction(viewing))}
									>
										<ReplyIcon className='h-6 w-6 m-3 text-white' />
										<span className='text-white'>Export CSV -&nbsp;{clientStatus(viewing)}</span>
									</button>
									{state.filteredClientsCSV !== '' && (
										<div className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg'>
											<CSVLink className='float-right' data={state.filteredClientsCSV}>
												<FileDownloadIcon className='h-6 w-6 m-3 text-orange-600' />
												<span className='text-orange-600'>Download File</span>
											</CSVLink>
										</div>
									)}
								</div>
								<div className='flex py-5 px-10'>
									<div
										className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg cursor-pointer'
										onClick={openFilter}
									>
										<FunnelIcon className='h-6 w-6 m-3 text-indigo-400' />
										<Typography className=''>Filter</Typography>
									</div>
									<div className='ml-10 flex bg-violet-50 sm:w-3/12 border border-solid border-violet-50 rounded-lg'>
										<MagnifyingGlassIcon className='h-6 w-6 m-3 text-indigo-400 ' />
										<input
											className='pl-3 w-full outline-violet-50'
											placeholder='Search Clients'
											value={state.filterString}
											onChange={event => {
												setState({ ...state, filterString: event.target.value });
											}}
											onKeyUp={e => {
												if (e.key === 'Enter' || e.keyCode === 13) {
													const { filterString } = state;
													dispatch(searchClientAction(filterString));
												}
											}}
										/>
									</div>
									<Menu
										id='simple-menu-packages'
										anchorEl={filterAnchorEl}
										keepMounted
										open={Boolean(filterAnchorEl)}
										onClose={closeFilter}
									>
										<MenuItem onClick={closeFilter} className='text-neutral-600'>
											<Typography className='align_icons' style={{ color: 'green' }}>
												<input
													type='radio'
													id='active'
													onChange={onChangeRadio}
													onClick={e => {
														if (e.target.checked) {
															setState({ ...state, viewing: '' });
														}
													}}
													checked={viewing === 'active'}
													style={{ height: '20px', width: '20px', cursor: 'pointer' }}
												/>
												<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Active</span>
											</Typography>
										</MenuItem>
										<MenuItem onClick={closeFilter} className='text-neutral-600'>
											<Typography className='align_icons' style={{ color: 'red' }}>
												<input
													type='radio'
													id='inactive'
													onChange={onChangeRadio}
													onClick={e => {
														if (e.target.checked) {
															setState({ ...state, viewing: '' });
														}
													}}
													checked={viewing === 'inactive'}
													style={{ height: '20px', width: '20px', cursor: 'pointer' }}
												/>
												<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inactive</span>
											</Typography>
										</MenuItem>
										<MenuItem onClick={closeFilter} className='text-neutral-600'>
											<Typography className='align_icons' style={{ color: 'grey' }}>
												<input
													type='radio'
													id='dormant'
													onChange={onChangeRadio}
													onClick={e => {
														if (e.target.checked) {
															setState({ ...state, viewing: '' });
														}
													}}
													checked={viewing === 'dormant'}
													style={{ height: '20px', width: '20px', cursor: 'pointer' }}
												/>
												<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dormant</span>
											</Typography>
										</MenuItem>
										<MenuItem onClick={closeFilter} className='text-neutral-600'>
											<Typography className='align_icons' style={{ color: 'orange' }}>
												<input
													type='radio'
													id='pending-registration'
													onChange={onChangeRadio}
													onClick={e => {
														if (e.target.checked) {
															setState({ ...state, viewing: '' });
														}
													}}
													checked={viewing === 'pending-registration'}
													style={{ height: '20px', width: '20px', cursor: 'pointer' }}
												/>
												<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending</span>
											</Typography>
										</MenuItem>
									</Menu>
								</div>
								<ClientsTable clients={clients} />
								<TablePagination
									component='div'
									count={state.clientsCount}
									page={state.page}
									onPageChange={handleChangePage}
									rowsPerPage={state.rowsPerPage}
									rowsPerPageOptions={[10]}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
				{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
				{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
			</div>
		</>
	);
};

export default AdminClients;
