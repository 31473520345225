import {
	AppBar,
	Badge,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	SwipeableDrawer,
	Toolbar,
	Typography,
	makeStyles,
} from '@material-ui/core';
import {
	AccountCircleOutlined,
	BallotOutlined,
	CheckBoxOutlined,
	DashboardOutlined,
	HelpOutlineOutlined,
	MenuOutlined,
	NotificationsNoneOutlined,
	PaymentOutlined,
} from '@material-ui/icons';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { logout } from '../../actions/AccountsActions';
import { Routes } from '../../routes';
import { useAppSelector } from '../../store/store';
// import { dashGetUserInfoAction, dashRecentItemsAction } from '../../actions/DashAction'

// const iOS = typeof window === 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
const mql = window.matchMedia(`(max-width: 1024px)`);

// function iOS() {
// 	return [
// 	  'iPad Simulator',
// 	  'iPhone Simulator',
// 	  'iPod Simulator',
// 	  'iPad',
// 	  'iPhone',
// 	  'iPod'
// 	].includes(navigator.platform)
// 	// iPad on iOS 13 detection
// 	|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
//   }

export const useStyless = makeStyles({
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
	tabPanel: { padding: '0px' },
	messageInput: { width: '60%', marginLeft: '10%' },
	unpaidInvoiceRow: { borderLeft: '4px solid rgb(255,122,0)' },
	paidInvoiceRow: { borderLeft: '4px solid rgb(0,0,255)' },
});

export const useStyles = {
	drawer: { backgroundColor: '#303E52', border: 'none' },
	listItems: { width: '240px' },
	appBarTitle: { flexGrow: 1 },
	tabPanel: { padding: '0px' },
	messageInput: { width: '60%', marginLeft: '10%' },
	unpaidInvoiceRow: { borderLeft: '4px solid rgb(255,122,0)' },
	paidInvoiceRow: { borderLeft: '4px solid rgb(0,0,255)' },
	paper: {
		// position: 'absolute',
		minWidth: 400,
		backgroundColor: 'white',
	},
};

export const DashDrawer = ({
	state,
	activeLink,
	toggleDrawer,
	openProfile,
	closeProfile,
	profileAnchorEl,
	toggleNotifications,
	onHelp,
}) => {
	const match = useResolvedPath('').pathname;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [statee, setStatee] = useState({
		receivedItems: [],
		eligibleItems: [],
		shippedItems: [],
		invoiceS: [],
		notifications: [],
	});

	const classes = useStyless();

	const selectProps = createSelector(
		[
			state => state.dash.recentUploads,
			state => state.dash.recentUploadsSuccess,
			state => state.dash.invoicesSuccess,
			state => state.dash.invoices,
			state => state.dash.userInfo,
			state => state.dash.userAddress,
			state => state.dash.userInfoFetched,
			state => state.dash.fetchingData,
		],
		(
			recentUploads,
			recentUploadsSuccess,
			invoicesSuccess,
			invoices,
			userInfo,
			userAddress,
			userInfoFetched,
			fetchingData
		) => {
			return {
				recentUploads,
				recentUploadsSuccess,
				invoicesSuccess,
				invoicess: invoices,
				userInfo,
				userAddress,
				userInfoFetched,
				fetchingData,
			};
		}
	);

	const {
		recentUploads,
		recentUploadsSuccess,
		invoicesSuccess,
		invoicess,
		userInfo,
		userAddress,
		userInfoFetched,
		fetchingData,
	} = useAppSelector(selectProps);

	// useEffect(() => {
	//   dispatch(dashGetUserInfoAction());
	//   dispatch(dashRecentItemsAction());
	// },
	// []);

	useEffect(
		() => {
			if (recentUploadsSuccess) {
				let arrivedItems = [];
				let receivedItems = [];
				let shippedItems = [];
				let invoicedItems = [];
				let deliveredItems = [];
				let paidItems = [];
				let eligibleItems = [];
				recentUploads &&
					recentUploads.map(item => {
						if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item);
						if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item);
						if (item.packageStatus === 'IN_NAIROBI') arrivedItems.push(item);
						if (item.packageStatus === 'invoiced') invoicedItems.push(item);
						if (item.packageStatus === 'paid') paidItems.push(item);
						if (item.packageStatus === 'delivered') deliveredItems.push(item);
						if (item.isEligibleForProtection === true) eligibleItems.push(item);
						return item;
					});
				setStatee({ ...state, receivedItems, shippedItems, eligibleItems });
			}

			if (invoicesSuccess) {
				setStatee({ ...state, invoiceS: invoicess });
			}
		},
		// eslint-disable-next-line
		[recentUploadsSuccess, recentUploads, invoicess, invoicesSuccess]
	);

	const { firstName, lastName } = userInfo;

	useEffect(
		() => {
			if (location.pathname !== Routes.EditProfile && userInfoFetched) {
				if (userInfo.firstName === null || userInfo.lastName === null || userInfo.phoneNumber === null) {
					navigate(Routes.EditProfile);
				}
			}
		},
		// eslint-disable-next-line
		[userInfo, userInfoFetched]
	);

	const { receivedItems, eligibleItems, shippedItems, invoices, notifications } = state;

	const receivedItemsLength = receivedItems.length;
	const eligibleItemsLength = eligibleItems.length;
	const shippedItemsLength = shippedItems.length;
	const invoicesLength = invoices ? invoices.length : 0;
	let notificationsLength = state.notifications && statee.notifications.length;

	if (receivedItemsLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}
	if (eligibleItemsLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}
	if (shippedItemsLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}
	if (invoicesLength !== 0) {
		notificationsLength = notificationsLength + 1;
	}

	useEffect(
		() => {
			if (userInfoFetched) {
				if (!userAddress || userAddress.length === 0) {
					navigate('/dashboard/editprofile');
				}
			}
		},
		// eslint-disable-next-line
		[userAddress, userInfoFetched]
	);

	const Notifications =
		notifications &&
		notifications.length > 0 &&
		notifications.map(notification => {
			const colors = ['crimson', 'orange', 'cyan', '#00FFAA', 'lime'];
			const color = colors[Math.floor(Math.random() * colors.length)];
			return (
				<Paper
					key={notification}
					className='dash_notification_paper'
					style={{ borderLeft: `4px solid ${color || '#00FFAA'}` }}
				>
					{notification}
					<Button style={{ color: 'blue' }}>Read</Button>
				</Paper>
			);
		});

	return (
		<div>
			<SwipeableDrawer
				PaperProps={{ classes: { root: classes ? classes.drawer : {} } }}
				open={state.drawerOpen}
				variant={mql.matches ? 'temporary' : 'permanent'}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={true}
				disableDiscovery={false}
			>
				{
					<div
						className={classes.listItems}
						role='presentation'
						onClick={toggleDrawer(false)}
						onKeyDown={toggleDrawer(false)}
					>
						<Paper
							className='dashboard_drawer_logo'
							style={{ cursor: 'pointer' }}
							onClick={() => navigate(Routes.Home)}
						></Paper>
						<Paper className='dashboard_drawer_links'>
							<List>
								<Link to={Routes.Dashboard} className='links'>
									<ListItem
										style={{
											background: activeLink === 'dash' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'dash' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<DashboardOutlined
												htmlColor={activeLink === 'dash' ? '#303E52' : 'white'}
											/>
										</ListItemIcon>
										<ListItemText>Dashboard</ListItemText>
									</ListItem>
								</Link>
								{/*<Link to={Routes.AddItems} className="links">
                  <ListItem className="rt_start_shipping" style={{
                    background: activeLink === 'add'? 'whitesmoke' : '#303E52',
                    color: activeLink === 'add'? '#232C39' : 'white'}}>
                    <ListItemIcon><AddBoxOutlined htmlColor={activeLink === 'add'? "#303E52" : "white"} /></ListItemIcon>
                    <ListItemText>Start shipping</ListItemText>
                  </ListItem>								
                </Link>*/}
								<Link to={Routes.RegisteredItems} className='links'>
									<ListItem
										className='rt_to_track_items'
										style={{
											background: activeLink === 'reg' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'reg' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<CheckBoxOutlined htmlColor={activeLink === 'reg' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Track Items</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.Invoices} className='links'>
									<ListItem
										style={{
											background: activeLink === 'inv' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'inv' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<BallotOutlined htmlColor={activeLink === 'inv' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Invoicing</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.Receipts} className='links'>
									<ListItem
										style={{
											background: activeLink === 'rec' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'rec' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<BallotOutlined htmlColor={activeLink === 'rec' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Receipts</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.Pricing} className='links'>
									<ListItem
										style={{
											background: activeLink === 'prc' ? 'whitesmoke' : '#303E52',
											color: activeLink === 'prc' ? '#232C39' : 'white',
										}}
									>
										<ListItemIcon>
											<PaymentOutlined htmlColor={activeLink === 'prc' ? '#303E52' : 'white'} />
										</ListItemIcon>
										<ListItemText>Pricing</ListItemText>
									</ListItem>
								</Link>
							</List>
							<Divider />
							<List>
								{activeLink === 'dash' || activeLink === 'add' ? (
									<Link onClick={onHelp} style={{ textDecoration: 'none' }} to={`${match}`}>
										<ListItem style={{ color: 'white' }}>
											<ListItemIcon>
												<HelpOutlineOutlined htmlColor='white' />
											</ListItemIcon>
											<ListItemText>Help</ListItemText>
										</ListItem>
									</Link>
								) : (
									<div></div>
								)}
								<Link to={Routes.FAQs} className='links'>
									<ListItem style={{ color: 'white' }}>
										<ListItemText>Frequently asked questions</ListItemText>
									</ListItem>
								</Link>
								<Link to={Routes.TermsAndConditions} className='links'>
									<ListItem style={{ color: 'white' }}>
										<ListItemText>Terms and conditions</ListItemText>
									</ListItem>
								</Link>
							</List>
						</Paper>
					</div>
				}
			</SwipeableDrawer>
			<AppBar
				position='fixed'
				style={{
					paddingLeft: mql.matches ? '0' : '240px',
					background: 'whitesmoke',
					color: '#232C39',
				}}
			>
				<Toolbar>
					<Button onClick={toggleDrawer(true)} className='rt_drawer_menu_icon'>
						<MenuOutlined htmlColor='#303E52' style={{ display: mql.matches ? 'block' : 'none' }} />
					</Button>
					<Typography variant='h6' className={classes.appBarTitle}></Typography>
					{/* <Button onClick={() => navigate(Routes.Messages)}>
            <Badge badgeContent={messages.length} showZero color="primary">
              <MessageOutlined htmlColor="#303E52" />
            </Badge>
          </Button>| */}
					<Button onClick={toggleNotifications}>
						<Badge badgeContent={notificationsLength} showZero color='primary'>
							<NotificationsNoneOutlined htmlColor='#303E52' />
						</Badge>
					</Button>
					|
					<Button onClick={openProfile}>
						<AccountCircleOutlined htmlColor='#303E52' />
						{mql.matches || ('firstName' in userInfo && userInfo.firstName === null)
							? ''
							: `  ${firstName} ${lastName}`}
					</Button>
					{/* Profile Options */}
					<Menu
						id='simple-menu'
						anchorEl={profileAnchorEl}
						keepMounted
						open={Boolean(profileAnchorEl)}
						onClose={closeProfile}
					>
						<MenuItem onClick={closeProfile}>
							{firstName}&nbsp;{lastName}
						</MenuItem>
						<MenuItem onClick={() => dispatch(logout(navigate, 1))}>Logout</MenuItem>
					</Menu>
				</Toolbar>
				<div className='linear_progress'>
					<LinearProgress style={{ display: fetchingData ? 'block' : 'none' }} />
				</div>
			</AppBar>
			{/* Notifications */}
			<Dialog open={state.notificationsDialogOpen} onClose={toggleNotifications} scroll='paper'>
				<DialogTitle>Notifications</DialogTitle>
				<DialogContent>
					{Notifications}
					<Paper
						className='dash_notification_paper'
						style={{
							borderLeft: '4px solid crimson',
							display: receivedItemsLength === 0 ? 'none' : 'block',
						}}
					>
						<span style={{ color: 'blue' }}>{receivedItemsLength}&nbsp;</span>
						Item(s) have been received at the warehouse.
						<Button onClick={() => navigate(`${Routes.RegisteredItems}?tab=1`)} style={{ color: 'blue' }}>
							View items
						</Button>
					</Paper>
					<Paper
						className='dash_notification_paper'
						style={{
							borderLeft: '4px solid orange',
							display: eligibleItemsLength === 0 ? 'none' : 'block',
						}}
					>
						<span style={{ color: 'blue' }}>{eligibleItemsLength}&nbsp;</span>
						Item(s) are eligible for protection
						<Button onClick={() => navigate(Routes.EligibleItems)} style={{ color: 'blue' }}>
							View items
						</Button>
					</Paper>
					<Paper
						className='dash_notification_paper'
						style={{
							borderLeft: '4px solid cyan',
							display: shippedItemsLength === 0 ? 'none' : 'block',
						}}
					>
						<span style={{ color: 'blue' }}>{shippedItemsLength}&nbsp;</span>
						Item(s) have been shipped
						<Button onClick={() => navigate(`${Routes.RegisteredItems}?tab=2`)} style={{ color: 'blue' }}>
							View items
						</Button>
					</Paper>
				</DialogContent>
			</Dialog>
		</div>
	);
};
