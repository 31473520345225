const nodeEnv = process.env.NODE_ENV;
const prodApi = process.env.REACT_APP_PROD_API;
const devApi = process.env.REACT_APP_DEV_API;

export const baseUrl = nodeEnv === 'production' ? prodApi : devApi;

export const deliveryPrices = [
	{ weightFrom: 0, weightTo: 15, region: 'Nairobi', price: 500 },
	{ weightFrom: 0, weightTo: 15, region: 'Central', price: 600 },
	{ weightFrom: 0, weightTo: 15, region: 'Coast', price: 750 },
	{ weightFrom: 0, weightTo: 15, region: 'Western', price: 650 },
	{ weightFrom: 0, weightTo: 15, region: 'Eastern', price: 500 },
	{ weightFrom: 0, weightTo: 15, region: 'Nyanza', price: 750 },
	{ weightFrom: 0, weightTo: 15, region: 'North Eastern', price: 900 },
	{ weightFrom: 0, weightTo: 15, region: 'Rift Valley', price: 1000 },
	{ weightFrom: 16, weightTo: 70, region: 'Nairobi', price: 3000 },
	{ weightFrom: 16, weightTo: 70, region: 'Central', price: 2530 },
	{ weightFrom: 16, weightTo: 70, region: 'Coast', price: 2800 },
	{ weightFrom: 16, weightTo: 70, region: 'Western', price: 2700 },
	{ weightFrom: 16, weightTo: 70, region: 'Eastern', price: 2500 },
	{ weightFrom: 16, weightTo: 70, region: 'Nyanza', price: 27500 },
	{ weightFrom: 16, weightTo: 70, region: 'North Eastern', price: 28000 },
	{ weightFrom: 16, weightTo: 70, region: 'Rift Valley', price: 29500 },
	{ weightFrom: 71, weightTo: 1000, region: 'Nairobi', price: 6500 },
	{ weightFrom: 71, weightTo: 1000, region: 'Central', price: 34000 },
	{ weightFrom: 71, weightTo: 1000, region: 'Coast', price: 35400 },
	{ weightFrom: 71, weightTo: 1000, region: 'Western', price: 34100 },
	{ weightFrom: 71, weightTo: 1000, region: 'Eastern', price: 34200 },
	{ weightFrom: 71, weightTo: 1000, region: 'Nyanza', price: 35000 },
	{ weightFrom: 71, weightTo: 1000, region: 'North Eastern', price: 35500 },
	{ weightFrom: 71, weightTo: 1000, region: 'Rift Valley', price: 38000 },
];

export const Regions = [
	'Ahero',
	'Athi River (Mavoko)',
	'Awasi',
	'Bomet',
	'Bondo',
	'Bungoma',
	'Burnt Forest',
	'Busia',
	'Chuka',
	'Eldama Ravine',
	'Eldoret',
	'Embu',
	'Garissa',
	'Gilgil',
	'Homa Bay',
	'Isiolo',
	'Iten/Tambach',
	'Juja',
	'Kakamega',
	'Kakuma',
	'Kangundo-Tala',
	'Kapenguria',
	'Kapsabet',
	'Karuri',
	'Kehancha',
	'Kendu Bay',
	'Kericho',
	'Keroka',
	'Kerugoya/Kutus',
	'Kiambu',
	'Kikuyu',
	'Kilifi',
	'Kimilili',
	'Kipkelion',
	'Kisii',
	'Kisumu',
	'Kitale',
	'Kitengela',
	'Kitui',
	'Limuru',
	'Litein',
	'Lodwar',
	'Londiani',
	'Luanda',
	'Machakos',
	'Makuyu',
	'Malaba',
	'Malakisi',
	'Malava',
	'Malindi',
	'Mandera',
	'Maragua',
	'Maralal',
	'Mariakani',
	'Matuu',
	'Maua',
	'Mbita Point',
	'Meru',
	'Migori',
	'Molo',
	'Mombasa',
	'Mombasa Musanifu Kombo',
	'Mombasa Nyali Area',
	'Mombasa Stadium Area',
	'Moyale',
	'Mtwapa',
	'Muhoroni',
	'Mumias',
	'Mwingi',
	'Nairobi',
	'Nairobi - Buru-Buru-Donholm',
	'Nairobi - CBD',
	'Nairobi - Embakasi',
	'Nairobi - GSU-EABL-Ngumba',
	'Nairobi - Industrial Area',
	'Nairobi - Kahawa Sukari',
	'Nairobi - Karen',
	'Nairobi - Karen Hardy',
	'Nairobi - Kasarani',
	'Nairobi - Kileleshwa',
	'Nairobi - Kilimani',
	'Nairobi - Langata',
	'Nairobi - Lavington',
	'Nairobi - Loresho',
	'Nairobi - Lower Kabete',
	'Nairobi - Madaraka-Nairobi West',
	'Nairobi - Mountain View',
	'Nairobi - Muthaiga',
	'Nairobi - Muthangari',
	'Nairobi - Ngumo',
	'Nairobi - Nyari-Gigiri',
	'Nairobi - Pangani',
	'Nairobi - Parklands',
	'Nairobi - Ridgeways / Garden Estate',
	'Nairobi - Rosslyn',
	'Nairobi - Runda',
	'Nairobi - South B-South C',
	'Nairobi - Spring Valley',
	'Nairobi - State House',
	'Nairobi - Survey',
	'Nairobi - Thome-Roysambu',
	'Nairobi - Upper Hill',
	'Nairobi - Westlands',
	'Naivasha',
	'Nakuru',
	'Nambale',
	'Nandi Hills',
	'Nanyuki',
	'Narok',
	'Ngong',
	'Nyahururu',
	'Nyamira',
	'Nyansiongo',
	'Nyeri',
	'Ogembo',
	'Ol Kalou',
	'Ongata Rongai',
	'Oyugis',
	'Rongo',
	'Ruiru',
	'Rumuruti',
	'Runyenjes',
	'Siaya',
	'Suneka',
	'Tabaka',
	'Taveta',
	'Thika',
	'TurnUp Regional',
	'Ugunja',
	'Ukunda',
	'Ukwala',
	'Vihiga',
	'Voi',
	'Wajir',
	'Webuye',
	'Wote',
	'Wundanyi',
	'Yala',
];
