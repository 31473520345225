import { Button, Input, InputLabel, Paper, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Routes } from '../../../routes';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from '../drawer';

import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { adminAddUserAction, adminGetMetricsAction } from '../../../actions/AdminActions';
import { useAppSelector } from '../../../store/store';
import '../../dashboard/Dashboard.scss';
import '../Admin.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminAddUser = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[state => state.dash.officerAdded, state => state.dash.adminHttpSuccess, state => state.dash.adminHttpErrors],
		(officerAdded, adminHttpSuccess, adminHttpErrors) => {
			return {
				officerAdded,
				adminHttpSuccess,
				adminHttpErrors,
			};
		}
	);

	const { officerAdded, adminHttpSuccess, adminHttpErrors } = useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		firstName: '',
		lastName: '',
		email: '',
		role: '',
		warehouse: '',
		formErrors: '',
		startDate: moment().add(-30, 'days'),
		endDate: moment(),
		dateError: null,
	});

	useEffect(
		() => {
			if (officerAdded) {
				navigate(Routes.AdminOfficers);
			}
		},
		// eslint-disable-next-line
		[officerAdded]
	);

	const onFormInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const onFormSelectChange = event => {
		const { name, value } = event.target;
		setState({ ...state, [name]: value });
	};

	const onAddOfficer = event => {
		event.preventDefault();
		setState({ ...state, formErrors: '' });
		const { firstName, lastName, email } = state;
		if (!firstName && !lastName && !email) {
			setState({ ...state, formErrors: '* All fields are required' });
			return;
		} else {
			dispatch(adminAddUserAction(email, firstName, lastName));
		}
	};

	const onChangeStartDate = date => {
		setState({ ...state, dateError: null });
		const { endDate } = state;
		if (moment(date).isBefore(endDate)) {
			setState({ ...state, startDate: moment(date) }, () => {
				dispatch(adminGetMetricsAction(date, endDate));
			});
		} else {
			setState({ ...state, dateError: 'Start date should be any day before the end date' });
		}
	};

	const onChangeEndDate = date => {
		setState({ ...state, dateError: null });
		const { startDate } = state;
		if (moment(date).isBefore(moment())) {
			if (moment(date).isAfter(startDate)) {
				setState({ ...state, endDate: moment(date) }, () => {
					dispatch(adminGetMetricsAction(startDate, date));
				});
			} else {
				setState({ ...state, dateError: 'The end date cannot be after the start date' });
			}
		} else {
			setState({ ...state, dateError: 'Oops! we cannot tell the future' });
		}
	};

	// const Warehouses = state.warehouses.map(warehouse => {
	//   return (
	//     <MenuItem value={warehouse.id} key={warehouse.id} >{warehouse.name}</MenuItem>
	//   )
	// })
	// if (!warehousesFetched) {
	// 	return (
	// 		<div className="linear_progress">
	// 			<LinearProgress />
	// 		</div>
	// 	)
	// } else {
	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '16vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Paper className='clean_paper' style={{ fontSize: '1.2em', textAlign: 'center' }}>
							ADD USER FORM
						</Paper>
						<Paper
							style={{
								fontSize: '1.2em',
								margin: '3.2% auto',
								padding: '2%',
								width: mql.matches ? '80%' : '50%',
							}}
						>
							<form onSubmit={onAddOfficer}>
								<InputLabel>First Name</InputLabel>
								<Input
									name='firstName'
									onChange={onFormInputChange}
									required
									value={state.firstName}
									style={{ width: '100%', marginBottom: '5%' }}
								/>
								<InputLabel>Last Name</InputLabel>
								<Input
									name='lastName'
									onChange={onFormInputChange}
									required
									value={state.lastName}
									style={{ width: '100%', marginBottom: '5%' }}
								/>
								<InputLabel>Email</InputLabel>
								<Input
									name='email'
									onChange={onFormInputChange}
									required
									value={state.email}
									style={{ width: '100%', marginBottom: '5%' }}
								/>
								{/* <InputLabel>Warehouse</InputLabel>
                      <Select name="warehouse" onChange={onFormSelectChange} required
                        style={{width: '100%', marginBottom: '2%'}}
                        value={state.warehouse}>
                        {Warehouses}
                      </Select> */}
								<div style={{ color: 'orangered' }}>{state.formErrors}</div>
								<Button type='submit' style={{ background: '#232C39', color: 'white' }}>
									Submit
								</Button>
							</form>
						</Paper>
					</Grid>
				</Grid>
			</Paper>
			{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
			{adminHttpSuccess ? <HttpSuccess errorMessage={adminHttpSuccess} /> : null}
		</div>
	);
};

export default AdminAddUser;
