import { AdminReducer as admin } from './AdminReducer';
import { AuthReducer as auth } from './AuthReducer';
import { dashReducer as dash } from './DashReducer';
import { ForemanReducer as foreman } from './ForemanReducer';
import { OfficerReducer as officer } from './OfficerReducer';

const rootReducer = {
	auth,
	dash,
	admin,
	officer,
	foreman,
};

export default rootReducer;
