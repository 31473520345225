import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Routes } from '.';
import { Sidebar } from '../components/common/Sidebar';
import { useAppSelector } from '../store/store';

const ClientRoute = ({ component }) => {
	const selectAuthAndRole = createSelector(
		[state => state.auth.authenticated, state => state.auth.isClient],
		(isAuthenticated, isClient) => {
			return {
				isAuthenticated,
				isClient,
			};
		}
	);

	const { isAuthenticated, isClient } = useAppSelector(selectAuthAndRole);

	const location = useLocation();
	let expectedPath = location.pathname.substring(0, 20).toString();
	let invoiceId = location.pathname.substring(20, location.pathname.length);
	let checkPath = '/dashboard/invoices/';

	if (expectedPath === checkPath && !isAuthenticated) {
		localStorage.invoiceLink = invoiceId;
	}

	return (
		<>
			{isAuthenticated && isClient ? (
				<div>
					<Sidebar />
					{component}
				</div>
			) : (
				<Navigate to={Routes.Signin} />
			)}
		</>
	);
};

export default ClientRoute;
