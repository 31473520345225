import { configureStore } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import { thunk } from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

const initialState = {};
const middlewares = process.env.NODE_ENV === 'development' ? [thunk, promise, logger] : [thunk, promise];

export const useAppSelector = useSelector;

const store = configureStore({
	reducer: rootReducer,
});

export default store;
