import { Avatar, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes';
import { useAppSelector } from '../../../store/store';

const mql = window.matchMedia(`(max-width: 1024px)`);

const ClientsTable = ({ clients }) => {
	const navigate = useNavigate();

	const selectProps = createSelector([state => state.auth.isAdmin], isAdmin => {
		return {
			isAdmin,
		};
	});

	const { isAdmin } = useAppSelector(selectProps);

	const headCells = [
		{ id: 'clntsindx', numeric: true, disablePadding: true, label: '', align: 'center' },
		{ id: 'clntsclnno', numeric: true, disablePadding: false, label: 'Client No.', align: 'left' },
		{ id: 'clntsnam', numeric: true, disablePadding: false, label: 'Name', align: 'left' },
		{ id: 'clntsphon', numeric: true, disablePadding: false, label: 'Phone', align: 'left' },
		{ id: 'clntsshipd', numeric: true, disablePadding: false, label: 'Last Shipped', align: 'left' },
		{ id: 'clntscrtd', numeric: true, disablePadding: false, label: 'Created On', align: 'left' },
		{ id: 'clntsStts', numeric: true, disablePadding: false, label: 'Status', align: 'left' },
	];

	const clientStatus = status => {
		let returnValue = '';
		switch (status) {
			case 'active':
				returnValue = <span style={{ color: 'green' }}>Active</span>;
				break;
			case 'inactive':
				returnValue = <span style={{ color: 'red' }}>Inactive</span>;
				break;
			case 'dormant':
				returnValue = <span style={{ color: 'grey' }}>Dormant</span>;
				break;
			default:
				returnValue = <span style={{ color: 'orange' }}>Pending</span>;
				break;
		}
		return returnValue;
	};

	return (
		<div className='tables'>
			<Table
				aria-labelledby='tableTitle'
				aria-label='enhanced table'
				className='table'
				style={{ display: mql.matches ? 'none' : 'blocsk' }}
			>
				<TableHead className='table-header'>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell
								className='cell'
								key={headCell.id}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className='table-body'>
					{clients &&
						clients.map((client, index) => {
							return (
								<TableRow key={client.id}>
									<TableCell align='center'>{index + 1}</TableCell>
									<TableCell align='left'>
										<Button
											style={{ color: 'blue' }}
											onClick={() => {
												if (isAdmin) {
													navigate(Routes.AdminClient.replace(':id', `${client.id}`));
												} else {
													navigate(Routes.OfficerClient.replace(':id', `${client.id}`));
												}
											}}
										>
											{`${client.id}`}
										</Button>
									</TableCell>
									<TableCell align='left'>
										{client.firstName} {client.lastName}
									</TableCell>
									<TableCell align='left'>{client.phoneNumber}</TableCell>
									<TableCell align='left'>
										{client.lastShippedAt && format(client.lastShippedAt, 'MMM dd, yyyy')}
									</TableCell>
									<TableCell align='left'>
										{client.user &&
											client.user.createdOn &&
											format(client.user.createdOn, 'MMM dd, yyyy')}
									</TableCell>
									<TableCell align='left'>{clientStatus(client.status)}</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			{/* Mobile screens */}
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				<TableBody className='table-body-mobi'>
					{clients &&
						clients.map((client, index) => {
							return (
								<TableRow key={index} className='table-row-mobi'>
									<TableCell className='cell'>
										<Typography className='pt-5'>
											<Avatar>
												<small>{index + 1}</small>
											</Avatar>
										</Typography>
										<Typography
											className='clickable pt-7'
											onClick={() => {
												if (isAdmin) {
													navigate(Routes.AdminClient.replace(':id', `${client.id}`));
												} else {
													navigate(Routes.OfficerClient.replace(':id', `${client.id}`));
												}
											}}
										>
											<b>Client No.:&nbsp;</b>
											{client.id}
										</Typography>
										<Typography className='pt-2'>
											<b>Name:&nbsp;</b>
											{client.firstName} {client.lastName}
										</Typography>
										<Typography className='pt-2'>
											<b>Phone:&nbsp;</b>
											{client.phoneNumber}
										</Typography>
										<Typography className='pt-2'>
											<b>Last Shipped:&nbsp;</b>
											{client.lastShippedAt && format(client.lastShippedAt, 'MMM dd, yyyy')}
										</Typography>
										<Typography className='pt-2'>
											<b>Created:&nbsp;</b>
											{client.user &&
												client.user.createdOn &&
												format(client.user.createdOn, 'MMM dd, yyyy')}
										</Typography>
										<Typography className='pt-2'>
											<b>Status:&nbsp;</b>
											{clientStatus(client.status)}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</div>
	);
};

export default ClientsTable;
