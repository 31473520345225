import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Input,
	Paper,
	Typography,
	makeStyles,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Menu, MenuItem } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Update from 'react-addons-update';
import { useDispatch } from 'react-redux';
import {
	officerConsolidateAction,
	officerGetDataAction,
	receiveItemsInNairobiAction,
	searchShippingAction,
} from '../../../actions/OfficerActions';
import { useAppSelector } from '../../../store/store';
import '../../admin/Admin.scss';
import ShippingItemsTable from '../../common/tables/ShippingItemsTable';
import '../../dashboard/Dashboard.scss';
import StatusTemplate from '../../officer/localOfficer/statusTemplate';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

function filterItems(collection, substring) {
	const invoices = _.filter(
		collection,
		_.flow(_.identity, _.values, _.join, _.toLower, _.partialRight(_.includes, substring.toLowerCase()))
	);
	return invoices;
}

const LocalOfficerDashboard = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.officer.fetchingData,
			state => state.officer.shippingItems,
			state => state.officer.filteredItems,
			state => state.officer.officerInfo,
			state => state.officer.shippingItemCheckedIn,
			state => state.officer.shippingItemArrived,
			state => state.officer.errorMsg,
			state => state.officer.successMsg,
			state => state.officer.shipmentCount,
		],
		(
			fetchingData,
			shippingItemsRdx,
			filteredItemsRdx,
			officerInfo,
			shippingItemCheckedIn,
			shippingItemArrived,
			errorMsg,
			successMsg,
			shipmentCount
		) => {
			return {
				fetchingData,
				shippingItemsRdx,
				filteredItemsRdx,
				officerInfo,
				shippingItemCheckedIn,
				shippingItemArrived,
				errorMsg,
				successMsg,
				shipmentCount,
			};
		}
	);

	const {
		fetchingData,
		shippingItemsRdx,
		filteredItemsRdx,
		officerInfo,
		shippingItemCheckedIn,
		shippingItemArrived,
		errorMsg,
		successMsg,
		shipmentCount,
	} = useAppSelector(selectProps);

	const [state, setState] = useState({
		page: 0,
		rowsPerPage: 10,
		consolidateBtnOpen: false,
		consolidateDialogOpen: false,
		officerInfo: {},
		shippingItems: [],
		filteredItems: [],
		searchQuery: '',
		consolidatedWeight: '',
		consolidatedLength: '',
		consolidatedWidth: '',
		consolidatedHeight: '',
		formErrors: '',
		filterString: '',
		filterLimit: 10,
		filterSkip: 0,
		shipmentCount: 0,
		viewing: 'IN_DELAWARE',
		newError: false,
		emptyError: false,
		orderBy: 'client',
		order: 'asc',
		packageStatus: false,
		statuses: [],
		shipmentCountFrz: 0,
		shippingItemsFrz: [],
	});

	const [filterAnchorEl, setFilterAnchorEl] = useState(null);
	const [checkedItems, setCheckedItems] = useState([]);

	const {
		filterLimit,
		filterSkip,
		viewing,
		shippingItems,
		consolidatedWeight,
		consolidatedLength,
		consolidatedWidth,
		consolidatedHeight,
		order,
		orderBy,
		filterString,
		shippingItemsFrz,
		shipmentCountFrz,
	} = state;

	useEffect(
		() => {
			dispatch(officerGetDataAction(filterLimit, filterSkip, viewing));
			// log vitumob pay id
			// const code = process.env.REACT_APP_FATTMERCHANT;
			// console.log('VM Code: ', code);
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (shippingItemCheckedIn) {
				dispatch(officerGetDataAction(filterLimit, filterSkip, viewing));
			}
			if (shippingItemArrived) {
				dispatch(officerGetDataAction(filterLimit, filterSkip, viewing));
			}
			setState({
				...state,
				officerInfo,
				shippingItems: shippingItemsRdx,
				shippingItemsFrz: shippingItemsRdx,
				shipmentCount,
				shipmentCountFrz: shipmentCount,
			});
		},
		// eslint-disable-next-line
		[officerInfo, shippingItemsRdx, shippingItemCheckedIn, shippingItemArrived, shipmentCount]
	);

	useEffect(
		() => {
			const checkedItemsFilter = shippingItems.filter(item => item.arrived);
			setCheckedItems(() => [...checkedItemsFilter]);
			if (checkedItemsFilter.length >= 1) {
				setState({ ...state, consolidateBtnOpen: true });
			} else {
				setState({ ...state, consolidateBtnOpen: false });
			}
		},
		// eslint-disable-next-line
		[shippingItems]
	);

	useEffect(
		() => {
			if (filteredItemsRdx && filterString.length > 2) {
				setState({
					...state,
					shippingItems: filteredItemsRdx,
					shipmentCount: filteredItemsRdx.length,
					rowsPerPage: filteredItemsRdx.length,
				});
			} else {
				setState({
					...state,
					shippingItems: shippingItemsFrz,
					shipmentCount: shipmentCountFrz,
					rowsPerPage: 10,
				});
			}
		},
		// eslint-disable-next-line
		[filteredItemsRdx, filterString]
	);

	useEffect(
		() => {
			dispatch(officerGetDataAction(filterLimit, filterSkip, viewing));
		},
		// eslint-disable-next-line
		[filterLimit, filterSkip, viewing]
	);

	const searchFieldChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onFilterItems = substring => {
		setState({
			...state,
			filterString: substring,
			filteredItems: filterItems(state.shippingItems, substring),
		});
	};

	const onConsildateFieldChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const openFilter = event => {
		setFilterAnchorEl(event.currentTarget);
	};

	const closeFilter = event => {
		setFilterAnchorEl(null);
	};

	const onChangeDimensions = (event, type) => {
		const index = shippingItems.findIndex(x => x.id === event.target.name);
		switch (type) {
			case 'weight':
				setState({
					...state,
					shippingItems: Update(shippingItems, { [index]: { weight: { $set: event.target.value } } }),
				});
				break;
			case 'length':
				setState({
					...state,
					shippingItems: Update(shippingItems, { [index]: { dlength: { $set: event.target.value } } }),
				});
				break;
			case 'width':
				setState({
					...state,
					shippingItems: Update(shippingItems, { [index]: { dwidth: { $set: event.target.value } } }),
				});
				break;
			case 'height':
				setState({
					...state,
					shippingItems: Update(shippingItems, { [index]: { dheight: { $set: event.target.value } } }),
				});
				break;
			default:
				return;
		}
	};

	const onCheckPackage = (event, id) => {
		const newShippingItems = [...shippingItems];
		const index = newShippingItems.findIndex(x => x.id === id);
		const shipmentObj = { ...newShippingItems[index] };
		shipmentObj.arrived = event.target.checked;
		newShippingItems[index] = shipmentObj;
		setState({
			...state,
			shippingItems: newShippingItems,
		});
	};

	const onReceiveItems = () => {
		const trackingNumbers = checkedItems.map(item => item.trackingNumber);
		const payload = {};

		payload.trackingNumbers = trackingNumbers;
		if (payload.trackingNumbers === 0) {
			return setState({ ...state, newError: true });
		} else {
			setState({ ...state, newError: false });
		}

		dispatch(receiveItemsInNairobiAction(payload));
		setCheckedItems([]);
	};

	const handleChangePage = (event, newPage) => {
		if (filterString.length > 2) {
			setState({
				...state,
				page: newPage,
			});
		} else {
			setState({
				...state,
				page: newPage,
				filterSkip: newPage * filterLimit,
			});
		}
	};

	const handleChangeRowsPerPage = event => {
		setState({
			...state,
			rowsPerPage: parseInt(event.target.value, 10),
			page: 0,
			filterLimit: parseInt(event.target.value, 10),
			filterSkip: 0,
		});
	};

	const onChangeRadio = e => {
		switch (e.target.id) {
			case 'inDeleware':
				return setState({
					...state,
					viewing: 'IN_DELAWARE',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'inTransit':
				return setState({
					...state,
					viewing: 'IN_TRANSIT',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'inKenya':
				return setState({
					...state,
					viewing: 'IN_KENYA',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'inNairobi':
				return setState({
					...state,
					viewing: 'IN_NAIROBI',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'beingProcessed':
				return setState({
					...state,
					viewing: 'BEING_PROCESSED',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'awaitingPayment':
				return setState({
					...state,
					viewing: 'AWAITING_PAYMENT',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'pendingDelivery':
				return setState({
					...state,
					viewing: 'PENDING_DELIVERY',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			case 'delivered':
				return setState({
					...state,
					viewing: 'DELIVERED',
					filterLimit: 10,
					filterSkip: 0,
					page: 0,
					consolidateBtnOpen: false,
				});
			default:
				return '';
		}
	};

	const onCheckinConsolidatedItems = event => {
		event.preventDefault();

		setState({ ...state, formErrors: '' });
		const trackingNumbers = checkedItems.map(item => item.trackingNumber);
		const clientNumbers = checkedItems.map(item => item.clientNumber);

		const isEqual = clientNumbers.every(clientNumber => clientNumber === clientNumbers[0]);
		if (!isEqual) {
			return setState({ ...state, formErrors: 'Packages must belong to the same client' });
		}

		const lwh = parseFloat(consolidatedLength) * parseFloat(consolidatedWidth) * parseFloat(consolidatedHeight);
		const weight = parseFloat(consolidatedWeight);
		let weightToSend = 0;
		if (lwh) {
			const metricWeight = lwh / 6000;
			if (weight) {
				// Set the larger number
				weightToSend = Math.max(metricWeight, weight);
			} else {
				// Proceed with metric weight only
				weightToSend = metricWeight;
			}
		} else {
			if (weight) {
				// Proceed with weight only
				weightToSend = weight;
			} else {
				setState({ ...state, formErrors: 'Fill weight or all dimensions' });
				return;
			}
		}

		dispatch(officerConsolidateAction(trackingNumbers, clientNumbers[0], weightToSend.toString()));

		setState({
			...state,
			consolidateDialogOpen: false,
			consolidateBtnOpen: false,
			consolidatedWeight: '',
			consolidatedLength: '',
			consolidatedWidth: '',
			consolidatedHeight: '',
			formErrors: '',
		});
		setCheckedItems([]);
	};

	return state.packageStatus ? (
		<div>
			<div style={{ paddingLeft: mql.matches ? '0px' : '259px', paddingTop: '10vh' }}>
				<Button
					onClick={() => {
						setState({ ...state, packageStatus: !state.packageStatus });
					}}
				>
					<ArrowBackIcon className='border border-solid rounded-full m-1' />
				</Button>
			</div>
			<StatusTemplate statusDates={state.statuses} />
		</div>
	) : (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Paper className='table-wrap'>
							<div className='flex py-5 px-10'>
								<div
									className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg cursor-pointer'
									onClick={openFilter}
								>
									<FunnelIcon className='h-6 w-6 m-3 text-indigo-400 ' />
									<Typography className=''>Filter</Typography>
								</div>
								<div className='ml-10 flex bg-violet-50 sm:w-3/12 border border-solid border-violet-50 rounded-lg'>
									<MagnifyingGlassIcon className='h-6 w-6 m-3 text-indigo-400 ' />
									<input
										className='pl-3 w-full outline-violet-50'
										placeholder='Search Items'
										value={state.filterString}
										onChange={event => {
											setState({ ...state, filterString: event.target.value });
											const searchFn = _.debounce(() => {
												if (event.target.value.length > 2) {
													dispatch(searchShippingAction(event.target.value, viewing));
												}
											}, 800);
											searchFn();
										}}
										onKeyUp={e => {
											if (e.key === 'Enter' || e.keyCode === 13) {
												const { filterString, viewing } = state;
												dispatch(searchShippingAction(filterString, viewing));
											}
										}}
									/>
								</div>
								{/* Officer Menu */}
								<Menu
									id='simple-menu-packages'
									anchorEl={filterAnchorEl}
									keepMounted
									open={Boolean(filterAnchorEl)}
									onClose={closeFilter}
								>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'orange' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='inDeleware'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'IN_DELAWARE'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Delaware</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'blue' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='inTransit'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'IN_TRANSIT'}
												style={{ height: '20px', width: '20px', border: '1px solid blue' }}
											/>
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In transit</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'red' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='inKenya'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'IN_KENYA'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Kenya</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'cyan' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='inNairobi'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'IN_NAIROBI'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Nairobi</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'magenta' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='beingProcessed'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'BEING_PROCESSED'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Processing</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography
											className='align_icons cursor-auto'
											style={{ color: 'darkslategray' }}
										>
											<input
												type='radio'
												className='cursor-pointer'
												id='awaitingPayment'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'AWAITING_PAYMENT'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Awaiting Payment
											</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'purple' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='pendingDelivery'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'PENDING_DELIVERY'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pending Delivery
											</span>
										</Typography>
									</MenuItem>
									<MenuItem onClick={closeFilter} className='text-neutral-600'>
										<Typography className='align_icons cursor-auto' style={{ color: 'green' }}>
											<input
												type='radio'
												className='cursor-pointer'
												id='delivered'
												onChange={onChangeRadio}
												onClick={e => {
													if (e.target.checked) {
														setState({ ...state, viewing: '' });
													}
												}}
												checked={viewing === 'DELIVERED'}
												style={{ height: '20px', width: '20px' }}
											/>
											<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delivered</span>
										</Typography>
									</MenuItem>
								</Menu>
							</div>
							<ShippingItemsTable
								shippingItems={shippingItems}
								order={order}
								orderBy={orderBy}
								state={state}
								setState={setState}
								onCheckPackage={onCheckPackage}
								fetchedData={!fetchingData}
							/>
							{/* Mobile Screens */}
							{/* {!fetchingData && ShippingItemsCard} */}
							<TablePagination
								component='div'
								count={state.shipmentCount}
								page={state.page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								rowsPerPage={state.rowsPerPage}
							/>
						</Paper>
					</Grid>
				</Grid>
				<div className='float-wrap'>
					<div className='left'>
						{viewing === 'IN_DELAWARE' ||
						viewing === 'IN_TRANSIT' ||
						viewing === 'IN_KENYA' ||
						viewing === 'IN_NAIROBI' ? (
							<Button
								onClick={() => setState({ ...state, consolidateDialogOpen: true })}
								style={{
									background: '#F47B2A',
									color: 'white',
									bottom: '0',
									left: mql.matches ? '0' : '240px',
									borderRadius: '0',
									display: state.consolidateBtnOpen ? 'block' : 'none',
								}}
							>
								Add to invoice
							</Button>
						) : (
							''
						)}
					</div>
					<div className='right'>
						{viewing === 'IN_DELAWARE' || viewing === 'IN_TRANSIT' || viewing === 'IN_KENYA' ? (
							<span>
								<Button
									onClick={onReceiveItems}
									style={{
										background: '#232C39',
										color: 'white',
										width: '125px',
										borderRadius: '0',
										visibility: state.consolidateBtnOpen ? '' : 'hidden',
									}}
								>
									Receive
								</Button>
								<br />
							</span>
						) : (
							''
						)}
					</div>
				</div>
			</Paper>
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{state.newError ? <HttpError errorMessage={'You have not selected any item'} /> : null}
			{state.emptyError ? <HttpError errorMessage={'Fill weight or all dimensions'} /> : null}
			{/* Consolidate items Dialog */}
			<Dialog
				open={state.consolidateDialogOpen}
				fullWidth
				onClose={() => setState({ ...state, consolidateDialogOpen: false })}
			>
				<DialogTitle style={{ color: 'blue' }}>Consolidated items</DialogTitle>
				<DialogContent>
					{checkedItems.map((item, index) => {
						return (
							<div key={item.id}>
								<Typography style={{ padding: '2%' }}>
									{index + 1}.&nbsp;{item.trackingNumber}
								</Typography>
								<Divider />
							</div>
						);
					})}
					<Card
						style={{
							marginTop: '6%',
							boxShadow: 'none',
							borderRadius: '0',
							borderLeft: '2px solid blue',
						}}
					>
						<CardContent>
							<form onSubmit={onCheckinConsolidatedItems}>
								<Input
									type='number'
									placeholder='Weight'
									name='consolidatedWeight'
									onChange={onConsildateFieldChange}
									value={consolidatedWeight}
									className={classes.smallinput}
								/>
								<Input
									type='number'
									placeholder='Length'
									name='consolidatedLength'
									onChange={onConsildateFieldChange}
									value={consolidatedLength}
									className={classes.smallinput}
								/>
								<Input
									type='number'
									placeholder='Width'
									name='consolidatedWidth'
									onChange={onConsildateFieldChange}
									value={consolidatedWidth}
									className={classes.smallinput}
								/>
								<Input
									type='number'
									placeholder='Height'
									name='consolidatedHeight'
									onChange={onConsildateFieldChange}
									value={consolidatedHeight}
									className={classes.smallinput}
								/>
								<Typography style={{ color: 'orangered' }}>{state.formErrors}</Typography>
								<Button type='submit' style={{ background: '#232C39', color: 'white', margin: '2%' }}>
									Add to Invoice
								</Button>
							</form>
						</CardContent>
					</Card>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default LocalOfficerDashboard;
