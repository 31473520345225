import { Card, CardContent, CardHeader, Paper, Typography } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { Grid } from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { addComma } from '../../utils/stringConverter';
import PriceList from '../static/Pricing';
import './Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const DashPricing = props => {
	const [state, setState] = useState({
		scrollIntoPricingView: false,
		ukLargeWeight: '',
		usaLargeWeight: '',
		usaLessWeight: '',
		chinaLargeWeight: '',
		chinaLessWeight: '',
		turkeyLargeWeight: '',
		turkeyLessWeight: '',
		dubaiLargeWeight: '',
		dubaiLessWeight: '',
		chinaSeaFreight: '',
		chinaSeaFreightGarment: '',
	});

	// componentDidMount() {
	// 	if (queryString.parse(this.props.location.search).from === 'FAQs') {
	// 		setState({ ...state, scrollIntoPricingView: true })
	// 		this.refs.pricing.scrollIntoView()
	// 	}
	// }

	useEffect(
		() => {
			const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
			Axios.get(`${baseUrl}/calculation-variables`)
				.then(snaps => {
					setState({
						...state,
						ukLargeWeight: snaps.data.ukLargeWeight,
						usaLargeWeight: snaps.data.usaLargeWeight,
						usaLessWeight: snaps.data.usaLessWeight,
						chinaLargeWeight: snaps.data.chinaLargeWeight,
						chinaLessWeight: snaps.data.chinaLessWeight,
						turkeyLargeWeight: snaps.data.turkeyLargeWeight,
						turkeyLessWeight: snaps.data.turkeyLessWeight,
						dubaiLargeWeight: snaps.data.dubaiLargeWeight,
						dubaiLessWeight: snaps.data.dubaiLessWeight,
						chinaSeaFreight: snaps.data.chinaSeaFreight,
						chinaSeaFreightGarment: snaps.data.chinaSeaFreightGarment,
					});
				})
				.catch(() => {
					console.log({ calcErrors: 'An error occured while getting calculation rates.' });
				});
		},
		// eslint-disable-next-line
		[]
	);

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid xs={mql.matches ? 12 : 6}>
						<Card style={{ margin: '2%' }}>
							<CardHeader title='United States shipping rates' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									Up to 2 kg - ${state.usaLessWeight}.
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />2 kg or more - ${state.usaLargeWeight} per kg.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={mql.matches ? 12 : 6}>
						<Card style={{ margin: '2%' }}>
							<CardHeader title='United Kingdom shipping rates' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									{/* Up to 2 kg - ${state.ukLargeWeight}. */}
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />£{state.ukLargeWeight} per kg.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={mql.matches ? 12 : 6}>
						<Card style={{ margin: '2%' }}>
							<CardHeader title='Turkey shipping rates' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									Up to 2 kg - ${state.turkeyLessWeight}.
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />2 kg or more - ${state.turkeyLargeWeight} per kg.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={mql.matches ? 12 : 6}>
						<Card style={{ margin: '2%' }}>
							<CardHeader title='Dubai shipping rates' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									Up to 2 kg - ${state.dubaiLessWeight}.
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />2 kg or more - ${state.dubaiLargeWeight} per kg.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={mql.matches ? 12 : 6}>
						<Card style={{ margin: '2%' }}>
							<CardHeader title='China air freight shipping rates' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									Up to 2 kg - ${state.chinaLessWeight}.
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />2 kg or more - ${state.chinaLargeWeight} per kg.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={mql.matches ? 12 : 6}>
						<Card style={{ margin: '2%' }}>
							<CardHeader title='China sea cargo shipping rates' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									{/* Up to 2 kg - Kshs. {state.chinaSeaFreight}. */}
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									Kshs. {addComma(state.chinaSeaFreight)} per cbm.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12}>
						<Card style={{ margin: '1%' }}>
							<CardHeader title='China sea cargo shipping rates (Garments)' />
							<CardContent>
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									{/* Up to 2 kg - Kshs. {state.chinaSeaFreightGarment}. */}
								</Typography>
								<br />
								<Typography style={{ fontSize: '1.2em' }} className='align_icons'>
									<ArrowRight />
									Kshs. {addComma(state.chinaSeaFreightGarment)} per cbm.
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12}>
						<Card style={{ margin: '1%' }}>
							<CardHeader title='Delivery fees' />
							<PriceList />
						</Card>
					</Grid>
				</Grid>
			</Paper>
		</div>
	);
};

export default DashPricing;
