import { Button, Card, CardActions, CardContent, CardHeader, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { HttpError, HttpSuccess } from '../snackbars';

import { useDispatch, useSelector } from 'react-redux';
import { officerGetOfficerInfoAction } from '../../actions/OfficerActions';
import '../admin/Admin.scss';
import '../dashboard/Dashboard.scss';

const OfficerSuspended = () => {
	const dispatch = useDispatch();

	const { auth, officer } = useSelector(state => ({
		auth: state.auth,
		officer: state.officer,
	}));

	const [state, setState] = useState({
		officerInfo: {},
	});

	useEffect(
		() => {
			dispatch(officerGetOfficerInfoAction());
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { officerInfo } = officer;
			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			setState({ ...state, officerInfo });
		},
		// eslint-disable-next-line
		[auth, officer]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const { first_name, last_name } = officer.officerInfo;
	const { errorMsg, successMsg, officerInfoFetched } = officer;

	if (!officerInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		return (
			<div>
				<Card style={{ margin: '5%', marginTop: '30vh', borderLeft: '4px solid #232C39' }}>
					<CardHeader title={`${first_name} ${last_name}`} />
					<CardContent>Your account has been suspended. Please contact the administrator</CardContent>
					<CardActions>
						<Button onClick={onLogout} style={{ background: '#232C39', color: 'white' }}>
							Logout
						</Button>
					</CardActions>
				</Card>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
		);
	}
};

export default OfficerSuspended;
