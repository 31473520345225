import {
	Button,
	Card,
	CardContent,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Input,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { DoneOutlined, FlightLand, FlightTakeoff, PaymentOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import Moment from 'moment'
import TablePagination from '@material-ui/core/TablePagination';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Routes } from '../../../routes';

import { Avatar } from '@mui/material';
import { adminEditDeliveryDateAction, adminGetClientAction } from '../../../actions/AdminActions';
import '../../admin/Admin.scss';
// import { it } from 'date-fns/locale'

const mql = window.matchMedia(`(max-width: 1024px)`);

/* Sanitized Items */
export const SanitizedItems = ({ sorted, registeredItems, deleteItem, markDelivered }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();

	const [count, setCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [packages, setPackages] = useState([]);
	const [openDates, setOpenDates] = useState(false);
	const [formErrors] = useState('');
	const [dateInput, setDateInput] = useState('');

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const { isClient, isOfficer, isAdmin } = useSelector(state => ({
		isClient: state.auth.isClient,
		isOfficer: state.auth.isOfficer,
		isAdmin: state.auth.isAdmin,
	}));

	const redirectTo = () => {
		if (isClient) {
			return Routes.DashHistory;
		}
		if (isOfficer) {
			return Routes.PackageHistory;
		}
		if (isAdmin) {
			return;
		}
	};

	const itemStatus = (status, client) => {
		if (status === 'IN_DELAWARE') {
			return (
				<Typography className='align_icons' style={{ color: 'orange' }}>
					<DoneOutlined htmlColor='orange' />
					&nbsp;Arrived in the US warehouse
				</Typography>
			);
		}
		if (client) {
			if (status === 'IN_KENYA' || status === 'IN_NAIROBI' || status === 'BEING_PROCESSED') {
				return (
					<Typography className='align_icons' style={{ color: 'magenta' }}>
						<AutorenewIcon htmlColor='magenta' />
						&nbsp;At Customs
					</Typography>
				);
			}
		} else {
			if (status === 'IN_KENYA') {
				return (
					<Typography className='align_icons' style={{ color: 'red' }}>
						<FlightLand htmlColor='red' />
						&nbsp;In Kenya
					</Typography>
				);
			}
			if (status === 'IN_NAIROBI') {
				return (
					<Typography className='align_icons' style={{ color: 'cyan' }}>
						<DoneOutlined htmlColor='cyan' />
						&nbsp;In Nairobi
					</Typography>
				);
			}
			if (status === 'BEING_PROCESSED') {
				return (
					<Typography className='align_icons' style={{ color: 'magenta' }}>
						<AutorenewIcon htmlColor='magenta' />
						&nbsp;Being Processed
					</Typography>
				);
			}
		}
		if (status === 'IN_TRANSIT') {
			return (
				<Typography className='align_icons' style={{ color: 'blue' }}>
					<FlightTakeoff htmlColor='blue' />
					&nbsp;In transit
				</Typography>
			);
		}

		if (status === 'AWAITING_PAYMENT') {
			return (
				<Typography className='align_icons' style={{ color: 'darkslategray' }}>
					<PaymentOutlined htmlColor='darkslategray' />
					&nbsp;Awaiting Payment
				</Typography>
			);
		}
		if (status === 'PENDING_DELIVERY') {
			return (
				<Typography className='align_icons' style={{ color: 'purple' }}>
					<FlightTakeoff htmlColor='purple' />
					&nbsp;Pending Delivery
				</Typography>
			);
		}
		if (status === 'DELIVERED') {
			return (
				<Typography className='align_icons' style={{ color: 'green' }}>
					<DoneOutlined htmlColor='green' />
					&nbsp;Delivered
				</Typography>
			);
		}
		return <Typography className='align_icons'>{status}</Typography>;
	};

	// const validateDate = (date) => {
	//   return date.replace("th, ", "")
	//     .replace("st, ", "")
	//     .replace("rd, ", "")
	// }
	// const archivedFalse = registeredItems.filter(item => item.archived === false);
	// let archivedFalse = registeredItems && registeredItems.length > 0 ? registeredItems.sort((a, b) => {
	//   return Moment(validateDate(a.estimatedPackageArrivalDate)).isBefore(validateDate(b.estimatedPackageArrivalDate)) ? 1 : -1
	// }
	// ) : [];

	// let archivedFalse = [];
	// const toReverse = registeredItems && registeredItems.length > 0 ? [...registeredItems] : [];

	// if (sorted) {
	// 	archivedFalse = registeredItems;
	// } else {
	// 	archivedFalse = toReverse.reverse();
	// }

	// useEffect(
	// 	() => {
	// 		setCount(archivedFalse.length);
	// 		console.log(count);
	// 	},
	// 	// eslint-disable-next-line
	// 	[archivedFalse]
	// );

	const headCells = [
		{ id: 'sntizdindx', view: true, numeric: true, disablePadding: false, label: 'Tracking No.', align: 'left' },
		{ id: 'sntizdclnno', view: true, numeric: true, disablePadding: false, label: 'Shipping No.', align: 'left' },
		{ id: 'sntizdnam', view: true, numeric: true, disablePadding: false, label: 'Status', align: 'left' },
		{
			id: 'sntizdphon',
			view: true,
			numeric: true,
			disablePadding: false,
			label: 'Estimated Arrival',
			align: 'left',
		},
		{ id: 'sntizdshipd', view: true, numeric: true, disablePadding: false, label: 'Action', align: 'left' },
		{ id: 'sntizdStts', view: isAdmin, numeric: true, disablePadding: false, label: 'Arrival', align: 'left' },
	];

	const Items =
		registeredItems &&
		registeredItems.map((item, index) => {
			if (mql.matches) {
				return (
					<TableRow key={item.id}>
						<TableCell>
							{item.packageStatus === 'registered' ? (
								<>
									<span
										onClick={() => navigate(redirectTo().replace(':id', `${item.id}`))}
										style={{ cursor: 'pointer', color: 'blue' }}
									>
										{item.trackingNumber}
									</span>
									<br />
									<span style={{ color: 'gray' }}>
										{/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
									</span>
									<br />
									{item.shipmentNumber}
									<br />
									{itemStatus(item.packageStatus, isClient)}&nbsp;&nbsp;
									<Link
										to={`/dashboard/registered_items/edit/${item.id}`}
										style={{ textDecoration: 'none', color: 'blue', fontSize: '1.2em' }}
									>
										Edit item
									</Link>
									&nbsp;&nbsp;
									<Button
										onClick={() => deleteItem(item.id)}
										style={{ textTransform: 'none', color: 'orangered' }}
									>
										Delete
									</Button>
								</>
							) : (
								<>
									<span
										onClick={() => navigate(redirectTo().replace(':id', `${item.id}`))}
										style={{ cursor: 'pointer', color: 'blue' }}
									></span>
									<Typography className='pt-5'>
										<Avatar>
											<small>{index + 1}</small>
										</Avatar>
									</Typography>
									<Typography
										className='clickable pt-7'
										onClick={() => navigate(redirectTo().replace(':id', `${item.id}`))}
									>
										<b>Tracking No.:&nbsp;</b>
										{item.trackingNumber}
									</Typography>
									<br />
									<span style={{ color: 'gray' }}>
										{/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
									</span>
									<Typography className='pt-2'>
										<b>Shipment No.:&nbsp;</b>
										{item.shipmentNumber}
									</Typography>
									<Typography className='pt-2'>
										<b>Status:&nbsp;</b>
										{itemStatus(item.packageStatus, isClient)}
									</Typography>
								</>
							)}
							<Typography className='pt-2'>
								<b>Estimated Arrival:&nbsp;</b>
								{item.estimatedPackageArrivalDate}
							</Typography>
							{item.packageStatus === 'paid' ? (
								<Typography>
									<button
										onClick={() => {
											markDelivered(item.id);
										}}
									>
										Mark Delivered
									</button>
								</Typography>
							) : (
								<Typography></Typography>
							)}
							{isAdmin && (
								<Typography>
									<Checkbox
										color='primary'
										checked={packages.includes(item.trackingNumber)}
										onClick={e => {
											e.preventDefault();
											if (packages.includes(item.trackingNumber)) {
												let spread = [...packages];
												let index = spread.indexOf(item.trackingNumber);
												spread.splice(index, 1);
												setPackages(spread);
											} else {
												let spread = [...packages];
												spread.push(item.trackingNumber);
												setPackages(spread);
											}
										}}
									/>
								</Typography>
							)}
						</TableCell>
					</TableRow>
				);
			}
			return (
				<TableRow key={item.id}>
					{item.packageStatus === 'registered' ? (
						<TableCell align='left'>
							<span
								onClick={() => navigate(redirectTo().replace(':id', `${item.id}`))}
								className='clickable'
							>
								{item.trackingNumber}
							</span>
							<br />
							<span style={{ color: 'gray' }}>
								{/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
							</span>
							<br />
							<Link
								to={`/dashboard/registered_items/edit/${item.id}`}
								style={{ textDecoration: 'none', color: 'blue', fontSize: '1.2em' }}
							>
								Edit item
							</Link>
							&nbsp;&nbsp;
							<Button
								onClick={() => deleteItem(item.id)}
								style={{ textTransform: 'none', color: 'orangered' }}
							>
								Delete
							</Button>
						</TableCell>
					) : (
						<TableCell align='left'>
							<span
								className='clickable'
								onClick={() => navigate(redirectTo().replace(':id', `${item.id}`))}
							>
								{item.trackingNumber}
							</span>
							<br />
							<span style={{ color: 'gray' }}>
								{/* Registered:&nbsp;{Moment(item.createdAt).format('LL')} */}
							</span>
							<br />
						</TableCell>
					)}
					<TableCell align='left'>{item.shipmentNumber}</TableCell>
					<TableCell align='left'>{itemStatus(item.packageStatus, isClient)}</TableCell>
					<TableCell align='left'>{item.estimatedPackageArrivalDate}</TableCell>
					{item.packageStatus === 'paid' ? (
						<TableCell align='left'>
							<button
								onClick={() => {
									markDelivered(item.id);
								}}
							>
								Mark Delivered
							</button>
						</TableCell>
					) : (
						<TableCell align='left'></TableCell>
					)}
					{isAdmin && (
						<TableCell align='left'>
							<Checkbox
								color='primary'
								checked={packages.includes(item.trackingNumber)}
								onClick={e => {
									e.preventDefault();
									if (packages.includes(item.trackingNumber)) {
										let spread = [...packages];
										let index = spread.indexOf(item.trackingNumber);
										spread.splice(index, 1);
										setPackages(spread);
									} else {
										let spread = [...packages];
										spread.push(item.trackingNumber);
										setPackages(spread);
									}
								}}
							/>
						</TableCell>
					)}
				</TableRow>
			);
		});

	if (mql.matches) {
		return (
			<>
				<Dialog open={openDates} fullWidth onClose={() => setOpenDates(false)}>
					<DialogTitle style={{ color: 'blue' }}>Update Delivery Date</DialogTitle>
					<DialogContent>
						{packages.map((item, index) => {
							return (
								<div key={index}>
									<Typography style={{ padding: '2%' }}>
										{index + 1}.&nbsp;{item}
									</Typography>
									<Divider />
								</div>
							);
						})}
					</DialogContent>
					<Card
						style={{
							marginTop: '6%',
							boxShadow: 'none',
							borderRadius: '0',
							borderLeft: '2px solid blue',
						}}
					>
						<CardContent>
							<form
								onSubmit={e => {
									e.preventDefault();

									const payload = {
										trackingNumbers: packages,
										delivery_date: dateInput,
									};
									dispatch(
										adminEditDeliveryDateAction(
											payload,
											adminGetClientAction,
											params.id,
											setPackages,
											setOpenDates
										)
									);
								}}
							>
								<Input
									type='date'
									placeholder='Date'
									name='date'
									onChange={e => {
										setDateInput(e.target.value);
									}}
									style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
								/>
								<Typography style={{ color: 'orangered' }}>{formErrors}</Typography>
								<Button type='submit' style={{ background: '#232C39', color: 'white', margin: '2%' }}>
									Change Date
								</Button>
							</form>
						</CardContent>
					</Card>
				</Dialog>
				<Table className='table-mobi'>
					<TableBody className='table-body-mobi'>
						{Items && Items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
					</TableBody>
				</Table>
				<TablePagination
					component='div'
					count={registeredItems && registeredItems.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[5]}
					onRowsPerPageChange={p => {
						console.log(p);
						setRowsPerPage(p);
					}}
					// style={{ margin: '0 auto', width: '100%' }}
				/>
				<div>
					<div>
						{packages.length > 0 && (
							<Button
								style={{
									background: 'darkgreen',
									color: 'white',
									position: 'fixed',
									bottom: '0',
									left: mql.matches ? '0' : '240px',
									borderRadius: '0',
									//display: this.state.consolidateBtnOpen ? 'block' : 'none'
								}}
								onClick={() => {
									setOpenDates(!openDates);
								}}
							>
								Change Date
							</Button>
						)}
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			<Dialog open={openDates} fullWidth onClose={() => setOpenDates(false)}>
				<DialogTitle style={{ color: 'blue' }}>Update Delivery Date</DialogTitle>
				<DialogContent>
					{packages.map((item, index) => {
						return (
							<div key={index}>
								<Typography style={{ padding: '2%' }}>
									{index + 1}.&nbsp;{item}
								</Typography>
								<Divider />
							</div>
						);
					})}
				</DialogContent>
				<Card
					style={{
						marginTop: '6%',
						boxShadow: 'none',
						borderRadius: '0',
						borderLeft: '2px solid blue',
					}}
				>
					<CardContent>
						<form
							onSubmit={e => {
								e.preventDefault();

								const payload = {
									trackingNumbers: packages,
									delivery_date: dateInput,
								};
								dispatch(
									adminEditDeliveryDateAction(
										payload,
										adminGetClientAction,
										params.id,
										setPackages,
										setOpenDates
									)
								);
							}}
						>
							<Input
								type='date'
								placeholder='Date'
								name='date'
								onChange={e => {
									setDateInput(e.target.value);
								}}
								style={{ width: '46%', margin: '2%', minHeight: 0, minWidth: 0 }}
							/>
							<Typography style={{ color: 'orangered' }}>{formErrors}</Typography>
							<Button type='submit' style={{ background: '#232C39', color: 'white', margin: '2%' }}>
								Change Date
							</Button>
						</form>
					</CardContent>
				</Card>
			</Dialog>
			<div className='tables'>
				<Table aria-labelledby='tableTitle' aria-label='enhanced table' className='table'>
					<TableHead className='table-header'>
						<TableRow>
							{headCells
								.filter(cell => cell.view)
								.map(headCell => (
									<TableCell
										className='cell'
										key={headCell.id}
										align={headCell.align}
										padding={headCell.disablePadding ? 'none' : 'normal'}
									>
										{headCell.label}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody className='table-body'>
						{Items && Items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
					</TableBody>
				</Table>
			</div>
			<TablePagination
				component='div'
				count={registeredItems && registeredItems.length}
				page={page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10]}
				onRowsPerPageChange={e => {
					setRowsPerPage(e.target.value);
				}}
			/>
			<div>
				<div>
					{packages.length > 0 && (
						<Button
							style={{
								background: 'darkgreen',
								color: 'white',
								position: 'fixed',
								bottom: '0',
								left: mql.matches ? '0' : '240px',
								borderRadius: '0',
							}}
							onClick={() => {
								setOpenDates(!openDates);
							}}
						>
							Change Date
						</Button>
					)}
				</div>
			</div>
		</>
	);
};
