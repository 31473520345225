import Spinner from '../../../assets/images/loading.gif';

const Loader = () => {
	return (
		<div className='flex items-center'>
			<div className=''>
				<img className='' src={Spinner} />
			</div>
		</div>
	);
};

export default Loader;
