import { CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import React, { useRef } from 'react';

const mql = window.matchMedia(`(max-width: 1024px)`);

const PriceList = () => {
	const pricing = useRef();
	return (
		<Grid container spacing={0}>
			<Grid item xs={mql.matches ? 12 : 6}>
				<CardContent ref={pricing}>
					<Typography style={{ fontSize: '1.3em' }}>Self collection at our warehouse</Typography>
					<Divider />
					<CardContent>
						<Typography className='align_icons'>
							<ArrowRight />
							Free
						</Typography>
					</CardContent>
				</CardContent>
				<CardContent>
					<Typography style={{ fontSize: '1.3em' }}>Within Nairobi</Typography>
					<Divider />
					<CardContent>
						<Typography className='align_icons'>
							<ArrowRight />
							Motorbike (Items below 15Kgs): Ksh. 500
						</Typography>
						<Typography className='align_icons'>
							<ArrowRight />
							Vehicle (Items between 16 - 70Kgs): Ksh. 3,000
						</Typography>
						<Typography className='align_icons'>
							<ArrowRight />
							Pick Up / Van (Items between 71 - 1,000Kgs): Ksh. 6,500
						</Typography>
						<Typography className='align_icons'>
							<ArrowRight />
							Lorry: Ksh. 10,000
						</Typography>
					</CardContent>
				</CardContent>
			</Grid>
			<Grid item xs={mql.matches ? 12 : 6}>
				<CardContent>
					<Typography style={{ fontSize: '1.3em' }}>Outside Nairobi</Typography>
					<Divider />
					<CardContent>
						<Typography className='align_icons'>
							<ArrowRight />
							Items below 15Kgs
							<br />
							1. Central region: Ksh. 600
							<br />
							2. Coast region: Ksh. 750
							<br />
							3. Western region: Ksh. 650
							<br />
							4. Eastern region: Ksh. 500
							<br />
							5. Nyanza region: Ksh. 750
							<br />
							6. North Eastern region: Ksh. 900
							<br />
							7. Rift Valley region: Ksh. 1,000
							<br />
						</Typography>
						<Typography className='align_icons'>
							<ArrowRight />
							Items between 16 - 70Kgs
							<br />
							1. Central region: Ksh. 2,530
							<br />
							2. Coast region: Ksh. 2,800
							<br />
							3. Western region: Ksh. 2,700
							<br />
							4. Eastern region: Ksh. 2,500
							<br />
							5. Nyanza region: Ksh. 27,500
							<br />
							6. North Eastern region: Ksh. 28,000
							<br />
							7. Rift Valley region: Ksh. 29,500
							<br />
						</Typography>
						<Typography className='align_icons'>
							<ArrowRight />
							Items between 71 - 1,000Kgs
							<br />
							1. Central region: Ksh. 34,000
							<br />
							2. Coast region: Ksh. 35,400
							<br />
							3. Western region: Ksh. 34,100
							<br />
							4. Eastern region: Ksh. 34,200
							<br />
							5. Nyanza region: Ksh. 35,000
							<br />
							6. North Eastern region: Ksh. 35,500
							<br />
							7. Rift Valley region: Ksh. 38,000
							<br />
						</Typography>
					</CardContent>
				</CardContent>
			</Grid>
		</Grid>
	);
};

export default PriceList;
