import { Grid, Paper, Typography } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../actions/DashAction';
import { Routes } from '../../../routes';
import { OfficersListing } from '../../../utils/officersUtil';
import { HttpError, HttpSuccess } from '../../snackbars';

import { FunnelIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { adminGetOfficersAction, searchOfficerAction } from '../../../actions/AdminActions';
import { useAppSelector } from '../../../store/store';
import Loader from '../../common/loader/Loader';
import '../../dashboard/Dashboard.scss';
import '../Admin.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

export const getOffiersCount = async () => {
	try {
		let data = (await Axios.get(`${baseUrl}/officers/count`)).data;
		if (data.count) return data.count;
		return 0;
	} catch (err) {
		return 0;
	}
};

const AdminOfficers = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectProps = createSelector(
		[
			state => state.admin.officersFetched,
			state => state.admin.officers,
			state => state.admin.adminHttpErrors,
			state => state.admin.adminHttpSuccess,
		],
		(officersFetched, officers, adminHttpErrors, adminHttpSuccess) => {
			return {
				officersFetched,
				officers,
				adminHttpErrors,
				adminHttpSuccess,
			};
		}
	);

	const { officersFetched, officers, adminHttpErrors, adminHttpSuccess } = useAppSelector(selectProps);

	const [state, setState] = useState({
		officers: [],
		roles: ['local_officer', 'local_foreman', 'int_officer', 'int_foreman'],
		rolesSanitized: ['Officer', 'Foreman', 'Officer', 'Foreman'],
		count: 0,
		page: 0,
		limit: 10,
		rowsPerPage: 10,
		skip: 0,
		filterString: '',
	});

	useEffect(
		() => {
			getOffiersCount().then(v => {
				setState({ ...state, count: v });
			});
			const { limit, skip } = state;
			dispatch(adminGetOfficersAction(limit, skip));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			setState({ ...state, officers });
		},
		// eslint-disable-next-line
		[officers]
	);

	const handleChangePage = (event, newPage) => {
		const { limit } = state;

		setState({ ...state, page: newPage, skip: newPage * limit }, () => {
			const { limit, skip } = state;
			dispatch(adminGetOfficersAction(limit, skip));
		});
	};

	return (
		<>
			<div>
				<Paper
					square={true}
					style={{
						background: '#fff',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '10vh',
					}}
				>
					{!officersFetched && (
						<div className='absolute top-1/2 left-1/2'>
							<Loader />
						</div>
					)}
					<Grid container spacing={0}>
						<Grid item xs={6}>
							<Paper className='clean_paper table-wrap'>
								<Typography className='title'>Officers</Typography>
							</Paper>
						</Grid>

						<Grid item xs={12}>
							<Paper className='table-wrap'>
								<div className='flex py-5 px-10 justify-between'>
									<div className='w-full sm:1-12 flex'>
										<div className='flex items-center pr-5 border border-solid border-violet-50 rounded-lg cursor-pointer'>
											<FunnelIcon className='h-6 w-6 m-3 text-indigo-400 ' />
											<Typography className=''>Filter</Typography>
										</div>
										<div className='ml-10 flex bg-violet-50 w-full sm:w-3/12 border border-solid border-violet-50 rounded-lg'>
											<MagnifyingGlassIcon className='h-6 w-6 m-3 text-indigo-400 ' />
											<input
												className='pl-3 w-full outline-violet-50'
												placeholder='Search Officers'
												value={state.filterString}
												onChange={event => {
													setState({ ...state, filterString: event.target.value });
												}}
												onKeyUp={e => {
													if (e.key === 'Enter' || e.keyCode === 13) {
														const { filterString } = state;
														dispatch(searchOfficerAction(filterString));
													}
												}}
											/>
										</div>
										<div className='w-2/12 sm:w-full pl-5 sm:pl-0'>
											<div
												className='float-right flex items-center pr-0 sm:pr-5 border border-solid border-blue-950 bg-blue-950 text-white rounded-full sm:rounded-lg cursor-pointer'
												onClick={() => navigate(Routes.AdminAddOfficers)}
											>
												<PlusIcon className='h-6 w-6 m-3 text-white' />
												{!mql.matches && <Typography>Add Officer</Typography>}
											</div>
										</div>
									</div>
								</div>
								<OfficersListing officers={state.officers} currentUser='admin' />
								<TablePagination
									component='div'
									count={state.count}
									page={state.page}
									onPageChange={handleChangePage}
									rowsPerPage={state.rowsPerPage}
									rowsPerPageOptions={[10]}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
				{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
				{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
			</div>
		</>
	);
};

export default AdminOfficers;
