import {
	AppBar,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Fade,
	Grid,
	Icon,
	MenuItem,
	Modal,
	Paper,
	Select,
	SwipeableDrawer,
	TextField,
	Tooltip,
	Typography,
	makeStyles,
	withStyles,
} from '@material-ui/core';
import { Info, MailOutlined, MenuOutlined, PhoneOutlined } from '@material-ui/icons';
import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { baseUrl } from '../config';
import { FAQs } from '../data/FAQs';
import { Routes } from '../routes';
import { HttpError, HttpSuccess } from './snackbars';
import PriceList from './static/Pricing';
import { TermsAndConditions } from './static/TermsAndC';
// import { addComma } from '../utils/stringConverter'
import { FormControl } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../actions/AccountsActions';
import { dashGetCalculatinVariablesAction } from '../actions/DashAction';
import FacebookIcon from '../assets/images/facebook.svg';
import InstagramIcon from '../assets/images/instagram.svg';
import LinkedInIcon from '../assets/images/linkedin.svg';
import TwitterIcon from '../assets/images/twitter.svg';
import Adidas from '../assets/logos/adidas.png';
import Amazon from '../assets/logos/amazon.png';
import Bestbuy from '../assets/logos/bestbuy.png';
import Costco from '../assets/logos/costco.png';
import Ebay from '../assets/logos/ebay.png';
import HM from '../assets/logos/h&m.png';
import Ikea from '../assets/logos/Ikea.png';
import Lowes from '../assets/logos/lowes.png';
import Macys from '../assets/logos/macys.png';
import Target from '../assets/logos/target.png';
import Tesco from '../assets/logos/tesco.png';
import Tesla from '../assets/logos/tesla.png';
import THD from '../assets/logos/thd.jpg';
import Walmart from '../assets/logos/walmart.png';
import store, { useAppSelector } from '../store/store';
import { addComma } from '../utils/stringConverter';
import './Index.scss';
// const iOS = typeof window === 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
// function iOS() {
// 	return [
// 	  'iPad Simulator',
// 	  'iPhone Simulator',
// 	  'iPod Simulator',
// 	  'iPad',
// 	  'iPhone',
// 	  'iPod'
// 	].includes(navigator.platform)
// 	// iPad on iOS 13 detection
// 	|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
//   }
const mql = window.matchMedia(`(max-width: 1024px)`);
const useStyles = {
	imageIcon: { height: '36px' },
	iconRoot: { textAlign: 'center' },
	drawer: {
		backgroundColor: '#303E52',
		visibility: mql.matches ? 'visible' : 'hidden',
		width: '240px',
	},
};

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#efeffb',
		color: 'rgb(100,100,100)',
		boxShadow: theme.shadows[1],
		fontSize: 10,
		fontWeight: '400',
	},
}))(Tooltip);
const LightCalcTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#efeffb',
		color: 'rgb(100,100,100)',
		boxShadow: theme.shadows[1],
		fontSize: 16,
		fontWeight: '400',
	},
}))(Tooltip);

const IndexComponent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const calculator = useRef(null);
	const calculator2 = useRef(null);

	const [state, setState] = useState({
		gbpToKes: 0,
		contactUsDialogOpen: false,
		calcErrors: '',
		name: '',
		email: '',
		phoneNumber: '',
		subject: '',
		message: '',
		httpSuccess: null,
		httpError: null,
		pricingDialogOpen: false,
		termsDialogOpen: false,
		faqsDialogOpen: false,
		privacyOpen: false,
		protectionAnchorEl: null,
		drawerOpen: false,
		showModal: false,
	});

	const [weight, setWeight] = useState(0);
	const [length, setLength] = useState(0);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [totalCost, setTotalCost] = useState(0);
	const [selectedGreaterWeight, setSelectedGreaterWeight] = useState('');
	const [volumetricWeight, setVolumetricWeight] = useState(0);
	const [usaLessWeight, setUsaLessWeight] = useState(0);
	const [usaLargeWeight, setUsaLargeWeight] = useState(0);
	const [usdToKes, setUsdToKes] = useState(0);
	const [ukLessWeight, setUkLessWeight] = useState(0);
	const [ukLargeWeight, setUkLargeWeight] = useState(0);
	const [ukExchangeRate, setUkExchangeRate] = useState(0);
	const [chinaLargeWeight, setChinaLargeWeight] = useState(0);
	const [turkeyLargeWeight, setTurkeyLargeWeight] = useState(0);
	const [dubaiLargeWeight, setDubaiLargeWeight] = useState(0);
	const [chinaLessWeight, setChinaLessWeight] = useState(0);
	const [turkeyLessWeight, setTurkeyLessWeight] = useState(0);
	const [dubaiLessWeight, setDubaiLessWeight] = useState(0);
	const [selectedOrigin, setSelectedOrigin] = useState('USA');
	const [selectedWeight, setSelectedWeight] = useState('Kgs');
	const [freightType, setFreightType] = useState('AIR');
	const [chinaSeaFreight, setChinaSeaFreight] = useState(0);
	const [chinaSeaFreightGarment, setChinaSeaFreightGarment] = useState(0);
	const [turkeySeaFreight, setTurkeySeaFreight] = useState(0);
	const [dubaiSeaFreight, setDubaiSeaFreight] = useState(0);
	const [garment, setGarment] = useState('NONE_GARMENTS');
	const [volumeUnit, setVolumeUnit] = useState('Centimetres');

	const selectAuth = createSelector(
		[state => state.auth.Officer, state => state.auth.isClient, state => state.dash.calculationVariables],
		(isOfficer, isClient, calculationVariables) => {
			return {
				isOfficer,
				isClient,
				calculationVariables,
			};
		}
	);

	const { isOfficer, isClient, calculationVariables } = useAppSelector(selectAuth);

	useEffect(() => {
		const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/api';
		Axios.get(`${baseUrl}/system-variables/exchange-rate`)
			.then(snaps => {
				// gbpToKes: snaps.data.GBP ? snaps.data.GBP : 0,
				setUsdToKes(snaps.data.exchangeRate);
			})
			.catch(() => {
				setState({ ...state, calcErrors: 'An error occured while getting exchange rates.' });
			});
		dispatch(dashGetCalculatinVariablesAction());
	}, []);

	useEffect(() => {
		if (calculationVariables) {
			setUsaLessWeight(calculationVariables.usaLessWeight);
			setUsaLargeWeight(calculationVariables.usaLargeWeight);
			setUkLessWeight(calculationVariables.ukLessWeight);
			setUkLargeWeight(calculationVariables.ukLargeWeight);
			setUkExchangeRate(calculationVariables.ukExchangeRate);
			setChinaLargeWeight(calculationVariables.chinaLargeWeight);
			setChinaLessWeight(calculationVariables.chinaLessWeight);
			setTurkeyLargeWeight(calculationVariables.turkeyLargeWeight);
			setTurkeyLessWeight(calculationVariables.turkeyLessWeight);
			setDubaiLargeWeight(calculationVariables.dubaiLargeWeight);
			setDubaiLessWeight(calculationVariables.dubaiLessWeight);
			setChinaSeaFreight(calculationVariables.chinaSeaFreight);
			setChinaSeaFreightGarment(calculationVariables.chinaSeaFreightGarment);
			setTurkeySeaFreight(calculationVariables.turkeySeaFreight);
			setDubaiSeaFreight(calculationVariables.dubaiSeaFreight);
		}
	}, [calculationVariables]);

	const onGetStarted = () => {
		if (isOfficer) {
			navigate(Routes.OfficerDash);
		} else if (isClient) {
			navigate(Routes.Dashboard);
		} else {
			navigate(Routes.Signup);
		}
	};

	const handleClose = () => {
		setState({ ...state, showModal: false });
	};

	const onToCalculator = () => {
		calculator.current.scrollIntoView();
	};

	const onToCalculator2 = () => {
		calculator2.current.scrollIntoView();
	};

	// const onResetCalculator = () => {
	// 	setState({
	// 		weight: '',
	// 		length: '',
	// 		width: '',
	// 		height: '',
	// 		selectedWeight: 'Kgs',
	// 		selectedOrigin: 'USA',
	// 		volumetricWeight: 0,
	// 		totalCost: 0,
	// 		calcErrors: '',
	// 		selectedGreaterWeight: '',
	// 	});
	// };

	const autoReset = () => {
		setState({
			calcErrors: '',
		});
		setWeight(0);
		setLength(0);
		setWidth(0);
		setHeight(0);
		setVolumetricWeight(0);
		setTotalCost(0);
		setSelectedGreaterWeight('');
	};

	// onCalculateCost = () => {
	//   setState({ calcErrors: '', selectedGreaterWeight: '' })
	//   const { weight, length, width, height } = state
	//   // if only weight available
	//   if (weight !== '' && length === '' && width === '' && height === '') {
	//     return setState({
	//       totalCost: weightToPrice(weight),
	//       selectedGreaterWeight: 'Using actual weight'
	//     });
	//   }
	//   else {
	//     if (length === '' || width === '' || height === '') {
	//       return setState({
	//         totalCost: weightToPrice(weight), selectedGreaterWeight: 'Using actual weight'
	//       })
	//     } else {
	//       const volumetricWeight = (parseFloat(length) * parseFloat(width) * parseFloat(height)) / 6000
	//       if (volumetricWeight > parseFloat(weight)) {
	//         return setState({
	//           totalCost: weightToPrice(volumetricWeight),
	//           selectedGreaterWeight: 'Using volumetric weight', volumetricWeight
	//         })
	//       } else {
	//         return setState({
	//           totalCost: weightToPrice(weight),
	//           selectedGreaterWeight: 'Using actual weight', volumetricWeight
	//         })
	//       }
	//     }
	//   }
	// }

	const weightToPrice = weight => {
		let returnValue;

		switch (selectedOrigin) {
			case 'USA':
				if (weight > 0 && weight <= 2) {
					returnValue = parseFloat(usaLessWeight) * usdToKes;
				} else {
					returnValue = parseFloat(usaLargeWeight) * weight * usdToKes;
				}
				break;
			case 'CHINA':
				if (freightType === 'AIR') {
					if (weight > 0 && weight <= 2) {
						returnValue = parseFloat(chinaLessWeight) * usdToKes;
					} else {
						returnValue = parseFloat(chinaLargeWeight) * weight * usdToKes;
					}
				} else {
					if (garment === 'NONE_GARMENTS') {
						returnValue = parseFloat(chinaSeaFreight) * weight;
					} else {
						returnValue = parseFloat(chinaSeaFreightGarment) * weight;
					}
				}
				break;
			case 'TURKEY':
				if (freightType === 'AIR') {
					if (weight > 0 && weight <= 2) {
						returnValue = parseFloat(turkeyLessWeight) * usdToKes;
					} else {
						returnValue = parseFloat(turkeyLargeWeight) * weight * usdToKes;
					}
				} else {
					returnValue = parseFloat(turkeySeaFreight) * weight * usdToKes;
				}
				break;
			case 'DUBAI':
				if (freightType === 'AIR') {
					if (weight > 0 && weight <= 2) {
						returnValue = parseFloat(dubaiLessWeight) * usdToKes;
					} else {
						returnValue = parseFloat(dubaiLargeWeight) * weight * usdToKes;
					}
				} else {
					returnValue = parseFloat(dubaiSeaFreight) * weight;
				}
				break;
			default:
				returnValue = parseFloat(ukLargeWeight) * weight * parseFloat(ukExchangeRate);
				break;
		}

		return returnValue;
	};

	const handleContactInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	// const handleInputChange = event => {
	// 	setState({
	// 		[event.target.name]: event.target.value,
	// 	});
	// };

	const onContactUs = event => {
		event.preventDefault();
		const { name, email, phoneNumber, subject, message } = state;
		Axios.post(`${baseUrl}/contactUs`, {
			name,
			email,
			phoneNumber,
			subject,
			message,
		})
			.then(() => {
				setState({ ...state, httpSuccess: 'Message sent.' });
			})
			.catch(() => {
				setState({ ...state, httpError: 'An error occured. Please retry.' });
			});
		setState({
			...state,
			name: '',
			email: '',
			phoneNumber: '',
			subject: '',
			message: '',
			contactUsDialogOpen: false,
		});
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	return (
		<div>
			{/* Sidebar */}
			<SwipeableDrawer
				PaperProps={{ classes: { root: classes ? classes.drawer : {} } }}
				open={state.drawerOpen}
				variant={mql.matches ? 'temporary' : 'permanent'}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				disableBackdropTransition={true}
				disableDiscovery={false}
			>
				<Grid spacing={0}>
					<Grid item xs={12} className='home_toggle_icon'>
						<Grid>
							<Button onClick={toggleDrawer(false)}>
								<MenuOutlined htmlColor='#ffffff' style={{ display: mql.matches ? 'block' : 'none' }} />
							</Button>
						</Grid>
						<Grid>
							<Paper className='clean_paper home_app_bar_logo'></Paper>
						</Grid>
					</Grid>
					<br />
					<Grid item xs={8}>
						<Paper className='clean_paper swipeable_sect'>
							<Button
								style={{ textTransform: 'none', color: '#ffffff' }}
								onClick={() => setState({ ...state, contactUsDialogOpen: true })}
							>
								Contact Us
							</Button>
							;
							<Button
								style={{ textTransform: 'none', color: '#ffffff' }}
								onClick={() => {
									onToCalculator2();
									setState({ ...state, drawerOpen: false });
								}}
							>
								Cost Calculator
							</Button>
							<Button
								onClick={() => setState({ ...state, faqsDialogOpen: true })}
								style={{ textTransform: 'none', color: 'white' }}
							>
								FAQs
							</Button>
							<Button
								onClick={() => setState({ ...state, privacyOpen: true })}
								style={{ textTransform: 'none', color: 'white' }}
							>
								Privacy Policy
							</Button>
						</Paper>
					</Grid>
				</Grid>
			</SwipeableDrawer>
			{/* App bar
			 ***********
			 ***********
			 *********/}
			<AppBar
				position='fixed'
				className='home_appbars'
				style={{
					background: 'whitesmoke',
					color: '#232C39',
				}}
			>
				<Grid container spacing={0} justify='start' alignContent='center' alignItems='center'>
					<Grid item xs={6} className='home_toggle_icon'>
						<Grid>
							<Button onClick={toggleDrawer(true)} className='rt_drawer_menu_home'>
								<MenuOutlined htmlColor='#303E52' style={{ display: mql.matches ? 'block' : 'none' }} />
							</Button>
						</Grid>
						<Grid>
							<Paper className='clean_paper home_app_bar_logo'></Paper>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Paper className='clean_paper' style={{ textAlign: 'right', paddingRight: '8%' }}>
							<span style={mql.matches ? { display: 'none' } : {}}>
								<Button
									style={{ textTransform: 'none', color: '#232C39' }}
									onClick={() => setState({ ...state, contactUsDialogOpen: true })}
								>
									Contact Us
								</Button>
								&nbsp;&nbsp;
								<Button
									style={{ textTransform: 'none', color: '#232C39' }}
									onClick={() => {
										onToCalculator();
									}}
								>
									Cost Calculator
								</Button>
								&nbsp;&nbsp;
								<Button
									onClick={() => setState({ ...state, faqsDialogOpen: true })}
									style={{ textTransform: 'none', color: '#232C39' }}
								>
									FAQs
								</Button>
								&nbsp;&nbsp;
								<Button
									onClick={() => setState({ ...state, privacyOpen: true })}
									style={{ textTransform: 'none', color: '#232C39' }}
								>
									Privacy Policy
								</Button>
								&nbsp;&nbsp;&nbsp;&nbsp;
							</span>
							{localStorage.getItem('token') ? (
								<Button
									onClick={() => {
										store.dispatch(logout('', 2));
										window.location.href = '/accounts/signin';
									}}
									style={{ textTransform: 'none', background: '#232C39', color: 'white' }}
								>
									Logout
								</Button>
							) : (
								<Button
									onClick={() => (window.location.href = '/accounts/signin')}
									style={{ textTransform: 'none', background: '#232C39', color: 'white' }}
								>
									Login
								</Button>
							)}
						</Paper>
					</Grid>
				</Grid>
			</AppBar>
			{/* Main card
			 *************
			 *************
			 ***********/}
			<Grid container spacing={0} alignContent='center' alignItems='center' className='index_grid_main'>
				<Grid
					item
					xs={12}
					className='main_paper'
					// style={{ margin: mql.matches ? '0%' : '0%', marginTop: '20vh' }}
				>
					<Grid container spacing={0}>
						<Modal
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								border: 'none',
							}}
							onClose={handleClose}
							open={state.showModal}
						>
							<Fade in={state.showModal}>
								<div
									style={{
										padding: 24,
										minHeight: 150,
										border: 'none',
										borderRadius: 8,
										background: '#fff',
										minWidth: 300,
										maxWidth: 500,
										textAlign: 'center',
										outline: 'none',
									}}
								>
									<div>
										<Info fontSize={'large'} color={'crimson'} />
										<h4>Dear esteemed customer!</h4>
										<p>
											Due to KRA increased customs clearance charges for the importers of
											consolidated cargo, we would like to advise you that there will be an
											increase in our shipping rate to be put into effect immediately.
										</p>
										<p>The new shipping rates are as below:</p>
										<p style={{ textAlign: 'left' }}>
											From the US: <b>2kgs and below at $30; Above 2kgs, @$16.5 per kg.</b>
											<br />
											From the UK: <b>14.5 pounds per kg.</b>
										</p>
										<p>
											However, if an item is hazardous, we charge an extra fee of $15 from the US
											and £20 from the UK. Hazardous are electronic items, Lithium-ion batteries,
											alcohol-based perfumes, and makeup that are categorized as dangerous goods,
											or anything considered hazardous according to IATA.
											<br />
											For any further clarification or assistance, please call us at;{' '}
											<a
												style={{ color: '#232C39', textDecoration: 'none' }}
												href='tel:0780363433'
											>
												0780363433
											</a>
											/
											<a
												style={{ color: '#232C39', textDecoration: 'none' }}
												href='tel:0780363433'
											>
												07171363433
											</a>
										</p>
										<Button
											style={{
												background: '#f3742a',
											}}
											onClick={handleClose}
										>
											Ok
										</Button>
									</div>
								</div>
							</Fade>
						</Modal>
						{/* <Grid item xs={12}>
							<Typography variant='h2' style={{ paddingBottom: '24%', color: 'rgb(89,89,89)' }}>
								Ship and track your package directly from North America, Europe, China, Turkey and Dubai
								to your doorstep in East Africa.
							</Typography>
						</Grid> */}
						<Grid
							item
							xs={12}
							style={{
								color: 'white',
								marginTop: '56px',
							}}
							className='flex justify-end absolute mt-24px top-96 md:top-0 w-full'
						>
							<div className='md:w-1/2'>
								<div className='flex justify-end'>
									<div
										style={{
											background: '#F57E29',
											borderRadius: mql.matches ? '14px' : '0 0 0 14px',
										}}
										className='flex justify-around items-center h-32 md:h-64 md:w-5/6'
									>
										<div className='md:w-11/12'>
											<p className='text-xl md:text-3xl font-semibold text-center tracking-widest'>
												Now you can buy from top stores in USA, UK, China, Turkey and Dubai. We
												will deliver to your home or business.
											</p>
										</div>
									</div>
								</div>
								<div className='flex justify-between mt-10'>
									<img src={Costco} width={mql.matches ? 60 : 100} />
									<img src={Ebay} width={mql.matches ? 60 : 100} />
									<img src={Amazon} width={mql.matches ? 60 : 100} />
									<img src={Ikea} width={mql.matches ? 60 : 100} />
									<img src={Bestbuy} width={mql.matches ? 60 : 100} />
								</div>
								<div className='mt-14 flex justify-between'>
									<img src={HM} width={mql.matches ? 60 : 100} />
									<img src={Adidas} width={mql.matches ? 60 : 100} height={100} />
									<img src={Tesla} width={mql.matches ? 60 : 100} height={100} />
									<img src={Lowes} width={mql.matches ? 60 : 100} />
									<img src={THD} width={mql.matches ? 60 : 100} height={100} />
								</div>
								<div className='mt-14 flex justify-between'>
									<img src={Macys} width={mql.matches ? 60 : 100} height={100} />
									<img src={Tesco} width={mql.matches ? 60 : 100} height={100} />
									<img src={Target} width={mql.matches ? 60 : 100} />
									<img src={Walmart} width={mql.matches ? 60 : 100} />
								</div>
								{/* <div className='mt-20'>
									<p className='text-2xl md:text-4xl text-center text-gray-500'>
										Shipping has never been this easy and convenient.
									</p>
								</div> */}
							</div>
						</Grid>
						<Grid
							item
							xs={12}
							style={{ marginTop: '34vh' }}
							className='md:w-1/2 absolute top-0 right-0'
						></Grid>
						<Grid
							item
							style={{ background: 'rgba(255, 255, 255, .6)' }}
							className='py-3 md:py-5 px-2 md:px-20 rounded-lg w-full md:w-1/3 absolute top-40 md:top-96'
						>
							<Grid item xs={12}>
								<Button
									onClick={onGetStarted}
									style={{
										background: '#F57E29',
										color: 'white',
										textTransform: 'none',
									}}
									className='pl-10 md:pl-0'
								>
									{localStorage.getItem('token') ? 'Go to Dashboard' : 'Get Started'}
								</Button>
								<Button
									onClick={() => onToCalculator()}
									style={{
										color: '#F57E29',
										textTransform: 'none',
										marginLeft: '10px',
										textDecoration: 'underline',
									}}
								>
									Calculate Your Costs
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				xs={12}
				alignContent='center'
				alignItems='center'
				justify='center'
				style={{ display: 'flex', justifyContent: 'space-around' }}
			>
				<Grid xs={12} class='slider flex-wrap md:flex-nowrap mb-64 md:mb-5 lg:mt-44 xl:mt-40 2xl:mt-0'>
					<Grid xs={mql.matches ? 12 : 4} className='vprop'>
						Get your packages in 3 easy steps!
					</Grid>
					<Grid className='vsects'>
						<div className='sect1'>
							<h1 className='step'>1</h1>
							<span>
								Sign up on
								<br /> our platform
							</span>
							<div className='sect-border'></div>
						</div>
						<div className='sect1'>
							<h1 className='step'>2</h1>
							<span>
								Ship to our
								<br /> US/EU address
							</span>
							<div className='sect-border'></div>
						</div>
						<div className='sect1'>
							<h1 className='step'>3</h1>
							<span>
								Get in it
								<br /> at your door step
							</span>
						</div>
					</Grid>
				</Grid>
			</Grid>
			{/* Shipping features
			 *********************
			 *********************
			 *********************
			 *******************/}
			<Grid container spacing={0} justify='center' alignContent='center' alignItems='center'>
				<Grid item xs={mql.matches ? 6 : 4}>
					<LightTooltip
						title={
							<React.Fragment>
								<Typography>
									Shipping protection is a service whereby we reimburse you in case your parcel is:
									lost, stolen, and/or damaged in transit.
								</Typography>
								<Typography>
									The cost of purchasing shipment is fixed at 10% of the value of the package being
									shipped. This amount is non-refundable.
								</Typography>
								<Typography>
									<b>How it works: </b> When your items arrive at our US/UK warehouse, you’ll receive
									an email offering purchase protection, you will have 48 hours to communicate your
									interest.
								</Typography>
								<Typography>
									Shippingprotection allows you to file a claim and receive full compensation. Should
									you choose not to have shipping protection, Vitumob will not accept any liability
									for loss or damage to your items.
								</Typography>
							</React.Fragment>
						}
					>
						<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
							<Button className='index_icns_ftrs sf_protect'>&nbsp;</Button>
							<Typography variant='h6'>Shipping protection</Typography>
							<Typography style={{ color: 'rgb(100,100,100)' }}>
								Optional cover for your goods against any eventualities that may happen during the
								shipping process
							</Typography>
						</Paper>
					</LightTooltip>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button onClick={() => navigate(Routes.Pricing)} className='index_icns_ftrs sf_delivery'>
							&nbsp;
						</Button>
						<Typography variant='h6'>Doorstep delivery</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							Request delivery to any of the listed&nbsp;
							<Button onClick={() => setState({ ...state, pricingDialogOpen: true })} color='primary'>
								Regions
							</Button>
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button className='index_icns_ftrs sf_shipping'>&nbsp;</Button>
						<Typography variant='h6'>Fast shipping</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							We ship once every week and our systems ensure you receive your products as fast as possible
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button onClick={() => navigate(Routes.Dashboard)} className='index_icns_ftrs sf_notf'>
							&nbsp;
						</Button>
						<Typography variant='h6'>Activity notifications</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							Receive instant notifications for each step or change in status of orders and items
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button onClick={() => navigate(Routes.RegisteredItems)} className='index_icns_ftrs sf_track'>
							&nbsp;
						</Button>
						<Typography variant='h6'>Item tracking</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							Get to track your items from when you order them to when they arrive at your doorstep.
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button onClick={() => navigate(Routes.Dashboard)} className='index_icns_ftrs sf_history'>
							&nbsp;
						</Button>
						<Typography variant='h6'>Item history</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							All items activity is recorded on the client's dashboard for reference.
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button onClick={() => onToCalculator()} className='index_icns_ftrs sf_cc'>
							&nbsp;
						</Button>
						<Typography variant='h6'>Cost calculator</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							Get to know exactly where the cost of your orders and items lie.
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={mql.matches ? 6 : 4}>
					<Paper className='index_shadow_paper' style={{ margin: '4%', textAlign: 'center' }}>
						<Button onClick={() => navigate(Routes.EditProfile)} className='index_icns_ftrs sf_perp'>
							&nbsp;
						</Button>
						<Typography variant='h6'>Personalized profiles</Typography>
						<Typography style={{ color: 'rgb(100,100,100)' }}>
							Custom profiles and customer ID for a more efficient tracking and overall personalized
							expericence.
						</Typography>
					</Paper>
				</Grid>
			</Grid>
			{/* Cost Calculator Section
			 *********************
			 *********************
			 *********************
			 *******************/}
			<Grid
				container
				spacing={0}
				justify='center'
				alignContent='center'
				alignItems='center'
				style={{ background: 'rgb(35,44,57)' }}
				className='index_grid_calc'
				ref={calculator}
			>
				<Grid item xs={mql.matches ? 12 : 4} className='calc_paper' style={{ margin: '2%' }}>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<Typography variant='h4' style={{ paddingBottom: '4%', color: 'rgb(245,245,245)' }}>
								Use our calculator to estimate your shipping before you order.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='h6' style={{ paddingRight: '4%', marginBottom: '15px' }}>
								Shipping has never been this easy and convenient.
							</Typography>
							<Button
								onClick={onGetStarted}
								style={{ background: 'rgb(245,245,245)', color: '#232C39', textTransform: 'none' }}
							>
								{localStorage.getItem('token') ? 'Go to Dashboard' : 'Get Your Package'}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				{/* Shipping cost calculator
				 ****************************
				 ****************************
				 **************************/}
				<Grid item xs={mql.matches ? 12 : 7} style={{ marginRight: '5px' }} ref={calculator2}>
					<Grid
						container
						spacing={0}
						style={{ background: 'white', boxShadow: '0px 0px 2px 2px rgb(239,239,251)' }}
					>
						<Grid item xs={12}>
							<Paper
								style={{
									margin: '2%',
									padding: '2%',
									boxShadow: '0px 8px 8px 0 #efeffb',
								}}
							>
								<LightCalcTooltip
									title={`Chargeable weight in Kgs is equal to volume
                    in cubic centimeters divided by 6000.`}
								>
									<Typography style={{ paddingBottom: '3%', color: 'rgb(100,100,100)' }}>
										Shipping weight is the greater of actual weight and chargeable (volumetric)
										weight.
									</Typography>
								</LightCalcTooltip>
								<Grid container spacing={0}>
									<Grid item xs={mql.matches ? 12 : 7}>
										<Grid
											container
											spacing={0}
											justify='center'
											alignContent='center'
											alignItems='center'
										>
											<Grid item xs={12}>
												<Typography style={{ paddingBottom: '2%', fontWeight: 'bold' }}>
													Use the calculator below to estimate your shipping.
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography style={{ paddingBottom: '4%' }}>
													<b>
														Select shipping origin & freight category below. (Actual weight
														in Kgs)
													</b>
												</Typography>
											</Grid>
											<Grid item xs={selectedOrigin === 'CHINA' ? 3 : 4}>
												<FormControl style={{ width: '92%' }}>
													<Select
														labelId='origin_select_label'
														value={selectedOrigin}
														variant='outlined'
														margin='dense'
														onChange={event => {
															setSelectedOrigin(event.target.value);
															if (
																event.target.value === 'USA' ||
																event.target.value === 'UK'
															) {
																setVolumeUnit('Centimetres');
																setFreightType('AIR');
															}
															autoReset();
														}}
													>
														<MenuItem value='USA'>USA</MenuItem>
														<MenuItem value='UK'>UK</MenuItem>
														<MenuItem value='CHINA'>China</MenuItem>
														<MenuItem value='TURKEY'>Turkey</MenuItem>
														<MenuItem value='DUBAI'>Dubai</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={selectedOrigin === 'CHINA' ? 3 : 4}>
												<FormControl style={{ width: '92%' }}>
													<Select
														labelId='freight_select_label'
														value={freightType}
														variant='outlined'
														margin='dense'
														onChange={event => {
															if (
																(selectedOrigin === 'USA' || selectedOrigin === 'UK') &&
																event.target.value === 'SEA'
															) {
																return setState({
																	...state,
																	calcErrors: `Sea freight not available for ${selectedOrigin}`,
																});
															}
															setFreightType(event.target.value);
															if (event.target.value === 'AIR') {
																return setVolumeUnit('Centimetres');
															}
															setVolumeUnit('Metres');
															autoReset();
														}}
													>
														<MenuItem value='AIR'>Air</MenuItem>
														<MenuItem value='SEA'>Sea</MenuItem>
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={selectedOrigin === 'CHINA' ? 3 : 4}>
												<FormControl style={{ width: '92%' }}>
													<Select
														labelId='weight_select_label'
														value={selectedWeight}
														variant='outlined'
														margin='dense'
														onChange={event => {
															setSelectedWeight(event.target.value);
															autoReset();
														}}
													>
														<MenuItem value='Kgs'>Kgs</MenuItem>
														{/* <MenuItem value="Lbs">Pounds (Lbs)</MenuItem> */}
													</Select>
												</FormControl>
											</Grid>
											{selectedOrigin === 'CHINA' && (
												<Grid item xs={3}>
													<FormControl style={{ width: '92%' }}>
														<Select
															labelId='weight_select_label'
															value={garment}
															variant='outlined'
															margin='dense'
															onChange={event => {
																setGarment(event.target.value);
																autoReset();
															}}
														>
															<MenuItem value='NONE_GARMENTS'>None Garments</MenuItem>
															<MenuItem value='GARMENTS'>Garments</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											)}
											{volumeUnit === 'Centimetres' && (
												<Grid item xs={12} style={{ paddingTop: '4%', paddingBottom: '2%' }}>
													<Typography>Weight</Typography>
												</Grid>
											)}
											{volumeUnit === 'Centimetres' && (
												<Grid item xs={12}>
													<TextField
														name='weight'
														value={weight === 0 ? '' : weight}
														onChange={e => {
															setWeight(Number(e.target.value));
															// execute if all fields are there
															if (
																Number(e.target.value) > 0 &&
																length > 0 &&
																width > 0 &&
																height > 0
															) {
																const volumetricWeight =
																	(parseFloat(length) *
																		parseFloat(width) *
																		parseFloat(height)) /
																	6000;
																if (volumetricWeight > parseFloat(e.target.value)) {
																	setTotalCost(weightToPrice(volumetricWeight));
																	setSelectedGreaterWeight('Using volumetric weight');
																	setVolumetricWeight(volumetricWeight);
																} else {
																	setTotalCost(weightToPrice(Number(e.target.value)));
																	setSelectedGreaterWeight('Using actual weight');
																	setVolumetricWeight(volumetricWeight);
																}
															}
															// execute if only weight selected
															if (
																Number(e.target.value) > 0 &&
																length === 0 &&
																width === 0 &&
																height === 0
															) {
																setTotalCost(weightToPrice(Number(e.target.value)));
																setSelectedGreaterWeight('Using actual weight');
															}
															// execute if only volumetric selected
															if (
																Number(e.target.value) > 0 &&
																(length > 0 || width > 0 || height > 0)
															) {
																setTotalCost(weightToPrice(Number(e.target.value)));
																return setSelectedGreaterWeight('Using actual weight');
															}

															// execute if only volumetric selected
															if (
																Number(e.target.value) === 0 &&
																length > 0 &&
																width > 0 &&
																height > 0
															) {
																const volumetricWeight =
																	(parseFloat(length) *
																		parseFloat(width) *
																		parseFloat(height)) /
																	6000;

																setTotalCost(weightToPrice(volumetricWeight));
																setSelectedGreaterWeight('Using volumetric weight');
																return setVolumetricWeight(volumetricWeight);
															}
															// if no weight and any other field
															if (
																Number(e.target.value) === 0 &&
																(length > 0 || width > 0 || height > 0)
															) {
																setTotalCost(0);
																setSelectedGreaterWeight('');
																return setVolumetricWeight(0);
															}
															// if all are empty, reset
															if (
																Number(e.target.value) === 0 &&
																length === 0 &&
																width === 0 &&
																height === 0
															) {
																autoReset();
															}
														}}
														type='number'
														label={`Weight in ${selectedWeight}`}
														variant='outlined'
														style={{ width: '98%' }}
													/>
												</Grid>
											)}
											<Grid item xs={12} style={{ paddingTop: '4%' }}>
												<Typography>
													Dimensions in <b>{volumeUnit}</b>
												</Typography>
											</Grid>
											<Grid item xs={4}>
												<TextField
													name='length'
													value={length === 0 ? '' : length}
													onChange={e => {
														setLength(Number(e.target.value));
														// execute if all fields are there
														if (
															weight > 0 &&
															Number(e.target.value) > 0 &&
															width > 0 &&
															height > 0
														) {
															const volumetricWeight =
																(parseFloat(e.target.value) *
																	parseFloat(width) *
																	parseFloat(height)) /
																6000;

															if (volumetricWeight > parseFloat(weight)) {
																setTotalCost(weightToPrice(volumetricWeight));
																setSelectedGreaterWeight('Using volumetric weight');
																return setVolumetricWeight(volumetricWeight);
															}
															setTotalCost(weightToPrice(weight));
															setSelectedGreaterWeight('Using actual weight');
															return setVolumetricWeight(volumetricWeight);
														}
														// execute if all dimensions and no weight
														if (
															weight === 0 &&
															Number(e.target.value) > 0 &&
															width > 0 &&
															height > 0
														) {
															const volumetricWeight =
																(parseFloat(e.target.value) *
																	parseFloat(width) *
																	parseFloat(height)) /
																	volumeUnit ===
																'Centimetres'
																	? 6000
																	: 1;

															setTotalCost(weightToPrice(volumetricWeight));
															setSelectedGreaterWeight(
																`Using volumetric ${volumeUnit === 'Centimetres' ? 'weight' : 'size'}`
															);
															return setVolumetricWeight(volumetricWeight);
														}
														// if all are zero reset
														if (
															weight === 0 &&
															Number(e.target.value) === 0 &&
															width === 0 &&
															height === 0
														) {
															autoReset();
														}
														// responsive dimensions
														if (
															weight === 0 &&
															Number(e.target.value) === 0 &&
															width > 0 &&
															height > 0
														) {
															setTotalCost(0);
															setSelectedGreaterWeight('');
															setVolumetricWeight(0);
														}
													}}
													type='number'
													style={{ width: '92%' }}
													margin='dense'
													variant='outlined'
													label='Length'
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													name='width'
													value={width === 0 ? '' : width}
													onChange={e => {
														setWidth(Number(e.target.value));
														// execute if all fields are there
														if (
															weight > 0 &&
															length > 0 &&
															Number(e.target.value) > 0 &&
															height > 0
														) {
															const volumetricWeight =
																(parseFloat(length) *
																	parseFloat(e.target.value) *
																	parseFloat(height)) /
																6000;
															if (volumetricWeight > parseFloat(weight)) {
																setTotalCost(weightToPrice(volumetricWeight));
																setSelectedGreaterWeight('Using volumetric weight');
																return setVolumetricWeight(volumetricWeight);
															}
															setTotalCost(weightToPrice(weight));
															setSelectedGreaterWeight('Using actual weight');
															return setVolumetricWeight(volumetricWeight);
														}
														// execute if all dimensions and no weight
														if (
															weight === 0 &&
															length > 0 &&
															Number(e.target.value) > 0 &&
															height > 0
														) {
															const volumetricWeight =
																(parseFloat(length) *
																	parseFloat(e.target.value) *
																	parseFloat(height)) /
																	volumeUnit ===
																'Centimetres'
																	? 6000
																	: 1;

															setTotalCost(weightToPrice(volumetricWeight));
															setSelectedGreaterWeight(
																`Using volumetric ${volumeUnit === 'Centimetres' ? 'weight' : 'size'}`
															);
															return setVolumetricWeight(volumetricWeight);
														}
														// if all are zero reset
														if (
															weight === 0 &&
															length === 0 &&
															Number(e.target.value) === 0 &&
															height === 0
														) {
															autoReset();
														}
														// responsive dimensions
														if (
															weight === 0 &&
															length > 0 &&
															Number(e.target.value) === 0 &&
															height > 0
														) {
															setTotalCost(0);
															setSelectedGreaterWeight('');
															return setVolumetricWeight(0);
														}
													}}
													type='number'
													style={{ width: '92%' }}
													margin='dense'
													variant='outlined'
													label='Width'
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													name='height'
													value={height === 0 ? '' : height}
													onChange={e => {
														setHeight(Number(e.target.value));
														// execute if all fields are there
														if (
															weight > 0 &&
															Number(e.target.value) > 0 &&
															width > 0 &&
															length > 0
														) {
															const volumetricWeight =
																(parseFloat(e.target.value) *
																	parseFloat(width) *
																	parseFloat(length)) /
																6000;
															if (volumetricWeight > parseFloat(weight)) {
																setTotalCost(weightToPrice(volumetricWeight));
																setSelectedGreaterWeight('Using volumetric weight');
																return setVolumetricWeight(volumetricWeight);
															}
															setTotalCost(weightToPrice(weight));
															setSelectedGreaterWeight('Using actual weight');
															return setVolumetricWeight(volumetricWeight);
														}
														// execute if all dimensions and no weight
														if (
															weight === 0 &&
															Number(e.target.value) > 0 &&
															width > 0 &&
															length > 0
														) {
															const volumetricWeight =
																(parseFloat(e.target.value) *
																	parseFloat(width) *
																	parseFloat(length)) /
																	volumeUnit ===
																'Centimetres'
																	? 6000
																	: 1;
															setTotalCost(weightToPrice(volumetricWeight));
															setSelectedGreaterWeight(
																`Using volumetric ${volumeUnit === 'Centimetres' ? 'weight' : 'size'}`
															);
															return setVolumetricWeight(volumetricWeight);
														}
														// if all are zero reset
														if (
															weight === 0 &&
															Number(e.target.value) === 0 &&
															width === 0 &&
															length === 0
														) {
															autoReset();
														}
														// responsive dimensions
														if (
															weight === 0 &&
															Number(e.target.value) === 0 &&
															width > 0 &&
															length > 0
														) {
															setTotalCost(0);
															setSelectedGreaterWeight('');
															return setVolumetricWeight(0);
														}
													}}
													type='number'
													style={{ width: '92%' }}
													margin='dense'
													variant='outlined'
													label='Height'
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography style={{ color: 'orangered' }}>
													{state.calcErrors}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 5}>
										{volumeUnit === 'Centimetres' && (
											<Paper className='index_shadow_paper' style={{ paddingBottom: '8%' }}>
												<Typography>
													<span style={{ color: 'rgb(149,149,149)' }}>
														Actual Weight:&nbsp;
													</span>
													{weight}&nbsp;{selectedWeight}
												</Typography>
											</Paper>
										)}
										<Paper className='index_shadow_paper' style={{ paddingBottom: '8%' }}>
											<Typography>
												<span style={{ color: 'rgb(149,149,149)' }}>
													Volumetric {volumeUnit === 'Centimetres' ? 'Weight' : 'Size'}:&nbsp;
												</span>
												{volumetricWeight}&nbsp;
												{volumeUnit === 'Centimetres' ? (
													selectedWeight
												) : (
													<span>
														m<sup>3</sup>
													</span>
												)}
											</Typography>
										</Paper>
										<Paper className='index_shadow_paper'>
											<Typography style={{ color: 'darkblue' }}>
												{selectedGreaterWeight}
											</Typography>
											<Typography>
												<span style={{ color: 'rgb(149,149,149)' }}>Total cost:&nbsp;</span>
												KES. {addComma(Number(totalCost))}
											</Typography>
										</Paper>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* Get started
			 ***************
			 ***************
			 *************/}
			<Grid
				container
				spacing={0}
				justify='center'
				alignContent='center'
				alignItems='center'
				style={{ background: 'rgb(235,245,245)' }}
			>
				<Grid item xs={8}>
					<Paper className='index_shadow_paper' style={{ margin: '8%' }}>
						<Typography variant='h4' style={{ paddingBottom: '1%' }}>
							Ready to get started?
						</Typography>
						<Typography variant='h6'>
							Get a guided walkthrough of the dashboard upon Login or Signup
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Button
						onClick={onGetStarted}
						style={{
							background: 'inherit',
							color: '#232C39',
							textTransform: 'none',
							border: '1px solid #232C39',
						}}
					>
						{localStorage.getItem('token') ? 'Go to Dashboard' : 'Get Started'}
					</Button>
				</Grid>
			</Grid>
			{/* Footer
			 **********
			 **********
			 ********/}
			<Grid
				container
				spacing={0}
				justify='center'
				alignContent='center'
				alignItems='center'
				className='index_footer'
			>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Typography className='align_icons' style={{ color: 'white', paddingRight: '24px' }}>
						<PhoneOutlined htmlColor='white' />
						&nbsp;US:&nbsp;
						<a style={{ color: 'white', textDecoration: 'none' }} href='tel:+1609-848-8662'>
							+1 609-848-8662
						</a>
					</Typography>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Paper className='footer_logo'></Paper>
					<Typography
						className='align_icons'
						style={{
							color: 'white',
							paddingRight: '24px',
							width: '100%',
							display: 'flex',
							justifyContent: 'space-around',
						}}
					>
						Contact us by:
					</Typography>
					<Typography className='align_icons' style={{ color: 'white', paddingRight: '24px' }}>
						<PhoneOutlined htmlColor='white' />
						&nbsp;US:&nbsp;
						<a style={{ color: 'white', textDecoration: 'none' }} href='tel:+1609-848-8662'>
							+1 609-848-8662
						</a>
					</Typography>
					<Typography className='align_icons' style={{ color: 'white', paddingRight: '24px' }}>
						<PhoneOutlined htmlColor='white' />
						&nbsp;UK:&nbsp;
						<a style={{ color: 'white', textDecoration: 'none' }} href='tel:02079932763'>
							0207 993 2763
						</a>
					</Typography>
					<Typography
						className='align_icons'
						style={
							mql.matches
								? { paddingLeft: '30px', color: 'white', paddingRight: '24px' }
								: { color: 'white', paddingRight: '24px' }
						}
					>
						<PhoneOutlined htmlColor='white' />
						&nbsp;Nairobi:&nbsp;
						<span className={`${mql.matches && 'align_numbers'}`}>
							<a style={{ color: 'white', textDecoration: 'none' }} href='tel:0717363433'>
								0717363433
							</a>
							&nbsp;/&nbsp;
							<a style={{ color: 'white', textDecoration: 'none' }} href='tel:0780363433'>
								0780363433
							</a>
							&nbsp;/&nbsp;
							<a style={{ color: 'white', textDecoration: 'none' }} href='tel:0742363433'>
								0742363433
							</a>
						</span>
					</Typography>
					<Typography className='align_icons' style={{ color: 'white', paddingRight: '24px' }}>
						<MailOutlined htmlColor='white' />
						&nbsp;
						<a style={{ color: 'white', textDecoration: 'none' }} href='mailto:support@vitumob.com'>
							support@vitumob.com
						</a>
					</Typography>
					<Typography style={{ paddingTop: '2%' }}>
						<a href='https://twitter.com/VituMob' target='_blank' rel='noopener noreferrer'>
							<Icon classes={{ root: classes.iconRoot }}>
								<img className={classes.imageIcon} src={TwitterIcon} alt='Twitter logo' />
							</Icon>
						</a>
						&nbsp;&nbsp;&nbsp;
						<a href='https://www.facebook.com/VituMob/' target='_blank' rel='noopener noreferrer'>
							<Icon classes={{ root: classes.iconRoot }}>
								<img className={classes.imageIcon} src={FacebookIcon} alt='Facebook logo' />
							</Icon>
						</a>
						&nbsp;&nbsp;&nbsp;
						<a href='https://www.instagram.com/vitumob/' target='_blank' rel='noopener noreferrer'>
							<Icon classes={{ root: classes.iconRoot }}>
								<img className={classes.imageIcon} src={InstagramIcon} alt='Instagram logo' />
							</Icon>
						</a>
						&nbsp;&nbsp;&nbsp;
						<a
							href='https://www.linkedin.com/company/vitumob/?viewAsMember=true'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Icon classes={{ root: classes.iconRoot }}>
								<img className={classes.imageIcon} src={LinkedInIcon} alt='LinkedIn logo' />
							</Icon>
						</a>
					</Typography>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center', paddingTop: '2%' }}>
					<Button
						onClick={() => setState({ ...state, termsDialogOpen: true })}
						style={{ textTransform: 'none', color: 'white' }}
					>
						Terms and Conditions
					</Button>
					&nbsp;
					<Button
						onClick={() => setState({ ...state, contactUsDialogOpen: true })}
						style={{ textTransform: 'none', color: 'white' }}
					>
						Contact us
					</Button>
					&nbsp;
					<Button
						onClick={() => setState({ ...state, faqsDialogOpen: true })}
						style={{ textTransform: 'none', color: 'white' }}
					>
						FAQs
					</Button>
					&nbsp;
					<Button
						onClick={() => setState({ ...state, privacyOpen: true })}
						style={{ textTransform: 'none', color: 'white' }}
					>
						Privacy Policy
					</Button>
					&nbsp;
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center', color: 'gray' }}>
					&copy; {new Date().getFullYear()}&nbsp;&nbsp;&nbsp;Vitumob Shipping
				</Grid>
			</Grid>
			{/* Contact us dialog
			 *********************
			 *********************
			 ********************/}
			<Dialog open={state.contactUsDialogOpen} onClose={() => setState({ ...state, contactUsDialogOpen: false })}>
				<DialogTitle>Contact us</DialogTitle>
				<DialogContent>
					<form onSubmit={onContactUs}>
						<TextField
							name='name'
							value={state.name}
							label='Name'
							onChange={handleContactInputChange}
							required
							margin='normal'
							variant='outlined'
							fullWidth
						/>
						<TextField
							name='email'
							value={state.email}
							label='Email'
							onChange={handleContactInputChange}
							required
							margin='normal'
							variant='outlined'
							fullWidth
						/>
						<TextField
							name='phoneNumber'
							value={state.phoneNumber}
							label='Phone'
							onChange={handleContactInputChange}
							required
							margin='normal'
							variant='outlined'
							fullWidth
						/>
						<TextField
							name='subject'
							value={state.subject}
							label='Subject'
							onChange={handleContactInputChange}
							required
							margin='normal'
							variant='outlined'
							fullWidth
						/>
						<TextField
							name='message'
							value={state.message}
							label='Message'
							onChange={handleContactInputChange}
							required
							multiline
							rows='4'
							margin='normal'
							variant='outlined'
							fullWidth
						/>
						<Button type='submit' style={{ textTransform: 'none' }} color='primary'>
							Send message
						</Button>
					</form>
				</DialogContent>
			</Dialog>
			{/* Pricing dialog
			 ******************
			 ******************
			 ****************/}
			<Dialog
				open={state.pricingDialogOpen}
				fullWidth
				onClose={() => setState({ ...state, pricingDialogOpen: false })}
			>
				<DialogContent>
					<PriceList />
				</DialogContent>
			</Dialog>
			{/* Terms&C dialog
			 ******************
			 ******************
			 ****************/}
			<Dialog
				open={state.termsDialogOpen}
				fullWidth
				onClose={() => setState({ ...state, termsDialogOpen: false })}
			>
				<DialogContent>
					<TermsAndConditions />
				</DialogContent>
			</Dialog>
			{/* FAQs Dialog
			 ***************
			 ***************
			 *************/}
			<Dialog open={state.faqsDialogOpen} fullWidth onClose={() => setState({ ...state, faqsDialogOpen: false })}>
				<DialogContent>
					<Accordion>
						{FAQs.map(FAQ => {
							return (
								<AccordionItem key={Math.random()}>
									<AccordionItemHeading>
										<AccordionItemButton>{FAQ.question}</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<div dangerouslySetInnerHTML={{ __html: FAQ.response }} />
									</AccordionItemPanel>
								</AccordionItem>
							);
						})}
					</Accordion>
				</DialogContent>
			</Dialog>
			{/* Privacy Dialog
			 ***************
			 ***************
			 *************/}
			<Dialog open={state.privacyOpen} fullWidth onClose={() => setState({ ...state, privacyOpen: false })}>
				<DialogContent>
					<h3 className='text-3xl text-slate-800 pb-4'>VituMob Privacy</h3>
					<p className='text-base text-slate-600 pb-3'>
						Welcome to VituMob! Your privacy is important to us. This Privacy Policy explains how we
						collect, use, disclose, and safeguard your information when you use our services.
					</p>
					<p className='text-xl text-slate-800 pb-1'>1. Information We Collect</p>
					<p className='text-base text-slate-600 pb-2'>
						Personal Information: We collect your email address for the following purposes:
					</p>
					<li>Updating you about the location of your goods.</li>
					<li>Advising you on new products.</li>
					<li>Providing customer service.</li>
					<li className='mb-3'>Sending invoices.</li>
					<p className='text-xl text-slate-800 pb-1'>2. How We Use Your Information</p>
					<p className='text-base text-slate-600 pb-2'>
						We use the information we collect in the following ways:
					</p>
					<li>
						Order Tracking and Updates: To keep you informed about the status and location of your orders.
						Product
					</li>
					<li>
						Notifications: To notify you about new products and services that may be of interest to you.
						Customer
					</li>
					<li className='mb-3'>
						{' '}
						Service: To provide you with support and address any queries or issues you may have. Invoicing:
						To send you invoices for your purchases.
					</li>
					<p className='text-xl text-slate-800 pb-1'>3. Sharing Your Information</p>
					<p className='text-base text-slate-600 pb-2'>
						We do not sell, trade, or otherwise transfer your personal information to outside parties except
						as described below:
					</p>
					<li>
						Service Providers: We may share your information with third-party service providers who assist
						us in operating our services, conducting our business, or servicing you, so long as those
						parties agree to keep this information confidential.{' '}
					</li>
					<li className='mb-3'>
						Legal Requirements: We may release your information when we believe it is appropriate to comply
						with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
					</li>
					<p className='text-xl text-slate-800 pb-1'>4. Data Security</p>
					<p className='text-base text-slate-600 mb-3'>
						We implement a variety of security measures to maintain the safety of your personal information
						when you place an order or enter, submit, or access your personal information. These security
						measures include password-protected directories and databases to safeguard your information.
					</p>
					<p className='text-xl text-slate-800 pb-1'>5. Your Consent</p>
					<p className='text-base text-slate-600 mb-3'>
						By using our site, you consent to our privacy policy.
					</p>
					<p className='text-xl text-slate-800 pb-1'>6. Changes to Our Privacy Policy</p>
					<p className='text-base text-slate-600 mb-5'>
						We may update this privacy policy from time to time. We will notify you of any changes by
						posting the new privacy policy on our website. You are advised to review this privacy
					</p>
				</DialogContent>
			</Dialog>
			{state.httpSuccess ? <HttpSuccess successMessage={state.httpSuccess} /> : null}
			{state.httpError ? <HttpError errorMessage={state.httpError} /> : null}
		</div>
	);
};

export default IndexComponent;
