import { Grid, LinearProgress, Paper, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../routes';
import { HttpError, HttpSuccess } from '../snackbars';
import { OfficerDrawer, useStyles } from './drawer';

import { useDispatch, useSelector } from 'react-redux';
import { officerGetOfficerInfoAction } from '../../actions/OfficerActions';
import '../admin/Admin.scss';
import '../dashboard/Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const OfficerDashboard = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const { auth, officer } = useSelector(state => ({
		auth: state.auth,
		officer: state.officer,
	}));

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		officerInfo: {},
		searchQuery: '',
		searchResults: [],
	});

	useEffect(
		() => {
			dispatch(officerGetOfficerInfoAction());
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			const { searchItemSuccess, searchedItemResults, officerInfo, officerInfoFetched, officerSuspended } =
				officer;
			if (officerSuspended) {
				navigate(Routes.OfficerSuspended);
			}
			if (auth.unauthorized) {
				window.location.href = '/accounts/signin';
			}
			setState({ ...state, officerInfo });
			if (searchItemSuccess) {
				setState({
					...state,
					searchResults: searchedItemResults,
					searchResultsDialogOpen: true,
				});
			}
			if (officerInfoFetched) {
				navigate(`/officers/${officerInfo.role}`);
			}
		},
		// eslint-disable-next-line
		[auth, officer]
	);

	const onLogout = () => {
		setState({ ...state, profileAnchorEl: null });
		window.location.href = '/accounts/signin';
	};

	const openProfileMenu = event => {
		setState({ ...state, profileAnchorEl: event.currentTarget });
	};

	const closeProfileMenu = () => {
		setState({ ...state, profileAnchorEl: null });
	};

	const toggleDrawer = open => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, drawerOpen: open });
	};

	const { errorMsg, successMsg, officerInfoFetched } = officer;

	if (!officerInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		return (
			<div>
				<OfficerDrawer
					classes={classes}
					state={state}
					props={props}
					activeLink='dash'
					toggleDrawer={toggleDrawer}
					openProfile={openProfileMenu}
					closeProfile={closeProfileMenu}
					profileAnchorEl={state.profileAnchorEl}
					logout={onLogout}
					// searchFieldChange={searchFieldChange}
					// searchItem={onSearchItem}
				/>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '10vh',
					}}
				>
					<Grid container justify='center' spacing={0}>
						Officers Dashboard
					</Grid>
				</Paper>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
		);
	}
};

export default OfficerDashboard;
