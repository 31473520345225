import { EditOutlined, LensOutlined, MailOutline, Notes, Phone } from '@material-ui/icons';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	LinearProgress,
	Paper,
	Typography,
} from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Reactour from 'reactour';
import { dashGetUserInfoAction, dashRecentItemsAction } from '../../actions/DashAction';
import { Routes } from '../../routes';
import { useAppSelector } from '../../store/store';
import Loader from '../common/loader/Loader';
import { HttpError, HttpSuccess } from '../snackbars';
import './Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const RecentActivities = ({ text, display, legendColor }) => {
	if (display === 'none') {
		return <Grid container spacing={0}></Grid>;
	} else {
		return (
			<Grid container spacing={0}>
				<Grid item xs={1}></Grid>
				<Grid item xs={1}>
					<div className='dash_recent_activity_pipe'>|</div>
				</Grid>
				<Grid item xs={10}></Grid>
				<Grid item xs={1}>
					<div className='dash_recent_activity_txt'></div>
				</Grid>
				<Grid item xs={1}>
					<div className='dash_recent_activity_0'>
						<LensOutlined htmlColor={`${legendColor}`} />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className='dash_recent_activity_txt'>{text}</div>
				</Grid>
			</Grid>
		);
	}
};

const DashBoardPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectProps = createSelector(
		[
			state => state.auth.isClient,
			state => state.dash.recentUploads,
			state => state.dash.recentUploadsSuccess,
			state => state.dash.invoicesSuccess,
			state => state.dash.invoices,
			state => state.dash.userInfo,
			state => state.dash.userAddress,
			state => state.dash.userInfoFetched,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
		],
		(
			isClient,
			recentUploads,
			recentUploadsSuccess,
			invoicesSuccess,
			invoices,
			userInfo,
			userAddress,
			userInfoFetched,
			successMsg,
			errorMsg
		) => {
			return {
				isClient,
				recentUploads,
				recentUploadsSuccess,
				invoicesSuccess,
				invoices,
				userInfo,
				userAddress,
				userInfoFetched,
				successMsg,
				errorMsg,
			};
		}
	);

	const {
		isClient,
		recentUploads,
		recentUploadsSuccess,
		invoicesSuccess,
		invoices,
		userInfo,
		userAddress,
		userInfoFetched,
		successMsg,
		errorMsg,
	} = useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		arrivedItems: [],
		invoicedItems: [],
		paidItems: [],
		deliveredItems: [],
		notifications: [],
		invoices: [],
		dashTourOpen: !localStorage.getItem('dashtour'),
		closeTourDialogOpen: false,
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
	});

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashRecentItemsAction());
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (recentUploadsSuccess) {
				let arrivedItems = [];
				let receivedItems = [];
				let shippedItems = [];
				let invoicedItems = [];
				let deliveredItems = [];
				let paidItems = [];
				let eligibleItems = [];
				recentUploads &&
					recentUploads.map(item => {
						if (item.packageStatus === 'IN_DELAWARE') receivedItems.push(item);
						if (item.packageStatus === 'IN_TRANSIT') shippedItems.push(item);
						if (item.packageStatus === 'IN_NAIROBI') arrivedItems.push(item);
						if (item.packageStatus === 'invoiced') invoicedItems.push(item);
						if (item.packageStatus === 'paid') paidItems.push(item);
						if (item.packageStatus === 'delivered') deliveredItems.push(item);
						if (item.isEligibleForProtection === true) eligibleItems.push(item);
						return item;
					});
				setState({
					...state,
					receivedItems,
					shippedItems,
					eligibleItems,
					arrivedItems,
					invoicedItems,
					paidItems,
					deliveredItems,
				});
			}

			if (invoicesSuccess) {
				setState({ ...state, invoices: invoices });
			}
		},
		// eslint-disable-next-line
		[recentUploadsSuccess, recentUploads, invoices, invoicesSuccess]
	);

	const toggleTourDismissDialog = () => {
		setState({ ...state, closeTourDialogOpen: !state.closeTourDialogOpen });
	};

	const hideTourForever = () => {
		localStorage.setItem('dashtour', `${new Date()}`);
		setState({ ...state, closeTourDialogOpen: !state.closeTourDialogOpen });
	};

	const { id, email, phoneNumber, firstName } = userInfo;
	const { receivedItems, shippedItems, arrivedItems, invoicedItems, paidItems, deliveredItems } = state;

	return (
		<>
			{!userInfoFetched && (
				<div className='linear_progress'>
					<LinearProgress />
				</div>
			)}
			<div>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					<Grid container justify='center' spacing={0}>
						<Grid item xs={12}>
							<Paper className='clean_paper rt_our_address' style={{ margin: '0 4% 0 5%' }}>
								<Grid container spacing={0}>
									<Grid item xs={mql.matches ? 12 : 3}>
										<Paper className='clean_paper'>
											Our <b>Customer</b> Service Contacts
											<br />
											Phone: <a href='tel:+254717363433'>+254717363433</a> <br />
											For more inquiries on China, Dubai and Turkey:{' '}
											<p>
												<a href='tel:+254724363433'>0724 363 433</a> /{' '}
												<a href='tel:+254729363433'>0729 363 433</a>
											</p>
											Email: <a href='mailto:support@vitumob.com'>support@vitumob.com</a>
										</Paper>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 3}>
										<Paper className={`clean_paper ${mql.matches && 'mt-8'}`}>
											Our <b>United States</b> Shipping Address:
											<br />
											Vitumob
											<br />
											1255 E 16th Street
											<br />
											Suite {userInfoFetched && id.toUpperCase()}
											<br />
											Wilmington, Delaware 19802
											<br />
											USA.
											<br />
											Phone: <a href='tel:+1 (609) 848-8662'>+1 (609) 848-8662</a>
										</Paper>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 3}>
										<Paper className={`clean_paper ${mql.matches && 'mt-8'}`}>
											Our <b>UK</b> Shipping Address:
											<br />
											Access Self Storage,
											<br />
											First Way ACG VM {userInfoFetched && id.toUpperCase()}
											<br />
											Wembley HA9 0JD
											<br />
											United Kingdom.
											<br />
											Phone: <a href='tel:+44 0207 993 2763'>+44 0207 993 2763</a>
										</Paper>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 3}>
										<Paper className={`clean_paper ${mql.matches && 'mt-8'}`}>
											Our <b>China</b> Air Shipping Address:
											<br />
											Room 803, Yidong Building
											<br />
											No.303 Huanshi Middle Road, Yuexiu District
											<br />
											Guangzhou.
											<br />
											{userInfoFetched && id.toUpperCase()} {userInfoFetched && firstName} C/O
											VITUMOB
											<br />
											Phone: <a href='tel:+8618529122243'>+86 1852 912 2243</a> Mr Lai
											<br />
											Phone: <a href='tel:+8613025142741'>+86 1302 514 2741</a> Miss Zhu
										</Paper>
									</Grid>
									<Grid container spacing={0}>
										<Grid item xs={mql.matches ? 12 : 3}>
											<Paper className='clean_paper mt-8'>
												Our <b>China</b> Sea Cargo Shipping Address:
												<br />
												Foshan First Ring Road,
												<br />
												102/F, Building 4
												<br />
												Hongcheng Hechuang Smart Industrial Park
												<br />
												East Eco-Industrial Park
												<br />
												Foshan, Guangdong Province, 510168.
												<br />
												{userInfoFetched && id.toUpperCase()} {userInfoFetched && firstName} C/O
												VITUMOB
												<br />
												Phone: <a href='tel:+8618820002343'>+86 1882 000 2343</a>
											</Paper>
										</Grid>
										<Grid item xs={mql.matches ? 12 : 3}>
											<Paper className='clean_paper mt-8'>
												Our <b>Turkey</b> Shipping Address:
												<br />
												Temel Cargo Office
												<br />
												Faturasiz Mal Kesinlikle Teslim
												<br />
												Alinmayacaktir.
												<br />
												Code: 5718
												<br />
												{userInfoFetched && id.toUpperCase()} {userInfoFetched && firstName} C/O
												VITUMOB
												<br />
												Phone: <a href='tel:+905362490859'>+90 536 249 08 59</a>
											</Paper>
										</Grid>
										<Grid item xs={mql.matches ? 12 : 3}>
											<Paper className='clean_paper mt-8'>
												Our <b>Dubai</b> Shipping Address:
												<br />
												P. O Box 183291
												<br />
												Deira, Dubai, U.A.E
												<br />
												Location: Opp. Sabakha, office No.4
												<br />
												Al Mamzar, Gate No: 11 Bishar
												<br />
												{userInfoFetched && id.toUpperCase()} {userInfoFetched && firstName} C/O
												VITUMOB
												<br />
												Phone: <a href='tel:+971552995780'>+971 552 995 780</a>
											</Paper>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<div style={{ paddingTop: '30px', color: 'red' }}>
											<p>
												Please include customer name or information on your shipping label.
												Ensure the shipping label has the correct address and your unique suite
												number. Also please indicate 0729363433 or 0724363433 on the shipping
												label for easy tracking.
											</p>
										</div>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						{userInfoFetched && userAddress && userAddress.length === 0 ? (
							<Grid item xs={12}>
								<Paper
									style={{
										margin: '2% 4%',
										padding: '1%',
										marginBottom: '0',
										background: 'rgba(255,69,0,0.2)',
										color: 'red',
									}}
								>
									You need a delivery address in order to start shipping. Please add at least one
									address&nbsp;
									<Button onClick={() => navigate(Routes.EditProfile)} color='primary'>
										here
									</Button>
								</Paper>
							</Grid>
						) : (
							<Grid item xs={12}></Grid>
						)}
						<Grid item xs={mql.matches ? 12 : 5}>
							<Grid container spacing={0}>
								<Grid item xs={12}>
									{userInfoFetched ? (
										<Card className='dashboard_profile_card rt_profile_info'>
											<CardContent>
												<Typography className='align_icons' color='textSecondary'>
													<Notes />
													&nbsp;&nbsp;&nbsp;<b>Client Number:&nbsp;</b>
													{id && id.toUpperCase()}
												</Typography>
												<br />
												<Typography className='align_icons' color='textSecondary'>
													<MailOutline />
													&nbsp;&nbsp;&nbsp;{email}
												</Typography>
												<br />
												<Typography className='align_icons' color='textSecondary'>
													<Phone />
													&nbsp;&nbsp;&nbsp;{phoneNumber}
												</Typography>
												<br />
											</CardContent>
											<CardActions>
												<Typography className='align_icons'>
													<Link
														to='/dashboard/editprofile'
														style={{ textDecoration: 'none' }}
													>
														&nbsp;&nbsp;&nbsp;
														<EditOutlined />
														&nbsp; Edit information
													</Link>
												</Typography>
											</CardActions>
										</Card>
									) : (
										<div className='absolute top-1/2 left-1/2'>
											<Loader />
										</div>
									)}
								</Grid>
								<Grid item xs={12}>
									<Card className='dashboard_legend_card'>
										<CardContent>
											<Typography>Legend</Typography>
											<Typography className='align_icons' style={{ color: 'green' }}>
												<LensOutlined />
												&nbsp;Delivered
											</Typography>
											<br />
											<Typography className='align_icons' style={{ color: 'purple' }}>
												<LensOutlined />
												&nbsp;Pending Delivery
											</Typography>
											<br />
											<Typography className='align_icons' style={{ color: 'darkslategray' }}>
												<LensOutlined />
												&nbsp;Awaiting Payment
											</Typography>
											<br />
											{isClient ? (
												<>
													<Typography className='align_icons' style={{ color: 'magenta' }}>
														<LensOutlined />
														&nbsp;At Customs
													</Typography>
													<br />
												</>
											) : (
												<>
													{' '}
													<Typography className='align_icons' style={{ color: 'red' }}>
														<LensOutlined />
														&nbsp;In Kenya
													</Typography>
													<br />
													<Typography className='align_icons' style={{ color: 'cyan' }}>
														<LensOutlined />
														&nbsp;In Nairobi
													</Typography>
													<br />
													<Typography className='align_icons' style={{ color: 'magenta' }}>
														<LensOutlined />
														&nbsp;Being Processed
													</Typography>
													<br />
												</>
											)}
											<Typography className='align_icons' style={{ color: 'blue' }}>
												<LensOutlined />
												&nbsp;In Transit
											</Typography>
											<br />
											<Typography className='align_icons' style={{ color: 'orange' }}>
												<LensOutlined />
												&nbsp;At Warehouse
											</Typography>
											<br />
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={mql.matches ? 12 : 7}>
							<Card className='dashboard_recent_activity rt_dash_recent_act'>
								<CardContent>Recent Activity</CardContent>
								<CardContent>
									<RecentActivities
										text={`${receivedItems.length} item(s) have been received at the warehouse`}
										display={receivedItems.length === 0 ? 'none' : 'block'}
										legendColor='orange'
									/>
									<RecentActivities
										text={`${shippedItems.length} item(s) have been shipped`}
										display={shippedItems.length === 0 ? 'none' : 'block'}
										legendColor='purple'
									/>
									<RecentActivities
										text={`${arrivedItems.length} item(s) have arrived in Nairobi`}
										display={arrivedItems.length === 0 ? 'none' : 'block'}
										legendColor='blue'
									/>
									<RecentActivities
										text={`${invoicedItems.length} item(s) have been invoiced`}
										display={invoicedItems.length === 0 ? 'none' : 'block'}
										legendColor='darkslategray'
									/>
									<RecentActivities
										text={`${paidItems.length} item(s) have been paid`}
										display={paidItems.length === 0 ? 'none' : 'block'}
										legendColor='cyan'
									/>
									<RecentActivities
										text={`${deliveredItems.length} item(s) have been delivered`}
										display={deliveredItems.length === 0 ? 'none' : 'block'}
										legendColor='green'
									/>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				{/* Tour */}
				<Reactour
					steps={reactourSteps}
					isOpen={state.dashTourOpen}
					onBeforeClose={toggleTourDismissDialog}
					onRequestClose={() => setState({ ...state, dashTourOpen: false })}
				/>
				{/* Dismiss tour dialog */}
				<Dialog open={state.closeTourDialogOpen} onClose={toggleTourDismissDialog}>
					<DialogContent style={{ paddingTop: '32px' }}>
						Dismiss the dashboard guide popup forever?
					</DialogContent>
					<DialogActions>
						<Button onClick={toggleTourDismissDialog} color='primary'>
							No
						</Button>
						<Button onClick={hideTourForever} color='primary' autoFocus={true}>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
			</div>
		</>
	);
};

const reactourSteps = [
	{
		selector: '.rt_our_address',
		content: `Welcome, These are our US and UK shipping addresses, Use them as the delivery
		          address when ordering goods.`,
	},
	{
		selector: '.rt_profile_info',
		content: `Here is your Client number and profile information. Click
		          the EDIT button to make changes to your info`,
	},
	{
		selector: '.rt_dash_recent_act',
		content: `View recent activities here (The status of the different items
			        that you registered)`,
	},
];

export default DashBoardPage;
