const initialState = {
	officerSuspended: false,
	officerInfoFetched: false,
	officerInfo: {},
	shippingItems: [],
	messages: [],
	clients: [],
	invoices: [],
	invoicesCount: 0,
	invoice: {},
	client: {},
	clientsCount: 0,
	shipmentCount: 0,
	dataFetched: false,
	invoiceFetched: false,
	clientFetched: false,
	shippingItemCheckedIn: false,
	shippingItemArrived: false,
	messagesUpdated: false,
	fetchingData: false,
	errorMsg: null,
	successMsg: null,
	archivedItems: [],
	countArchived: 0,
	exchangeRates: {},
	userAddress: [],
	addressFetched: false,
	clientInvoicesFetched: false,
	clientInvoices: [],
	fetchingStatusDates: false,
	statusDates: [],
	sentInvoice: false,
};

const addElements = array => {
	let newArr = array.map(arr => {
		arr.weight = '';
		arr.dlength = '';
		arr.dwidth = '';
		arr.dheight = '';
		arr.arrived = false;
		return arr;
	});
	return newArr;
};

const addElementsInv = array => {
	let newArr = array.map(arr => {
		arr.checked = '';
		return arr;
	});
	return newArr;
};

export const OfficerReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'OFFICER_SEND_INVOICE':
			return {
				...state,
				sentInvoice: false,
				successMsg: null,
			};
		case 'OFFICER_SEND_INVOICE_SUCCESS':
			return {
				...state,
				sentInvoice: true,
				successMsg: action.payload,
			};
		case 'OFFICER_GET_STATUS_DATES':
			return {
				...state,
				fetchingStatusDates: true,
				statusDates: [],
			};
		case 'OFFICER_GET_STATUS_DATES_SUCCESS':
			return {
				...state,
				fetchingStatusDates: false,
				statusDates: action.payload,
			};
		case 'OFFICER_GET_ARCHIVED':
			return {
				...state,
				archivedItems: [],
				countArchived: 0,
				fetchingData: true,
			};
		case 'OFFICER_GET_ARCHIVED_SUCCESS':
			return {
				...state,
				archivedItems: action.payload.archivedItems,
				countArchived: action.payload.countArchived,
				fetchingData: false,
			};
		/* Get officer Information */
		case 'OFFICER_GET_OFFICER_INFO':
			return {
				...state,
				officerInfoFetched: false,
				invoiceFetched: false,
				messagesUpdated: false,
				clientFetched: false,
				officerInfo: {},
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
			};
		case 'OFFICER_MARKS_DELIVERED':
			return {
				...state,
			};
		case 'OFFICER_MARKS_DELIVERED_SUCCESS':
			return {
				...state,
			};
		case 'OFFICER_GET_OFFICER_INFO_SUCCESS':
			return {
				...state,
				officerInfoFetched: true,
				messagesUpdated: false,
				officerInfo: action.payload,
				fetchingData: false,
			};
		case 'SEARCH_OFFICER':
			return {
				...state,
				clients: [],
				fetchingData: true,
			};
		case 'SEARCH_OFFICER_SUCCESS':
			return {
				...state,
				clients: action.payload,
				clientsCount: action.payload.length,
				fetchingData: false,
			};
		case 'SEARCH_SHIPPING':
			return {
				...state,
				filteredItems: [],
				fetchingData: true,
			};
		case 'SEARCH_SHIPPING_SUCCESS':
			return {
				...state,
				filteredItems: action.payload,
				fetchingData: false,
			};
		/* Get address */
		case 'OFFICER_GET_ADDRESS':
			return {
				...state,
				addressFetched: false,
				userAddress: [],
			};
		case 'OFFICER_GET_ADDRESS_SUCCESS':
			return {
				...state,
				addressFetched: true,
				userAddress: action.payload,
			};
		/* Get data */
		case 'OFFICER_GET_DATA':
			return {
				...state,
				dataFetched: false,
				shippingItemCheckedIn: false,
				shippingItemArrived: false,
				messagesUpdated: false,
				shippingItems: [],
				messages: [],
				clients: [],
				invoices: [],
				clientsCount: 0,
				shipmentCount: 0,
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
				invoicesCount: 0,
			};
		case 'OFFICER_GET_DATA_SUCCESS':
			return {
				...state,
				dataFetched: true,
				messagesUpdated: false,
				shippingItems: addElements(action.payload.shippingItems),
				messages: action.payload.messages,
				clients: action.payload.clients,
				invoices: addElementsInv(action.payload.invoices),
				clientsCount: action.payload.clientsCount,
				shipmentCount: action.payload.shipmentCount.count,
				fetchingData: false,
				invoicesCount: action.payload.invoicesCount.count,
			};
		/* Items in Nairobi */
		case 'OFFICER_RECEIVES_IN_NAIROBI':
			return {
				...state,
				shippingItemArrived: false,
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
			};
		case 'OFFICER_RECEIVES_IN_NAIROBI_SUCCESS':
			return {
				...state,
				shippingItemArrived: true,
				fetchingData: false,
				successMsg: action.payload,
			};
		/* Update Shipping Item */
		case 'OFFICER_CHECKIN_SHIPPING_ITEMS':
			return {
				...state,
				shippingItemCheckedIn: false,
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
			};
		case 'OFFICER_CHECKIN_SHIPPING_ITEMS_SUCCESS':
			return {
				...state,
				shippingItemCheckedIn: true,
				fetchingData: false,
				successMsg: action.payload,
			};
		case 'OFFICER_REPLY_MESSAGE':
			return {
				...state,
				fetchingData: true,
				messagesUpdated: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'OFFICER_REPLY_MESSAGE_SUCCESS':
			return {
				...state,
				fetchingData: false,
				messagesUpdated: true,
				successMsg: action.payload,
			};
		/* Invoice */
		case 'OFFICER_GET_INVOICE':
			return {
				...state,
				invoiceFetched: false,
				invoice: {},
				exchangeRates: {},
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
			};
		case 'OFFICER_GET_INVOICE_SUCCESS':
			return {
				...state,
				invoiceFetched: true,
				invoice: action.payload.invoice,
				exchangeRates: action.payload.exchangeRates,
				fetchingData: false,
			};
		case 'OFFICER_GET_CLIENT_INVOICES':
			return {
				...state,
				fetchingData: false,
				invoices: [],
				invoicesFetched: false,
				invoiceFetched: false,
			};
		case 'OFFICER_GET_CLIENT_INVOICES_SUCCESS':
			return {
				...state,
				fetchingData: false,
				clientInvoicesFetched: true,
				clientInvoices: action.payload,
			};
		case 'OFFICER_PAY_INVOICE':
			return {
				...state,
				fetchingData: true,
				successMsg: null,
			};
		case 'OFFICER_PAY_INVOICE_SUCCESS':
			return {
				...state,
				successMsg: action.payload,
				fetchingData: false,
			};
		/* Client */
		case 'OFFICER_GET_CLIENT':
			return {
				...state,
				clientFetched: false,
				client: {},
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
			};
		case 'OFFICER_GET_CLIENT_SUCCESS':
			console.log(10000000, action);
			return {
				...state,
				clientFetched: true,
				client: action.payload,
				fetchingData: false,
			};
		/* Officer Suspended */
		case 'OFFICER_SUSPENDED':
			return {
				...state,
				officerSuspended: true,
				fetchingData: false,
			};
		case 'OFFICER_INVOICE_LOADING':
			return {
				...state,
				fetchingData: false,
			};
		default:
			return state;
	}
};
