const initialState = {
	officers: [],
	foremanInfo: {},
	foremanFetchOfficers: false,
	foremanInfoFetched: false,
	officerInfoUpdated: false,
	foremanSuspended: false,
	fetchingData: false,
	errorMsg: null,
	successMsg: null,
};

export const ForemanReducer = (state = initialState, action) => {
	switch (action.type) {
		/* Get foreman Info */
		case 'FOREMAN_GET_FOREMAN_INFO':
			return {
				...state,
				foremanInfoFetched: false,
				foremanInfo: {},
				fetchingData: true,
				errorMsg: null,
				successMsg: null,
			};
		case 'FOREMAN_GET_FOREMAN_INFO_SUCCESS':
			return {
				...state,
				foremanInfoFetched: true,
				foremanFetchOfficers: true,
				foremanInfo: action.payload,
				fetchingData: false,
			};
		/* Get officers */
		case 'FOREMAN_GET_OFFICERS':
			return {
				...state,
				officers: [],
				fetchingData: true,
				officerInfoUpdated: false,
				foremanFetchOfficers: false,
				successMsg: null,
				errorMsg: null,
			};
		/* Get officers */
		case 'FOREMAN_GET_OFFICERS_SUCCESS':
			return {
				...state,
				officers: action.payload,
				fetchingData: false,
			};
		/* Add officer */
		case 'FOREMAN_ADD_OFFICER':
			return {
				...state,
				fetchingData: true,
				officerInfoUpdated: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'FOREMAN_ADD_OFFICER_SUCCESS':
			return {
				...state,
				fetchingData: false,
				officerInfoUpdated: true,
				successMsg: action.payload,
			};
		default:
			return state;
	}
};
